import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = (selectedType) => {
  const theme = useTheme();
  return makeStyles(() => {
    return {
      productSummary3Wrapper: {
        "&.productSummary3Bg": {
          "& .labelText": {
            color: theme.palette?.[selectedType]?.LABEL,
          },
          "& .headingText": {
            color: theme.palette?.[selectedType]?.TITLE,
          },
          "& .descriptionText": {
            color: theme.palette?.[selectedType]?.PARAGRAPH,
          },
          "& .imageWrapper": {
            background: theme.palette?.[selectedType]?.BACKGROUND,
            display: "flex",
            borderRadius: `${theme.borderRadius.value1} ${theme.borderRadius.value1} 0 0`,
            [theme.breakpoints.down("md")]: {
              borderRadius: `${theme.borderRadius.value1} ${theme.borderRadius.value1} 0  0`,
            },
            [theme.breakpoints.up("em")]: {
              borderRadius: `${theme.borderRadius.value1} 0  0 ${theme.borderRadius.value1}`,
            },
            overflow: "hidden",
          },
          "& .outerBox": {
            display: "initial",
          },
          "& .ProductSummary3": {},
          "& .title": {
            borderBottom: `1px solid ${theme.palette?.[selectedType]?.LINE}`,
            maxWidth: "80%",
            [theme.breakpoints.up("md")]: {
              maxWidth: "350px",
            },
            [theme.breakpoints.up("lg")]: {
              maxWidth: "420px",
            },
          },
          "& .imageProp": {
            width: "100%",
            height: "100%",
          },
          "& .secondColumnContentWrapper": {
            background: theme.palette?.[selectedType]?.BACKGROUND,
            borderBottomRightRadius: theme.borderRadius.value1,
            borderTopRightRadius: theme.borderRadius.value1,
            width: "100%",
            padding: "40px",
            [theme.breakpoints.down("em")]: {
              padding: "15px",
              borderBottomRightRadius: theme.borderRadius.value1,
              borderTopRightRadius: 0,
              borderBottomLeftRadius: theme.borderRadius.value1,
              borderTopLeftRadius: 0,
            },
          },
        },
      },
    };
  })();
};
