import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Container, Grid, Typography } from "@mui/material";
import { getDynamicContentListApiCall } from "@platformx/utilities";
import { useEffect, useState } from "react";
import CardSkeleton from "../../components/Cards/CardSkeleton";
import XCard4 from "../../components/Cards/XCard4/XCard4";
import ReplaceComponent from "../../components/Replace/ReplaceComponent";
import { getPrelemType } from "../../utils/Themefunctions";
import { useCustomStyle } from "./DynamicPrelemWithCarousel1.style";

const DynamicPrelemWithCarousel1 = ({
  content,
  analytics,
  authoringHelper,
  secondaryArgs = {},
}) => {
  const prelemType = content.selected_prelem_bg_color;
  const classes = useCustomStyle(getPrelemType(prelemType));
  const [loading, setLoading] = useState(false);
  const [contentList, setContentList] = useState<any[]>([]);
  const [start, setStart] = useState(0);
  const numberOfRows = 3;
  const getContentList = async () => {
    setLoading(true);
    try {
      const contentListFromAPI = await getDynamicContentListApiCall({
        params: content?.QueryParam,
        secondaryArgs: secondaryArgs,
        start,
        numberOfRows,
        publishRpiKey: content?.RpiKey,
      });

      if (contentListFromAPI) setContentList(contentListFromAPI);
      else setContentList([]);
      setLoading(false);
    } catch (err: any) {
      setContentList([]);
      setLoading(false);
    }
  };
  const getNextList = () => {
    setStart((prevState) => prevState + numberOfRows);
  };

  const getPreviousList = () => {
    if (start !== 0) setStart((prevState) => prevState - numberOfRows);
  };

  useEffect(() => {
    getContentList();
  }, [start, content?.QueryParam]);

  return (
    <div
      ref={authoringHelper?.innerRef}
      className={`${classes.dynamicPrelemWithCarousel1} dynamicPrelemWithCarousel1Wrapper`}>
      <Container
        className={
          authoringHelper?.isEditPage ? `grid_full_width prelem-py` : `grid_container prelem-py`
        }>
        <Box className='textWrapper'>
          <Typography variant='h2medium' className='headingText' id='Title'>
            {content?.Title}
          </Typography>
          <Box className='navigationWrapper'>
            <ArrowBackIcon
              onClick={getPreviousList}
              data-testid='previous-list'
              className={`${start === 0 ? "arrowIconsDisabled" : ""} arrowIcons`}
              sx={{
                pointerEvents: start === 0 ? "none" : "",
              }}
            />
            <ArrowForwardIcon
              onClick={getNextList}
              data-testid='next-list'
              className={`${contentList?.length === 0 ? "arrowIconsDisabled" : ""} arrowIcons`}
              sx={{
                pointerEvents: contentList?.length === 0 ? "none" : "",
              }}
            />
          </Box>
        </Box>
        <Grid
          container
          sx={{
            paddingBottom: "35px",
            margin: "8px 0 0 0",
            position: "relative",
            "&:hover": {
              ".add-content-overlay": {
                display: authoringHelper?.authoringHoverShow ? "flex !important" : "none",
              },
            },
          }}>
          {loading ? (
            <Box>
              <CardSkeleton />
            </Box>
          ) : contentList?.length > 0 ? (
            <Box sx={{ marginLeft: "-15px", marginRight: "-15px", width: "calc(100% + 30px)" }}>
              <Grid container>
                {contentList.map((item: any, index: any) => {
                  return (
                    <Grid item em={4} xs={12} lg={4} px='15px' key={index}>
                      <XCard4
                        content={item}
                        secondaryArgs={secondaryArgs}
                        cardIndex={index}
                        analytics={analytics}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          ) : (
            <Box className='noResultFound'>
              <Typography variant='p3regular' className='descriptionText'>
                No results found
              </Typography>
            </Box>
          )}
          <Box className='add-content-overlay'>
            <ReplaceComponent secondaryArgs={secondaryArgs} />
          </Box>
        </Grid>
      </Container>
    </div>
  );
};

export default DynamicPrelemWithCarousel1;
