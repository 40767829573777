import { Box, Container, Typography } from "@mui/material";
import { RedDotsIcon } from "@platformx/shared/static-assets";
import { SecondaryArgs, formCroppedUrlString, structureDataUrlPoint } from "@platformx/utilities";
import React, { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import "../../Style.css";
import BasicButton from "../../components/BasicButton/BasicButton";
import ImageRender from "../../components/ImageRender";
import { usePrelemImpression } from "../../components/ImpressionHooks/PrelemImpressionHook";
import TwoColumnLayout from "../../components/layouts/TwoColumns/TwoColumnLayout";
import prelemTypes from "../../globalStyle";
import { getButtonVariant, getPrelemType } from "../../utils/Themefunctions";
import { useCustomStyle } from "./WebsiteIntroduction2.style";

const WebsiteIntroduction2 = ({
  content,
  analytics,
  authoringHelper,
  secondaryArgs,
}: // secondaryArgs,
WebsiteIntroduction2Prop) => {
  const prelemType = content.selected_prelem_bg_color;
  const classes = useCustomStyle(getPrelemType(prelemType));
  const globalClasses = prelemTypes();
  const ButtonObj1 = {
    Button_Name: "Button1_Name",
    Button_RedirectURL: "Button1_RedirectURL",
    Button_Type: "Button1_Type",
    Button_Value: "Button1_Value",
    Button_Action: "Button1_Action",
    Button_Content: "Button1_Content",
  };

  const ButtonDataObj1 = {
    Button_Name: content.Button1_Name,
    Button_RedirectURL: content.Button1_RedirectURL,
    Button_Type: content.Button1_Type,
    Button_Value: content.Button1_Value,
    Button_Action: content.Button1_Action,
    Button_Content: content?.Button1_Content,
  };
  const ButtonObj2 = {
    Button_Name: "Button2_Name",
    Button_RedirectURL: "Button2_RedirectURL",
    Button_Type: "Button2_Type",
    Button_Value: "Button2_Value",
    Button_Action: "Button2_Action",
    Button_Content: "Button2_Content",
  };
  const ButtonDataObj2 = {
    Button_Name: content.Button2_Name,
    Button_RedirectURL: content.Button2_RedirectURL,
    Button_Type: content.Button2_Type,
    Button_Value: content.Button2_Value,
    Button_Action: content.Button2_Action,
    Button_Content: content?.Button2_Content,
  };

  const ButtonObj3 = {
    Button_Name: "Button3_Name",
    Button_RedirectURL: "Button3_RedirectURL",
    Button_Type: "Button3_Type",
    Button_Value: "Button3_Value",
    Button_Action: "Button3_Action",
    Button_Content: "Button3_Content",
  };
  const ButtonDataObj3 = {
    Button_Name: content.Button3_Name,
    Button_RedirectURL: content.Button3_RedirectURL,
    Button_Type: content.Button3_Type,
    Button_Value: content.Button3_Value,
    Button_Action: content.Button3_Action,
    Button_Content: content?.Button3_Content,
  };

  const ButtonObj4 = {
    Button_Name: "Button4_Name",
    Button_RedirectURL: "Button4_RedirectURL",
    Button_Type: "Button4_Type",
    Button_Value: "Button4_Value",
    Button_Action: "Button4_Action",
    Button_Content: "Button4_Content",
  };
  const ButtonDataObj4 = {
    Button_Name: content.Button4_Name,
    Button_RedirectURL: content.Button4_RedirectURL,
    Button_Type: content.Button4_Type,
    Button_Value: content.Button4_Value,
    Button_Action: content.Button4_Action,
    Button_Content: content?.Button4_Content,
  };

  const ButtonObj5 = {
    Button_Name: "Button5_Name",
    Button_RedirectURL: "Button5_RedirectURL",
    Button_Type: "Button5_Type",
    Button_Value: "Button5_Value",
    Button_Action: "Button5_Action",
    Button_Content: "Button5_Content",
  };
  const ButtonDataObj5 = {
    Button_Name: content.Button5_Name,
    Button_RedirectURL: content.Button5_RedirectURL,
    Button_Type: content.Button5_Type,
    Button_Value: content.Button5_Value,
    Button_Action: content.Button5_Action,
    Button_Content: content?.Button5_Content,
  };
  const firstRender = useRef(true);
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const refTitle = useRef<any>();
  const defaultStructureData = () => {
    let websiteIntroduction2StructureData;
    const { original_image_relative_path, ext }: any =
      content?.ImageCompound?.ImageCompound_1?.original_image || {};
    const img = formCroppedUrlString(
      secondaryArgs?.gcpUrl,
      secondaryArgs?.bucketName,
      original_image_relative_path,
      ext,
    ).src;

    try {
      websiteIntroduction2StructureData = {
        "@context": "https://schema.org/",
        "@type": "Service",
        hasOfferCatalog: {
          "@type": "OfferCatalog",
          name: content?.Title,
          description: content?.Description,
          image: img,
          itemListElement: [
            {
              "@type": "Offer",
              itemOffered: {
                "@type": "Service",
                name: content?.Button1_Value,
                url: structureDataUrlPoint(
                  {
                    Button_Action: content?.Button1_Action,
                    Button_RedirectURL: content?.Button1_RedirectURL,
                    Button_Content: content.Button1_Content,
                  },
                  secondaryArgs,
                ),
              },
            },
            {
              "@type": "Offer",
              itemOffered: {
                "@type": "Service",
                name: content?.Button2_Value,
                url: structureDataUrlPoint(
                  {
                    Button_Action: content?.Button2_Action,
                    Button_RedirectURL: content?.Button2_RedirectURL,
                    Button_Content: content.Button2_Content,
                  },
                  secondaryArgs,
                ),
              },
            },
            {
              "@type": "Offer",
              itemOffered: {
                "@type": "Service",
                name: content?.Button3_Value,
                url: structureDataUrlPoint(
                  {
                    Button_Action: content?.Button3_Action,
                    Button_RedirectURL: content?.Button3_RedirectURL,
                    Button_Content: content.Button3_Content,
                  },
                  secondaryArgs,
                ),
              },
            },
            {
              "@type": "Offer",
              itemOffered: {
                "@type": "Service",
                name: content?.Button4_Value,
                url: structureDataUrlPoint(
                  {
                    Button_Action: content?.Button4_Action,
                    Button_RedirectURL: content?.Button4_RedirectURL,
                    Button_Content: content.Button4_Content,
                  },
                  secondaryArgs,
                ),
              },
            },
            {
              "@type": "Offer",
              itemOffered: {
                "@type": "Service",
                name: content?.Button5_Value,
                url: structureDataUrlPoint(
                  {
                    Button_Action: content?.Button5_Action,
                    Button_RedirectURL: content?.Button5_RedirectURL,
                    Button_Content: content.Button5_Content,
                  },
                  secondaryArgs,
                ),
              },
            },
          ],
        },
      };
    } catch (e) {
      websiteIntroduction2StructureData = {};
    }
    return websiteIntroduction2StructureData;
  };

  const generateStructureData = () => {
    let websiteIntroduction2StructureData;
    const tempSD = String(authoringHelper?.lastSavedStructuredData);

    if (firstRender.current) {
      const defaultSD = defaultStructureData();
      const stringifyStructureData = defaultSD && JSON.stringify(defaultSD);
      authoringHelper?.sendDefaultStructureDataForResetToAuthoringCB(stringifyStructureData || "");

      if (String(tempSD).length > 0) {
        websiteIntroduction2StructureData = JSON.parse(tempSD);
      } else {
        websiteIntroduction2StructureData = defaultStructureData();
      }
      firstRender.current = false;
    } else {
      websiteIntroduction2StructureData = defaultStructureData();
    }
    return websiteIntroduction2StructureData;
  };

  useEffect(() => {
    if (analytics?.isAuthoring && analytics?.isSeoEnabled) {
      const structureData = generateStructureData();
      const stringifyStructureData = structureData && JSON.stringify(structureData);
      authoringHelper?.sendStructureDataToAuthoringCB(stringifyStructureData || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    content?.Title,
    content?.Heading,
    content?.Description,
    content?.Button1_Value,
    content?.Button2_Value,
    content?.Button3_Value,
    content?.Button4_Value,
    content?.Button5_Value,
    content?.ImageCompound?.ImageCompound_1?.original_image,
  ]);

  usePrelemImpression(analytics, inView, secondaryArgs);
  const gridVal = {
    md: [12, 12],
    em: [6, 6],
    lg: [6, 6],
  };
  const firstColumnContent = () => {
    return (
      <Box className='leftContentWrapper'>
        <Typography id='WelcomeText' variant='h3regular' className='linkText'>
          {content.WelcomeText}
        </Typography>
        <Typography
          variant='h2semibold'
          id='Title'
          className='headingText'
          ref={refTitle}
          sx={{
            display: authoringHelper?.isEditing || content?.Title ? "block" : "none",
          }}>
          {content.Title}
        </Typography>
        <Typography
          variant='h3regular'
          className='headingText'
          sx={{
            margin: "5px 0",
            display: authoringHelper?.isEditing || content?.Heading ? "block" : "none",
          }}
          id='Heading'>
          {content.Heading}
        </Typography>
        <Typography
          variant='p3regular'
          id='Description'
          className='descriptionText'
          sx={{
            display: authoringHelper?.isEditing || content?.Description ? "block" : "none",
          }}>
          {content.Description}
        </Typography>
        <Box>
          <BasicButton
            openButtonEditWindow={authoringHelper?.openButtonEditWindowInAuthoringCB}
            isAuthoring={analytics?.isAuthoring}
            currentBtnEditing={authoringHelper?.selectedButtonNameForEditing}
            variant={getButtonVariant(prelemType, "Button2")}
            analyticsEnabled={analytics?.isAnalyticsEnabled}
            ButtonObj={ButtonObj1}
            isEditing={authoringHelper?.isEditing}
            buttonDataObj={ButtonDataObj1}
            secondaryArgs={secondaryArgs}
            analytics={analytics}
            style={{
              display:
                authoringHelper?.isEditing ||
                (content?.Button1_Value && content?.Button1_Value !== "\n")
                  ? "inline-flex"
                  : "none",
            }}
          />
          <BasicButton
            openButtonEditWindow={authoringHelper?.openButtonEditWindowInAuthoringCB}
            isAuthoring={analytics?.isAuthoring}
            currentBtnEditing={authoringHelper?.selectedButtonNameForEditing}
            variant={getButtonVariant(prelemType, "Button2")}
            analyticsEnabled={analytics?.isAnalyticsEnabled}
            ButtonObj={ButtonObj2}
            isEditing={authoringHelper?.isEditing}
            buttonDataObj={ButtonDataObj2}
            secondaryArgs={secondaryArgs}
            analytics={analytics}
            style={{
              display:
                authoringHelper?.isEditing ||
                (content?.Button2_Value && content?.Button2_Value !== "\n")
                  ? "inline-flex"
                  : "none",
            }}
          />
          <BasicButton
            openButtonEditWindow={authoringHelper?.openButtonEditWindowInAuthoringCB}
            isAuthoring={analytics?.isAuthoring}
            currentBtnEditing={authoringHelper?.selectedButtonNameForEditing}
            variant={getButtonVariant(prelemType, "Button2")}
            analyticsEnabled={analytics?.isAnalyticsEnabled}
            ButtonObj={ButtonObj3}
            isEditing={authoringHelper?.isEditing}
            buttonDataObj={ButtonDataObj3}
            secondaryArgs={secondaryArgs}
            analytics={analytics}
            style={{
              display:
                authoringHelper?.isEditing ||
                (content?.Button3_Value && content?.Button3_Value !== "\n")
                  ? "inline-flex"
                  : "none",
            }}
          />
          <BasicButton
            openButtonEditWindow={authoringHelper?.openButtonEditWindowInAuthoringCB}
            isAuthoring={analytics?.isAuthoring}
            currentBtnEditing={authoringHelper?.selectedButtonNameForEditing}
            variant={getButtonVariant(prelemType, "Button2")}
            analyticsEnabled={analytics?.isAnalyticsEnabled}
            ButtonObj={ButtonObj4}
            isEditing={authoringHelper?.isEditing}
            buttonDataObj={ButtonDataObj4}
            secondaryArgs={secondaryArgs}
            analytics={analytics}
            style={{
              display:
                authoringHelper?.isEditing ||
                (content?.Button4_Value && content?.Button4_Value !== "\n")
                  ? "inline-flex"
                  : "none",
            }}
          />
          <BasicButton
            openButtonEditWindow={authoringHelper?.openButtonEditWindowInAuthoringCB}
            isAuthoring={analytics?.isAuthoring}
            currentBtnEditing={authoringHelper?.selectedButtonNameForEditing}
            variant={getButtonVariant(prelemType, "Button2")}
            analyticsEnabled={analytics?.isAnalyticsEnabled}
            ButtonObj={ButtonObj5}
            isEditing={authoringHelper?.isEditing}
            buttonDataObj={ButtonDataObj5}
            secondaryArgs={secondaryArgs}
            analytics={analytics}
            style={{
              display:
                authoringHelper?.isEditing ||
                (content?.Button5_Value && content?.Button5_Value !== "\n")
                  ? "inline-flex"
                  : "none",
            }}
          />
        </Box>
      </Box>
    );
  };
  const secondColumnContent = () => {
    return (
      <Box className='rightWrapper'>
        <Box className='imgBoxColorWrapper'>
          <Box className='imgBoxWrapper widthheight100'>
            <ImageRender
              originalImage={content?.ImageCompound?.ImageCompound_1?.original_image}
              publishedImages={content?.ImageCompound?.ImageCompound_1?.published_images}
              secondaryArgs={secondaryArgs}
              cropType={content?.cropType?.toLowerCase()}
              imgOrder={{
                1440: "card2",
                1280: "card2",
                1024: "card2",
                768: "card2",
                600: "card2",
                320: "card2",
              }}
              index={0}
              isEditing={authoringHelper?.isEditing}
            />
          </Box>
          <Box className='dottedImgFirst'>
            <img alt='intor3' src={RedDotsIcon} width='140' height='63' />
          </Box>
          <Box className='dottedImgSecond'>
            <img alt='intro4' src={RedDotsIcon} width='140' height='63' />
          </Box>
        </Box>
      </Box>
    );
  };
  return (
    <div
      ref={authoringHelper?.innerRef}
      className={`${classes.websiteIntroduction2Wrapper} ${globalClasses.prelemType1} prelem prelemType1 websiteIntroduction2 websiteIntroduction2Bg`}>
      <Container
        className={
          authoringHelper?.isEditPage ? `grid_full_width prelem-py` : `grid_container prelem-py`
        }
        ref={ref}>
        <TwoColumnLayout
          firstColumnContent={firstColumnContent()}
          secondColumnContent={secondColumnContent()}
          gridVal={gridVal}
          customClassName='websiteIntroduction2InnerWrapper'
          noGap={true}
        />
      </Container>
    </div>
  );
};

interface WebsiteIntroduction2Prop {
  content: Content;
  analytics: Analytics;
  authoringHelper?: AuthoringHelper;
  secondaryArgs: SecondaryArgs;
}

interface Analytics {
  pageId?: number;
  prelemId?: number;
  pageTitle?: string;
  prelemTitle?: string;
  pageDesc?: string;
  pageTags?: string;
  prelemTags?: string;
  prelemPosition?: number;
  isAnalyticsEnabled: boolean;
  isAuthoring: boolean;
  isSeoEnabled: boolean;
}

interface AuthoringHelper {
  innerRef: React.Ref<HTMLDivElement>;
  sendStructureDataToAuthoringCB: (structureData: string) => void;
  sendDefaultStructureDataForResetToAuthoringCB: (structureData: string) => void;
  openButtonEditWindowInAuthoringCB: (buttonObj?: object, e?: object) => void;
  selectedButtonNameForEditing: string;
  isEditing: boolean;
  buttonRef?: React.Ref<HTMLButtonElement>;
  buttonContentEditable?: boolean;
  lastSavedStructuredData?: string;
  isEditPage?: boolean;
}

interface Content {
  WelcomeText?: string;
  Title?: string;
  Heading?: string;
  Description?: string;

  Button1_Action?: string;
  Button1_Content?: string;
  Button1_Name?: string;
  Button1_RedirectURL?: string;
  Button1_RestEndPonit?: string;
  Button1_Type?: string;
  Button1_Value?: string;

  Button2_Action?: string;
  Button2_Content?: string;
  Button2_Name?: string;
  Button2_RedirectURL?: string;
  Button2_RestEndPonit?: string;
  Button2_Type?: string;
  Button2_Value?: string;

  Button3_Action?: string;
  Button3_Content?: string;
  Button3_Name?: string;
  Button3_RedirectURL?: string;
  Button3_RestEndPonit?: string;
  Button3_Type?: string;
  Button3_Value?: string;

  Button4_Action?: string;
  Button4_Content?: string;
  Button4_Name?: string;
  Button4_RedirectURL?: string;
  Button4_RestEndPonit?: string;
  Button4_Type?: string;
  Button4_Value?: string;

  Button5_Action?: string;
  Button5_Content?: string;
  Button5_Name?: string;
  Button5_RedirectURL?: string;
  Button5_RestEndPonit?: string;
  Button5_Type?: string;
  Button5_Value?: string;
  cropType: "single" | "multiple";
  aspectRatio?: any[];
  TagName?: string;
  ImageCompound: {
    ImageCompound_1: {
      published_images: ImageType[];
      original_image?: object;
    };
  };
  selected_prelem_bg_color?: string;
}
interface ImageType {
  aspect_ratio: string;
  bucket_path: string;
  folder_path: string;
  visibility: string;
  ext: string;
}

export default WebsiteIntroduction2;
