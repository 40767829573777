import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { SyntheticEvent, useState } from "react";
import { useInView } from "react-intersection-observer";
import "../../Style.css";
import ContentReplace from "../../components/ContentReplace/ContentReplace";
import { usePrelemImpression } from "../../components/ImpressionHooks/PrelemImpressionHook";
import { SkeletonLoader } from "../../components/SkeletonLoader/SkeletonLoader";
import TwoColumnLayout from "../../components/layouts/TwoColumns/TwoColumnLayout";
import prelemTypes from "../../globalStyle";
import useContentData from "../../utils/DynamicSchema/useContentData";
import { getDescriptionColor, getPrelemType, getTypographyColor } from "../../utils/Themefunctions";
import { useCustomStyle } from "./Faq1.style";

const FAQ1 = ({ content, analytics, authoringHelper, secondaryArgs }: any) => {
  const [expanded, setExpanded] = useState<number | false>(false);
  const prelemType = content?.selected_prelem_bg_color;
  const classes = useCustomStyle(getPrelemType(prelemType));
  const globalClasses = prelemTypes();

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const onContentReplace = () => {
    secondaryArgs?.multiSlot?.onToggleContentGallery();
  };

  usePrelemImpression(analytics, inView, secondaryArgs);

  const gridVal = {
    md: [12, 12],
    em: [5, 7],
    lg: [5, 7],
  };
  const handleChange = (panel: number) => (_event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { contentValue, loader } = useContentData(content, secondaryArgs);

  const firstColumnContent = () => {
    return (
      <Box className='faq'>
        <Typography
          variant='h2medium'
          id='title'
          className='title'
          color={getTypographyColor(prelemType)}>
          {content?.title}
        </Typography>
      </Box>
    );
  };
  const secondColumnContent = () => {
    return (
      <Box
        className='accordianWrapper'
        sx={{
          "&:hover": {
            ".add-content-overlay": {
              display: authoringHelper?.isEditing ? "flex !important" : "none",
            },
          },
        }}>
        {contentValue && !loader ? (
          <>
            {contentValue?.map((contentItem: any, index: any) => {
              return (
                <Accordion
                  key={index}
                  expanded={expanded === index}
                  onChange={handleChange(index)}
                  disableGutters
                  elevation={0}
                  square
                  className='accordianHeading'>
                  <AccordionSummary
                    className='accordiansummary'
                    expandIcon={expanded === index ? <RemoveIcon /> : <AddIcon />}>
                    <Typography variant='h4semibold' color={getTypographyColor(prelemType)}>
                      {contentItem?.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className='accordianDetail'>
                    <Box>
                      <Typography variant='p2regular' color={getTypographyColor(prelemType)}>
                        {contentItem?.description}
                      </Typography>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </>
        ) : loader ? (
          <SkeletonLoader />
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
            }}>
            {content?.selected_items?.length === 0 ? (
              <Typography variant='p3regular' color={getDescriptionColor(prelemType)}>
                Choose FAQs to view it here.
              </Typography>
            ) : null}
          </Box>
        )}
        <ContentReplace onReplaceClick={onContentReplace} />
      </Box>
    );
  };

  return (
    <div
      ref={authoringHelper?.innerRef}
      className={`${classes.faq1Wrapper} ${globalClasses.prelemType1} prelem prelemType1 faq1 faq1Bg`}>
      <Container
        className={
          authoringHelper?.isEditPage ? "grid_full_width prelem-py" : "grid_container prelem-py"
        }>
        <Grid container ref={ref}>
          <TwoColumnLayout
            firstColumnContent={firstColumnContent()}
            secondColumnContent={secondColumnContent()}
            gridVal={gridVal}
            customClassName='FAQ1'
            noGap={true}
            col1Align='start'
            col2Align='start'
          />
        </Grid>
      </Container>
    </div>
  );
};

export default FAQ1;
