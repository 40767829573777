import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = (selectedType) => {
  const theme = useTheme();
  return makeStyles(() => {
    return {
      quoteWrapperPrelem: {
        "&.quoteBg": {
          background: theme.palette?.[selectedType]?.BACKGROUND,
          "& .labelText": {
            color: theme.palette?.[selectedType]?.LABEL,
          },
          "& .headingText": {
            color: theme.palette?.[selectedType]?.TITLE,
          },
          "& .descriptionText": {
            color: theme.palette?.[selectedType]?.PARAGRAPH,
          },
          "& .typographyFirst": {
            textAlign: "center",
            [theme.breakpoints.down("sm")]: {
              textAlign: "left",
            },
          },
          "& .typographySecond": {
            textAlign: "right",
          },
          "& .wrapperImg": {
            marginBottom: "0px",
            [theme.breakpoints.down("sm")]: {
              marginBottom: "20px",
            },
          },
        },
      },
    };
  })();
};
