import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Box, Container, Grid, Typography } from "@mui/material";
import { formCroppedUrlString } from "@platformx/utilities";
import React, { Fragment, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import "../../Style.css";
import { usePrelemImpression } from "../../components/ImpressionHooks/PrelemImpressionHook";
import prelemTypes from "../../globalStyle";
import { getPrelemType, getTypographyColor } from "../../utils/Themefunctions";
import "./BlogTiles.css";
import { useCustomStyle } from "./BlogTiles.style";
import BlogTilesCard from "./BlogTilesCard";
import BlogTilesCard2 from "./BlogTilesCard2";

const BlogTiles = ({ content, analytics, authoringHelper, secondaryArgs }) => {
  const prelemType = content.selected_prelem_bg_color;
  const classes = useCustomStyle(getPrelemType(prelemType));
  const globalClasses = prelemTypes();
  const { bucketName, gcpUrl } = secondaryArgs;
  const getCardArr = (data: any) => {
    if (data?.length > 5) {
      return data.slice(0, 5);
    } else if (data?.length > 0) {
      return data;
    }
    return [];
  };
  const [cardArr, setCardArr] = useState(getCardArr(content?.Slots));
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  usePrelemImpression(analytics, inView, secondaryArgs);

  useEffect(() => {
    setCardArr(getCardArr(content?.Slots));
  }, [content?.Slots]);

  const getGridValues = () => {
    let md = 4,
      em = 3;
    if (cardArr.length === 1) {
      md = 12;
      em = 12;
    } else if (cardArr.length === 2) {
      md = 6;
      em = 6;
    } else if (cardArr.length === 3) {
      em = 4;
      md = 4;
    } else if (cardArr.length === 4) {
      md = 6;
      em = 6;
    }
    return { md, em };
  };

  return (
    <div
      ref={authoringHelper?.innerRef}
      className={`${classes.blogTilesWrapper} ${globalClasses.prelemType1} prelem prelemType1 blogTilesBg`}>
      <Container
        className={
          authoringHelper?.isEditPage ? "grid_full_width prelem-py" : "grid_container prelem-py"
        }>
        <Box ref={ref}>
          <Box className='blogTitleWrapper'>
            <Typography
              variant='h2semibold'
              id='Title'
              className='headingText'
              sx={{
                display: authoringHelper?.isEditing || content?.Title ? "block" : "none",
              }}>
              {content?.Title}
            </Typography>
          </Box>
          <Grid
            container
            className='blogTilesInnerWrapper'
            sx={{
              "&:hover": {
                ".add-content-overlay": {
                  display: authoringHelper?.authoringHoverShow ? "flex !important" : "none",
                },
              },
            }}>
            {cardArr?.length > 0 ? (
              cardArr?.length <= 4 ? (
                cardArr?.map((item: any, index: any) => {
                  const { md, em } = getGridValues();
                  return (
                    <Grid item xs={12} md={md} em={em} rowSpacing={1.5} p={1.5} key={index}>
                      {cardArr && Object.keys(item).length !== 0 && (
                        <Fragment>
                          <Box
                            sx={{
                              display: { xs: "block", md: "none", em: "none" },
                            }}>
                            <BlogTilesCard
                              content={cardArr?.[index]}
                              secondaryArgs={secondaryArgs}
                              analytics={analytics}
                              cardIndex={index}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: { xs: "none", md: "block", em: "none" },
                            }}>
                            <BlogTilesCard
                              content={cardArr?.[index]}
                              secondaryArgs={secondaryArgs}
                              analytics={analytics}
                              cardIndex={index}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: { xs: "none", md: "none", em: "block" },
                            }}>
                            {em === 12 ? (
                              <BlogTilesCard2
                                content={cardArr?.[index]}
                                secondaryArgs={secondaryArgs}
                                analytics={analytics}
                                cardIndex={index}
                              />
                            ) : (
                              <BlogTilesCard
                                content={cardArr?.[index]}
                                secondaryArgs={secondaryArgs}
                                analytics={analytics}
                                cardIndex={index}
                              />
                            )}
                          </Box>
                        </Fragment>
                      )}
                    </Grid>
                  );
                })
              ) : (
                <Grid
                  container
                  className='fivecardswp'
                  sx={{ display: { xs: "flex", md: "flex", em: "flex" } }}>
                  <Grid
                    className='leftGrid'
                    xs={12}
                    md={12}
                    em={7}
                    rowSpacing={1.5}
                    p={1.5}
                    sx={{ display: { xs: "flex", md: "none", em: "flex" } }}>
                    <BlogTilesCard
                      content={cardArr?.[0]}
                      secondaryArgs={secondaryArgs}
                      analytics={analytics}
                      cardIndex={0}
                    />
                  </Grid>
                  <Grid className='rightGrid' xs={12} md={12} em={5}>
                    <Grid container sx={{ justifyContent: "center", display: "flex" }}>
                      <Grid
                        xs={12}
                        md={6}
                        em={6}
                        rowSpacing={1.5}
                        p={1}
                        sx={{ display: { xs: "none", md: "flex", em: "none" } }}
                        className='SmallCard'>
                        <BlogTilesCard
                          content={cardArr?.[0]}
                          secondaryArgs={secondaryArgs}
                          analytics={analytics}
                          cardIndex={0}
                        />
                      </Grid>
                      <Grid xs={12} md={6} em={6} rowSpacing={1.5} p={1.5} className='SmallCard'>
                        <BlogTilesCard
                          content={cardArr?.[1]}
                          secondaryArgs={secondaryArgs}
                          analytics={analytics}
                          cardIndex={0}
                        />
                      </Grid>
                      <Grid xs={12} md={6} em={6} rowSpacing={1.5} p={1.5} className='SmallCard'>
                        <BlogTilesCard
                          content={cardArr?.[2]}
                          secondaryArgs={secondaryArgs}
                          analytics={analytics}
                          cardIndex={0}
                        />
                      </Grid>
                      <Grid xs={12} md={6} em={6} rowSpacing={1.5} p={1.5} className='SmallCard'>
                        <BlogTilesCard
                          content={cardArr?.[3]}
                          secondaryArgs={secondaryArgs}
                          analytics={analytics}
                          cardIndex={0}
                        />
                      </Grid>
                      <Grid xs={12} md={6} em={6} rowSpacing={1.5} p={1.5} className='SmallCard'>
                        <BlogTilesCard
                          content={cardArr?.[4]}
                          secondaryArgs={secondaryArgs}
                          analytics={analytics}
                          cardIndex={0}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )
            ) : (
              <React.Fragment>
                <Box className='noDataFoundWrapper'>
                  <img
                    src={
                      formCroppedUrlString(
                        gcpUrl,
                        bucketName,
                        secondaryArgs?.noResultImg,
                        secondaryArgs?.ext,
                      ).src
                    }
                    alt='NoDataFound'
                  />
                </Box>
                <Typography
                  variant='h2'
                  className='noDataAlingment'
                  color={getTypographyColor(prelemType)}>
                  No data found
                </Typography>
              </React.Fragment>
            )}
            <Box className='add-content-overlay replaceWrapper'>
              <Box
                className='pointer'
                onClick={() => secondaryArgs?.multiSlot?.onToggleContentGallery()}
                data-testid='toggle-content-gallery'>
                <AutorenewIcon className='replaceIconWrapper' />
                <Typography variant='p1regular' color='textColor'>
                  Replace
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default BlogTiles;
