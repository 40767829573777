import { Box, Container, Typography } from "@mui/material";
import { Frame1, Frame2, Frame3 } from "@platformx/shared/static-assets";
import { completeButtonUrl, formCroppedUrlString } from "@platformx/utilities";
import { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import Typewriter from "typewriter-effect";
import "../../Style.css";
import BasicButton from "../../components/BasicButton/BasicButton";
import CommonDescription from "../../components/CommonDescription";
import ImageRender from "../../components/ImageRender";
import { usePrelemImpression } from "../../components/ImpressionHooks/PrelemImpressionHook";
import TwoColumnLayout from "../../components/layouts/TwoColumns/TwoColumnLayout";
import prelemTypes from "../../globalStyle";
import { getButtonVariant, getDescriptionColor, getPrelemType } from "../../utils/Themefunctions";
import { useCustomStyle } from "./AboutUS2.style";

const AboutUs2 = ({ content, analytics, authoringHelper, secondaryArgs }: any) => {
  const prelemType = content.selected_prelem_bg_color;
  const classes = useCustomStyle(getPrelemType(prelemType));
  const globalClasses = prelemTypes();

  const ButtonObj1 = {
    Button_Name: "Button1_Name",
    Button_RedirectURL: "Button1_RedirectURL",
    Button_Type: "Button1_Type",
    Button_Value: "Button1_Value",
    Button_Action: "Button1_Action",
    Button_Content: "Button1_Content",
  };
  const ButtonDataObj1 = {
    Button_Name: content?.Button1_Name,
    Button_RedirectURL: content?.Button1_RedirectURL,
    Button_Type: content?.Button1_Type,
    Button_Value: content?.Button1_Value,
    Button_Action: content?.Button1_Action,
    Button_Content: content?.Button1_Content,
  };

  const firstRender = useRef(true);
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const defaultStructureData = () => {
    let aboutUs2StructureData;
    const { original_image_relative_path, ext }: any =
      content?.ImageCompound?.ImageCompound_1?.original_image || {};
    const img = formCroppedUrlString(
      secondaryArgs?.gcpUrl,
      secondaryArgs?.bucketName,
      original_image_relative_path,
      ext,
    ).src;
    try {
      aboutUs2StructureData = {
        "@context": "https://schema.org/",
        "@type": "ImageObject",
        title: content?.primary_heading,
        name: content?.secondary_heading,
        description: content?.description,
        keywords: content?.typescript_heading,
        url: completeButtonUrl(
          content?.Button1_Action,
          content?.Button1_RedirectURL,
          secondaryArgs?.prelemBaseEndpoint?.buttonBaseUrl,
        ),
        image: img,
      };
    } catch (e) {
      aboutUs2StructureData = {};
    }
    return aboutUs2StructureData;
  };
  const genrateStructureData = () => {
    let aboutUs2StructureData;
    const tempSD = String(authoringHelper?.lastSavedStructuredData);

    if (firstRender.current) {
      const defaultSD = defaultStructureData();
      const stringifyStructureData = defaultSD && JSON.stringify(defaultSD);
      authoringHelper?.sendDefaultStructureDataForResetToAuthoringCB(stringifyStructureData || "");

      if (String(tempSD).length > 0) {
        aboutUs2StructureData = JSON.parse(tempSD);
      } else {
        aboutUs2StructureData = defaultStructureData();
      }
      firstRender.current = false;
    } else {
      aboutUs2StructureData = defaultStructureData();
    }
    return aboutUs2StructureData;
  };
  const stringToArray = (typeString: any) => {
    let resultString = typeString;
    if (typeof document !== "undefined") {
      if (document.getElementById("site-frame")) {
        const iframe = document?.getElementById("site-frame") as HTMLIFrameElement;
        resultString =
          iframe?.contentWindow?.document?.getElementById("typescript_heading")?.innerText;
      } else {
        resultString = document.getElementById("typescript_heading")?.innerText;
      }
      if (resultString?.length > 0) {
        return resultString?.split(",");
      }
    }
  };
  useEffect(() => {
    if (analytics?.isAuthoring && analytics?.isSeoEnabled) {
      const structureData = genrateStructureData();
      const stringifyStructureData = structureData && JSON.stringify(structureData);
      authoringHelper?.sendStructureDataToAuthoringCB(stringifyStructureData || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    content?.description,
    content?.ImageCompound?.ImageCompound_1?.original_image,
    content?.primary_heading,
    content?.secondary_heading,
    content?.Button1_Value,
    content?.Button1_RedirectURL,
    content?.typescript_heading,
  ]);

  usePrelemImpression(analytics, inView, secondaryArgs);

  const gridVal = {
    md: [12, 12],
    em: [5, 7],
    lg: [5, 7],
  };
  const firstColumnContent = () => {
    return (
      <Box className='textcenter'>
        <Typography
          variant='h1bold'
          id='primary_heading'
          className='title headingText'
          sx={{
            display: authoringHelper?.isEditing || content?.primary_heading ? "block" : "none",
          }}>
          {content?.primary_heading}
        </Typography>
        <Box
          className='blinkText'
          sx={{
            display: authoringHelper?.isEditing || content?.typescript_heading ? "block" : "none",
          }}>
          <Typography
            variant='h1bold'
            id='typescript_heading'
            component='span'
            className='category'
            sx={{
              display: secondaryArgs?.editState ? "inherit" : "none",
            }}>
            {content?.typescript_heading}
          </Typography>

          {!secondaryArgs?.editState && (
            <Typography
              variant='h1bold'
              component='span'
              id='typescript_heading'
              className='categoryText headingText'>
              <Typewriter
                options={{
                  strings: stringToArray(content?.typescript_heading),
                  autoStart: true,
                  loop: true,
                }}
              />
            </Typography>
          )}
        </Box>
        <Box className='textMaxWidth'>
          <CommonDescription
            content={content}
            analytics={analytics}
            secondaryArgs={secondaryArgs}
            id='description'
            color={getDescriptionColor(prelemType)}
          />
          <BasicButton
            openButtonEditWindow={authoringHelper?.openButtonEditWindowInAuthoringCB}
            isAuthoring={analytics?.isAuthoring}
            currentBtnEditing={authoringHelper?.selectedButtonNameForEditing}
            variant={getButtonVariant(prelemType, "Button1")}
            analyticsEnabled={analytics?.isAnalyticsEnabled}
            ButtonObj={ButtonObj1}
            isEditing={authoringHelper?.isEditing}
            buttonDataObj={ButtonDataObj1}
            secondaryArgs={secondaryArgs}
            analytics={analytics}
            style={{
              display:
                authoringHelper?.isEditing ||
                (content?.Button1_Value && content?.Button1_Value !== "\n")
                  ? "inline-flex"
                  : "none",
            }}
          />
        </Box>
      </Box>
    );
  };
  const secondColumnContent = () => {
    return (
      <Box className='imageWrapper widthheight100'>
        <ImageRender
          originalImage={content?.ImageCompound?.ImageCompound_1?.original_image}
          publishedImages={content?.ImageCompound?.ImageCompound_1?.published_images}
          secondaryArgs={secondaryArgs}
          cropType={content?.cropType?.toLowerCase()}
          imgOrder={{
            1440: "square",
            1280: "square",
            1024: "square",
            768: "square",
            600: "square",
            320: "square",
          }}
          index={0}
          isEditing={authoringHelper?.isEditing}
        />
        <Box className='backgroundText'>
          <Typography
            variant='h1bold'
            id='secondary_heading'
            className='fadeBackground'
            component='span'
            sx={{
              display: authoringHelper?.isEditing || content?.secondary_heading ? "block" : "none",
            }}>
            {content?.secondary_heading}
          </Typography>
        </Box>
        <Box className='imageWrapper1'>
          <img alt='About us' src={Frame1} className='frame1' width='200' height='87' />
        </Box>
        <Box className='imageWrapper2'>
          <img alt='About us' src={Frame2} className='frame2' width='170' height='72' />
        </Box>
        <Box className='imageWrapper3'>
          <img alt='About us' src={Frame3} className='frame3' width='232' height='40' />
        </Box>
      </Box>
    );
  };
  return (
    <div
      ref={authoringHelper?.innerRef}
      className={`${classes.aboutUs2Wrapper} ${globalClasses.prelemType1} prelem prelemType1 about-us2 aboutUs2Bg`}>
      <Container
        className={
          authoringHelper?.isEditPage ? `grid_full_width prelem-py` : `grid_container prelem-py`
        }
        ref={ref}>
        <TwoColumnLayout
          firstColumnContent={firstColumnContent()}
          secondColumnContent={secondColumnContent()}
          gridVal={gridVal}
          customClassName='aboutUs2'
          noGap={true}
        />
      </Container>
    </div>
  );
};

export default AboutUs2;
