/* eslint-disable @typescript-eslint/no-empty-function */
import DoneIcon from "@mui/icons-material/Done";
import { Box, Container, Grid, Typography } from "@mui/material";
import {
  Analytics,
  AuthoringHelper,
  SecondaryArgs,
  completeButtonUrl,
  fetchCroppedUrl,
  formCroppedUrlString,
  getThumbImages,
} from "@platformx/utilities";
import { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import BasicButton from "../../components/BasicButton/BasicButton";
import CommonDescription from "../../components/CommonDescription";
import useCustomMediaQuery from "../../components/CustomHook/useCustomMediaQuery";
import ImageRender from "../../components/ImageRender";
import { usePrelemImpression } from "../../components/ImpressionHooks/PrelemImpressionHook";
import VideoPlayer from "../../components/VideoPlayers/VideoPlayer";
import prelemTypes from "../../globalStyle";
import { getButtonVariant, getDescriptionColor, getPrelemType } from "../../utils/Themefunctions";
import { useCustomStyle } from "./AboutUsThree.style";

const AboutUsThree = ({
  content,
  analytics,
  authoringHelper,
  secondaryArgs,
}: AboutUsThreeProps) => {
  const prelemType = content.selected_prelem_bg_color;
  const classes = useCustomStyle(getPrelemType(prelemType));
  const globalClasses = prelemTypes();
  const mediaQueryValues = useCustomMediaQuery;
  const ButtonObj1 = {
    Button_Name: "Button1_Name",
    Button_RedirectURL: "Button1_RedirectURL",
    Button_Type: "Button1_Type",
    Button_Value: "Button1_Value",
    Button_Action: "Button1_Action",
    Button_Content: "Button1_Content",
  };
  const ButtonDataObj1 = {
    Button_Name: content?.Button1_Name,
    Button_RedirectURL: content?.Button1_RedirectURL,
    Button_Type: content?.Button1_Type,
    Button_Value: content?.Button1_Value,
    Button_Action: content?.Button1_Action,
    Button_Content: content?.Button1_Content,
  };

  const firstRender = useRef(true);
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const defaultStructureData = () => {
    let AboutUsThreeStructureData;
    const { original_image_relative_path, ext }: any =
      content?.ImageCompound?.ImageCompound_1?.original_image || {};
    const img = formCroppedUrlString(
      secondaryArgs?.gcpUrl,
      secondaryArgs?.bucketName,
      original_image_relative_path,
      ext,
    ).src;
    try {
      AboutUsThreeStructureData = {
        "@context": "https://schema.org/",
        "@type": "WebPage",
        name: content?.Title,
        description: content?.Description,
        url: completeButtonUrl(
          content?.Button1_Action,
          content?.Button1_RedirectURL,
          secondaryArgs?.prelemBaseEndpoint?.buttonBaseUrl,
        ),
        image: img,
        video: content?.Videos?.Video_1?.Url,
      };
    } catch (e) {
      AboutUsThreeStructureData = {};
    }
    return AboutUsThreeStructureData;
  };
  const genrateStructureData = () => {
    let AboutUsThreeStructureData;
    const tempSD = String(authoringHelper?.lastSavedStructuredData);

    if (firstRender.current) {
      const defaultSD = defaultStructureData();
      const stringifyStructureData = defaultSD && JSON.stringify(defaultSD);
      authoringHelper?.sendDefaultStructureDataForResetToAuthoringCB(stringifyStructureData || "");

      if (String(tempSD).length > 0) {
        AboutUsThreeStructureData = JSON.parse(tempSD);
      } else {
        AboutUsThreeStructureData = defaultStructureData();
      }
      firstRender.current = false;
    } else {
      AboutUsThreeStructureData = defaultStructureData();
    }
    return AboutUsThreeStructureData;
  };

  useEffect(() => {
    if (analytics?.isAuthoring && analytics?.isSeoEnabled) {
      const structureData = genrateStructureData();
      const stringifyStructureData = structureData && JSON.stringify(structureData);
      authoringHelper?.sendStructureDataToAuthoringCB(stringifyStructureData || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    content?.Title,
    content?.Description,
    content?.Description1,
    content?.Description2,
    content?.Description3,
    content?.ImageCompound?.ImageCompound_1?.original_image,
    content?.Videos?.Video_1?.Url,
    content?.Button1_Value,
    content?.Button1_RedirectURL,
  ]);

  usePrelemImpression(analytics, inView, secondaryArgs);
  const thumbnailImg = getThumbImages(content?.Videos?.Video_1?.Thumbnail);
  const posterImage =
    thumbnailImg &&
    fetchCroppedUrl(
      content?.Videos?.Video_1?.Url ? content.Videos.Video_1.Url : "",
      thumbnailImg,
      {
        1440: "portrait",
        1280: "portrait",
        1024: "portrait",
        768: "portrait",
        600: "portrait",
        320: "portrait",
      },
      {},
      mediaQueryValues,
      secondaryArgs,
      false,
      content?.Videos?.Video_1?.ext ? content?.Videos?.Video_1?.ext : "",
    );
  const getDescription = (description) => (description === "<br>" ? "" : description);
  const description1 = getDescription(content?.Description1);
  const description2 = getDescription(content?.Description2);
  const description3 = getDescription(content?.Description3);
  return (
    <div ref={authoringHelper?.innerRef}>
      <Box
        className={`${classes.aboutUsThreeWrapper} ${globalClasses.prelemType3} prelem prelemType3 aboutus-three aboutUsThreeBg`}
        ref={ref}>
        <Container className={authoringHelper?.isEditPage ? "grid_full_width" : "grid_container"}>
          <Grid container ref={ref} className='gridcontainer'>
            <Grid item xs={12} sm={12} md={12} em={6} lg={6}>
              <Grid container>
                <Grid item xs={6}>
                  <Box className='imageWrapper'>
                    <ImageRender
                      originalImage={content?.ImageCompound?.ImageCompound_1?.original_image}
                      publishedImages={content?.ImageCompound?.ImageCompound_1?.published_images}
                      secondaryArgs={secondaryArgs}
                      imgOrder={{
                        1440: "portrait",
                        1280: "portrait",
                        1024: "portrait",
                        768: "portrait",
                        600: "portrait",
                        320: "portrait",
                      }}
                      isEditing={authoringHelper?.isEditing}
                      index={0}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box className='imageWrapper1'>
                    {content?.Videos?.Video_1?.Url && (
                      <VideoPlayer
                        playerProp={{
                          posterImg: posterImage,
                          videoUrl: content?.Videos?.Video_1.Url,
                        }}
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} em={6} lg={6} className='contentWrapper'>
              <Typography component='h2' variant='h2medium' id='Title' className='headingText'>
                {content.Title}
              </Typography>
              <Box>
                <CommonDescription
                  content={content}
                  analytics={analytics}
                  secondaryArgs={secondaryArgs}
                  color={getDescriptionColor(prelemType)}
                  id='Description'
                />
              </Box>
              <Box>
                <Box component='ul' sx={{ padding: 0, margin: 0 }}>
                  <Box
                    component='li'
                    className='alignList'
                    sx={{
                      display:
                        authoringHelper?.isEditing || description1 ? "flex" : "none !important",
                    }}>
                    <DoneIcon className='doneIcon' />
                    <Typography
                      component='p'
                      variant='p3regular'
                      className='descriptionText'
                      id='Description1'
                      dangerouslySetInnerHTML={{
                        __html: authoringHelper?.isEditing || description1 ? description1 : "",
                      }}
                    />
                  </Box>
                  <Box
                    component='li'
                    className='alignList'
                    sx={{
                      display:
                        authoringHelper?.isEditing || description2 ? "flex" : "none !important",
                    }}>
                    <DoneIcon className='doneIcon' />
                    <Typography
                      component='p'
                      variant='p3regular'
                      className='descriptionText'
                      id='Description2'
                      dangerouslySetInnerHTML={{
                        __html: authoringHelper?.isEditing || description2 ? description2 : "",
                      }}
                    />
                  </Box>
                  <Box
                    component='li'
                    className='alignList'
                    sx={{
                      display:
                        authoringHelper?.isEditing || description3 ? "flex" : "none !important",
                    }}>
                    <DoneIcon className='doneIcon' />
                    <Typography
                      component='p'
                      variant='p3regular'
                      className='descriptionText'
                      id='Description3'
                      dangerouslySetInnerHTML={{
                        __html: authoringHelper?.isEditing || description3 ? description3 : "",
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box className='gap'>
                  <BasicButton
                    openButtonEditWindow={authoringHelper?.openButtonEditWindowInAuthoringCB}
                    isAuthoring={analytics?.isAuthoring}
                    currentBtnEditing={authoringHelper?.selectedButtonNameForEditing}
                    variant={getButtonVariant(prelemType, "Button1")}
                    analyticsEnabled={analytics?.isAnalyticsEnabled}
                    ButtonObj={ButtonObj1}
                    isEditing={authoringHelper?.isEditing}
                    buttonDataObj={ButtonDataObj1}
                    secondaryArgs={secondaryArgs}
                    analytics={analytics}
                    style={{
                      display:
                        authoringHelper?.isEditing ||
                        (content?.Button1_Value && content?.Button1_Value !== "\n")
                          ? "inline-flex"
                          : "none",
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

interface AboutUsThreeProps {
  content: Content;
  analytics: Analytics;
  authoringHelper?: AuthoringHelper;
  secondaryArgs: SecondaryArgs;
}

interface Content {
  Title?: string;
  Description?: string;
  Description1?: string;
  Description2?: string;
  Description3?: string;
  Button1_Action?: string;
  Button1_Content?: string;
  Button1_Name?: string;
  Button1_RedirectURL?: string;
  Button1_RestEndPonit?: string;
  Button1_Type?: string;
  Button1_Value?: string;
  Videos?: {
    Video_1: {
      Name: string;
      Url: string;
      Title: string;
      Description: string;
      Attribution: boolean;
      Transcript: boolean;
      Thumbnail: string;
      CC: boolean;
      ext: string;
    };
  };
  selected_prelem_bg_color?: string;
  TagName?: string;
  ImageCompound: {
    ImageCompound_1: {
      published_images: Image[];
      original_image?: OriginalImage;
    };
  };
}

interface OriginalImage {
  original_image_relative_path: string;
  ext: string;
}
interface Image {
  aspect_ratio: string;
  bucket_path: string;
  folder_path: string;
  visibility: string;
  ext: string;
}

export default AboutUsThree;
