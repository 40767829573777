import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    playerDetailWrapper: {
      "&.playerDetailWrapperBg": {
        background: theme.palette.prelemType1.BACKGROUND,
      },
    },
  };
});
