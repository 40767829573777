import { getRestApiCall, onClickCardUrlNavigate, postRestApiCall } from "@platformx/utilities";

/**
 * courseId based get course fill details
 * post call
 */
export const getCourseDetailsApiCall = (courseId: string, secondaryArgs: any) => {
  const {
    prelemBaseEndpoint: {
      deliveryEndPoint = "https://marvericks.delivery.hcl-x.com/platform-x/",
      language = "en",
    } = {},
    sitename,
  } = secondaryArgs;
  return getRestApiCall(
    `${deliveryEndPoint}api/v1/web/en/delivery/course-model?path=${courseId}`,
    language,
    sitename,
  );
  // return getRestApiCall(
  //   `https://marvericks.delivery.hcl-x.com/platform-x/api/v1/web/en/delivery/course-model?path=108058619401306`
  // );
};

/**
 * courseId based get course fill details
 * post call
 */
export const getLearningListApiCall = (ele: any) => {
  const { secondaryArgs = {}, userId = "" } = ele;
  const {
    prelemBaseEndpoint: {
      // deliveryEndPoint = "https://dev.users.hcl-x.com/platform-x/user-service/",
      language = "en",
    } = {},
    sitename,
  } = secondaryArgs;

  const data = JSON.stringify({
    query: `query { userCoursesList(user_id:${JSON.stringify(userId)}) {
      user_id 
      title 
      description 
      author 
      lessons 
      teaser 
      teaser_image 
      course_id 
      course_url 
      status
      created_at
      updated_at
    } } `,
  });
  return postRestApiCall(
    `https://dev.users.hcl-x.com/platform-x/user-service/`,
    data,
    language,
    sitename,
  );
};
export const getUserInfo = (key) => {
  const userLoginDetails = localStorage.getItem("userLoginDetails");
  if (userLoginDetails) {
    const useDeatils = JSON.parse(userLoginDetails);
    return useDeatils?.data?.[key];
  } else {
    return null;
  }
};

export const parseSelectedObject = (selectedPersonalisation) => {
  if (selectedPersonalisation && typeof selectedPersonalisation === "string") {
    try {
      let parseSelectedPersonalisation;
      // Try parsing once for selected Personalisation
      parseSelectedPersonalisation = JSON.parse(selectedPersonalisation);
      // If still a string, attempt to parse again
      if (parseSelectedPersonalisation && typeof parseSelectedPersonalisation === "string") {
        parseSelectedPersonalisation = JSON.parse(parseSelectedPersonalisation);
      }
      return parseSelectedPersonalisation;
    } catch (e) {
      console.error(e);
    }
  }
  return selectedPersonalisation;
};

export const getGalleryItems = (contentValue) => {
  const gallery =
    contentValue?.length > 0
      ? contentValue?.map((item) => {
          if (item?.img_gallery) return JSON.parse(item?.img_gallery);
          else if (item?.vid_gallery) return JSON.parse(item?.vid_gallery);
          else if (item?.gallery) return JSON.parse(item?.gallery);
          else return "";
        })
      : [];
  return gallery?.flat(1) || [];
};

export const onClickCard = (
  e: any,
  id: string,
  content,
  secondaryArgs,
  cardIndex,
  analytics,
  triggerClickAnalytics,
  toggleModalStatus,
) => {
  if (secondaryArgs.editState) {
    e.preventDefault();
  } else {
    const url = onClickCardUrlNavigate(id, content, secondaryArgs);
    triggerClickAnalytics(
      url,
      cardIndex,
      analytics,
      secondaryArgs,
      content?.Title,
      content?.ContentType,
    );
    if (["gallery", "videogallery", "imagegallery"].includes(content?.ContentType.toLowerCase())) {
      toggleModalStatus();
    } else if (url) {
      window.open(url, "_self");
    }
  }
};
