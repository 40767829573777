import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = (selectedType) => {
  const theme = useTheme();
  return makeStyles(() => {
    return {
      signBoardWrapper: {
        "&.signBoardBg": {
          background: theme.palette?.[selectedType].BACKGROUND,
          "& .leftContentWrapper": {
            textAlign: "left",
            [theme.breakpoints.down("md")]: {
              textAlign: "center",
            },
          },
          "& .rightButtonWrapper": {
            width: "100%",
            textAlign: "right",
            [theme.breakpoints.down("md")]: {
              textAlign: "center",
            },
            "& button": {
              marginRight: 0,
            },
          },
        },
      },
    };
  })();
};
