const customFunctions = {
  fetchColorsFromTheme: function () {
    return [
      {
        label: "PRIMARY",
        id: "prelemType1",
        parentId: 0,
      },
      {
        label: "SECONDARY",
        id: "prelemType2",
        parentId: 0,
      },
      {
        label: "TERTIARY",
        id: "prelemType3",
        parentId: 0,
      },
    ];
  },
};

export default customFunctions;
