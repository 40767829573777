import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Box, CardMedia, Typography } from "@mui/material";
import { formCroppedUrlString } from "@platformx/utilities";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import "../../Style.css";
import { usePrelemImpression } from "../../components/ImpressionHooks/PrelemImpressionHook";
import InfiniteLooper from "../../components/InfiniteLooper/InfiniteLooper";
import { SkeletonLoader } from "../../components/SkeletonLoader/SkeletonLoader";
import VideoPlayer from "../../components/VideoPlayers/VideoPlayer";
import prelemTypes from "../../globalStyle";
import useContentData from "../../utils/DynamicSchema/useContentData";
import { getPrelemType } from "../../utils/Themefunctions";
import { getGalleryItems } from "../../utils/helper";
import "./Gallery2.css";
import { useCustomStyle } from "./Gallery2.style";

// ts-ignore
const Gallery2 = ({ content, analytics, authoringHelper, secondaryArgs }) => {
  const globalClasses = prelemTypes();
  const prelemType = content.selected_prelem_bg_color;
  const classes = useCustomStyle(getPrelemType(prelemType));
  const [, setHoverState] = useState(false);

  const [GalleryOne, setGallery1] = useState<any>([]);
  const [GalleryTwo, setGallery2] = useState<any>([]);

  const { contentValue, loader } = useContentData(content, secondaryArgs);
  // const imageGallery =
  //   !loader && contentValue?.[0]?.img_gallery ? JSON.parse(contentValue?.[0]?.img_gallery) : [];

  // JSON.parse(contentValue?.[0]?.vid_gallery);
  // const videoGallery = !loader && contentValue?.[0]?.vid_gallery ? contentValue?.map : [];

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });
  usePrelemImpression(analytics, inView, secondaryArgs);
  /* AnalyticsEnabled dependency added as many times we are not getting analytics provider*async call)
  1. we are first checking in publish app if analytics provider is avaiable or not
  2. if its available we are setting AnalyticsEnabled to true
  3. if its not available we are setting false
*/
  useEffect(() => {
    // const gallery = [...imageGallery, ...videoGallery];
    if (!loader) {
      const gallery = getGalleryItems(contentValue);
      const finalGallery = gallery;
      const midLength = finalGallery?.length / 2;
      const gallery1 = finalGallery?.slice(0, midLength);
      const gallery2 = finalGallery?.slice(midLength + 1, finalGallery?.length);
      setGallery1(gallery1);
      setGallery2(gallery2);
    }
  }, [loader]);

  return (
    <Box
      ref={authoringHelper?.innerRef}
      className={`${classes.gallery2Wrapper} ${globalClasses.prelemType3} prelem prelemType3 gallery2 gallery2Bg`}>
      <Box ref={ref} className='prelem-py'>
        <Box className='boxWrapper'>
          <Typography
            variant='h2medium'
            className='headingText'
            component='div'
            id='title'
            sx={{
              display: authoringHelper?.isEditing || content?.title ? "block" : "none",
            }}>
            {content.title}
          </Typography>
          {/* <VideoPlayer
            playerProp={{
              posterImg: "",
              videoUrl: GalleryOne?.[0]?.video?.Url,
              controls: false,
              loop: true,
            }}
          /> */}
          <Box
            className='descriptionBox'
            sx={{
              display: authoringHelper?.isEditing || content?.description ? "block" : "none",
            }}>
            <Typography variant='p3regular' className='descriptionText' id='description'>
              {content?.description}
            </Typography>
          </Box>
          <Box className={`looper-list looperListWrapper`}>
            {loader ? (
              <Box className='loader'>
                <SkeletonLoader />
                <SkeletonLoader />
              </Box>
            ) : (
              <>
                {GalleryOne.length === 0 ? (
                  <Box className='loader'>
                    <SkeletonLoader />
                  </Box>
                ) : (
                  <InfiniteLooper
                    speed={`${authoringHelper?.isModalShow ? GalleryOne.length * 18 : 0}`}
                    direction='left'>
                    <Box className='looperList'>
                      {GalleryOne?.map((item, index) => {
                        const formedUrl = item?.image
                          ? formCroppedUrlString(
                              secondaryArgs?.gcpUrl,
                              secondaryArgs?.bucketName,
                              item.image.original_image.original_image_relative_path,
                              item.image.original_image.ext,
                            ).src
                          : "";
                        return (
                          <Box
                            className='looperItem'
                            key={index}
                            onMouseEnter={() => setHoverState(true)}
                            onMouseLeave={() => setHoverState(false)}>
                            {formedUrl !== "" ? (
                              <CardMedia
                                className='thumbnail'
                                component={formedUrl ? "img" : "div"}
                                image={formedUrl ? formedUrl : ""}
                                sx={{
                                  // backgroundColor: color ? color : "",
                                  width: "656px",
                                  height: "276px",
                                }}
                              />
                            ) : (
                              <VideoPlayer
                                playerProp={{
                                  posterImg: "",
                                  videoUrl: item?.video?.Url,
                                  controls: false,
                                  loop: true,
                                }}
                              />
                            )}
                          </Box>
                        );
                      })}
                    </Box>
                  </InfiniteLooper>
                )}
                {GalleryTwo.length === 0 ? (
                  <Box className='loader'>
                    <SkeletonLoader />
                  </Box>
                ) : (
                  <InfiniteLooper
                    speed={`${authoringHelper?.isModalShow ? GalleryTwo.length * 18 - 20 : 0}`}
                    direction='left'>
                    <Box sx={{ display: "flex" }}>
                      {GalleryTwo?.map((item, index) => {
                        const formedUrl = item?.image
                          ? formCroppedUrlString(
                              secondaryArgs?.gcpUrl,
                              secondaryArgs?.bucketName,
                              item.image.original_image.original_image_relative_path,
                              item.image.original_image.ext,
                            ).src
                          : "";
                        return (
                          <Box className='looperItem' key={index}>
                            {formedUrl !== "" ? (
                              <CardMedia
                                className='thumbnail'
                                component={formedUrl ? "img" : "div"}
                                image={formedUrl ? formedUrl : ""}
                                sx={{
                                  // backgroundColor: color ? color : "",
                                  width: "656px",
                                  height: "276px",
                                }}
                              />
                            ) : (
                              <VideoPlayer
                                playerProp={{
                                  posterImg: "",
                                  videoUrl: item?.video?.Url,
                                  controls: false,
                                  loop: true,
                                }}
                              />
                            )}
                          </Box>
                        );
                      })}
                    </Box>
                  </InfiniteLooper>
                )}
                <Box className={authoringHelper?.isEditing ? "overlay" : "hideElementClass"}>
                  <Box
                    className='pointer'
                    onClick={() =>
                      secondaryArgs?.multiSlot?.onToggleContentGallery("gallery", true)
                    }>
                    <AutorenewIcon className='icon' />
                    <Typography className='overLaytextgap' variant='h3regular' color='textColor'>
                      Replace
                    </Typography>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Gallery2;
