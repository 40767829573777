import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = (selectedType) => {
  const theme = useTheme();
  return makeStyles(() => {
    return {
      aboutUsThreeWrapper: {
        "&.aboutUsThreeBg": {
          background: theme.palette?.[selectedType]?.BACKGROUND,
          "& .labelText": {
            color: theme.palette?.[selectedType]?.LABEL,
          },
          "& .headingText": {
            color: theme.palette?.[selectedType]?.TITLE,
          },
          "& .descriptionText": {
            color: theme.palette?.[selectedType]?.PARAGRAPH,
          },
          "& .gridcontainer": {
            padding: "25px 0",
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.up("md")]: {
              padding: "30px 0",
            },
            [theme.breakpoints.up("lg")]: {
              padding: "65px 0",
            },
          },
          "& .imageWrapper": {
            display: "flex",
            height: "272px",
            paddingRight: "5px",
            "& .rounderCardImages": {
              borderRadius: theme.borderRadius.value1,
            },
            [theme.breakpoints.up("sm")]: {
              height: "462px",
            },
            [theme.breakpoints.up("md")]: {
              height: "600px",
              paddingRight: "10px",
            },
            [theme.breakpoints.up("em")]: {
              height: "470px",
            },
            [theme.breakpoints.up("lg")]: {
              height: "500px",
              paddingRight: "16px",
            },
          },
          "& .imageWrapper1": {
            position: "relative",
            display: "flex",
            height: "272px",
            paddingLeft: "5px",
            [theme.breakpoints.up("sm")]: {
              height: "462px",
            },
            [theme.breakpoints.up("md")]: {
              height: "600px",
              paddingLeft: "10px",
            },
            [theme.breakpoints.up("em")]: {
              height: "470px",
            },
            [theme.breakpoints.up("lg")]: {
              height: "500px",
              paddingLeft: "16px",
            },
            "& video": {
              objectFit: "cover",
              borderRadius: theme.borderRadius.value1,
            },
          },
          "& .playIcon": {
            fontSize: "82px",
            color: theme.palette.textColor,
          },
          "& .contentWrapper": {
            marginTop: "17px",
            padding: "10px 0",
            [theme.breakpoints.up("md")]: {
              marginTop: "24px",
              padding: "0px 0px",
            },
            [theme.breakpoints.up("em")]: {
              marginTop: "0",
              padding: "0px 0 10px 44px",
            },
            [theme.breakpoints.up("xl")]: {
              padding: "0px 0 10px 64px",
            },
          },
          "& .alignList": {
            display: "flex",
            alignItems: "center",
          },
          "& .doneIcon": {
            color: theme.palette?.[selectedType]?.NOTIFICATION.SUCCESS.BACKGROUND,
            fontSize: "22px",
            marginRight: "10px",
          },
          "& .gap": {
            marginTop: "24px",
          },
          "& .reactPlayerWrapper .react-player__preview": {
            borderRadius: theme.borderRadius.value1,
          },
        },
      },
    };
  })();
};

export default useCustomStyle;
