import AddIcon from "@mui/icons-material/Add";
import ReplayIcon from "@mui/icons-material/Replay";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import { Box, styled, TextField, Typography } from "@mui/material";
import { DeleteIconList, UploadThumbnail } from "@platformx/shared/static-assets";
import {
  AUTH_INFO,
  formCroppedUrl,
  getRandomNumberAddon,
  relativeImageURL,
  ShowToastError,
} from "@platformx/utilities";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const GalleryOption = ({ showGallery, items, setItems, changeCrop }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isDeleteDisable, setIsDeleteDisable] = useState(false);

  const handleChange = (event) => {
    if (event.target.value.length > 100) {
      ShowToastError(`${t("max_char")} 100!`);
    }
    setItems(
      items.map((item) =>
        item.id === event.target.name ? { ...item, option: event.target.value } : item,
      ) as [],
    );
  };
  const onUploadClick = (id) => {
    showGallery("video", id);
  };
  const onAddOption = () => {
    if (items?.length < 10) {
      setItems([
        ...items,
        {
          id: `${getRandomNumberAddon(items, 1, 200)}`,
          option: "",
          video: "",
          status: false,
        },
      ]);
    } else {
      ShowToastError(t("options_limit_toast"));
    }
  };
  // useEffect(() => {
  //   if (items.length <= 2) {
  //     setIsDeleteDisable(true);
  //   } else {
  //     setIsDeleteDisable(false);
  //   }
  // }, [items]);
  const onDeleteOption = (id) => {
    // if (items.length > 2) {
    setItems(items.filter((item) => item.id !== id));
    // }
  };

  const onUploadImageClick = (type, id) => {
    showGallery(type, id);
  };

  const onAddImageOption = () => {
    if (items?.length < 10) {
      setItems([
        ...items,
        {
          id: `${getRandomNumberAddon(items, 1, 200)}`,
          option: "",
          image: "",
          status: false,
        },
      ]);
    } else {
      ShowToastError(t("options_limit_toast"));
    }
  };

  const RoundBox = styled("div")({
    position: "absolute",
    right: "20px",
    top: "20px",
    width: "40px",
    height: "40px",
    background: "#fff",
    zIndex: "9",
    borderRadius: "4px",
    boxShadow: "0 10px 25px 0 rgba(0, 0, 0, 0.12)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  });

  return (
    <>
      {items.map((item) => {
        return (
          // eslint-disable-next-line react/jsx-key
          Object.prototype.hasOwnProperty.call(item, "image") ? (
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: "10px", marginBottom: "10px" }}>
              <Box sx={{ display: "flex", gap: "10px" }}>
                {item?.image?.published_images ? (
                  <Box
                    sx={{
                      width: "100%",
                      height: "192px",
                      // border: "solid 1px #ced3d9",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                    }}>
                    <img
                      alt='answer_image'
                      style={{
                        width: "inherit",
                        height: "inherit",
                        objectFit: "cover",
                        borderRadius: "inherit",
                        cursor: "pointer",
                      }}
                      src={formCroppedUrl(
                        AUTH_INFO.gcpUri,
                        AUTH_INFO.gcpBucketName,
                        item?.image?.published_images?.[2]?.folder_path,
                        "webp",
                      )}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: "0",
                        width: "inherit",
                        height: "inherit",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                        borderRadius: "inherit",
                      }}>
                      <Box sx={{ display: "flex" }}>
                        <Box
                          sx={{ cursor: "pointer" }}
                          onClick={() => onUploadImageClick("images", item.id)}>
                          <Box
                            sx={{
                              borderRadius: "50%",
                              width: "25px",
                              height: "25px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              margin: "auto",
                            }}>
                            <ReplayIcon sx={{ color: "#fff" }} />
                          </Box>
                        </Box>
                        <RoundBox onClick={() => changeCrop(item.id)}>
                          <ViewQuiltIcon
                            sx={{
                              cursor: "pointer",
                              fontSize: { md: "22px" },
                            }}
                          />
                        </RoundBox>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    onClick={() => onUploadImageClick("images", item.id)}
                    sx={{
                      width: "100%",
                      height: "192px",
                      border: "solid 1px #ced3d9",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      gap: "10px",
                      cursor: "pointer",
                      backgroundColor: "#EFF0F6",
                    }}>
                    <img src={UploadThumbnail} alt='Icon' />
                    <Typography
                      variant='h5medium'
                      color='textColorPrimary'
                      data-testid='chooseimage'>
                      {t("choose_your_image")}
                    </Typography>
                  </Box>
                )}
                <Box
                  sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                  onClick={() => onDeleteOption(item.id)}>
                  <img
                    src={DeleteIconList}
                    style={{
                      objectFit: "cover",
                      opacity: isDeleteDisable ? "0.4" : "1",
                    }}
                    alt='Delete Icon'
                  />
                </Box>
              </Box>
              <Box>
                <Box>
                  <TextField
                    error={item.option?.length > 100}
                    variant='filled'
                    size='small'
                    name={item.id}
                    className='titlefield '
                    placeholder='Title'
                    value={item?.option}
                    inputProps={{ maxLength: 100 }}
                    sx={{
                      width: "94%",
                    }}
                    onChange={(e) => handleChange(e)}
                  />
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: "10px", marginBottom: "10px" }}>
              <Box sx={{ display: "flex", gap: "10px" }}>
                {item?.video?.Url ? (
                  <Box
                    sx={{
                      width: "100%",
                      height: "192px",
                      // border: "solid 1px #ced3d9",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                    }}>
                    <video
                      style={{
                        width: "100%",
                        height: "192px",
                        objectFit: "cover",
                        display: "flex",
                      }}
                      src={item?.video?.Url}
                      poster={relativeImageURL(item?.video?.Thumbnail)}
                      controls></video>
                    <Box
                      sx={{
                        position: "absolute",
                        top: "0",
                        width: "inherit",
                        height: "inherit",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                        borderRadius: "inherit",
                      }}>
                      <Box sx={{ display: "flex" }}>
                        <Box sx={{ cursor: "pointer" }} onClick={() => onUploadClick(item.id)}>
                          <Box
                            sx={{
                              borderRadius: "50%",
                              width: "25px",
                              height: "25px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              margin: "auto",
                            }}>
                            <ReplayIcon sx={{ color: "#fff" }} />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    onClick={() => onUploadClick(item.id)}
                    sx={{
                      width: "100%",
                      height: "192px",
                      border: "solid 1px #ced3d9",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      gap: "10px",
                      cursor: "pointer",
                      backgroundColor: "#EFF0F6",
                    }}>
                    <img src={UploadThumbnail} alt='Icon' />
                    <Typography
                      variant='h5medium'
                      color='textColorPrimary'
                      data-testid='choosevideo'>
                      Choose your video
                    </Typography>
                  </Box>
                )}
                <Box
                  sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                  onClick={() => onDeleteOption(item.id)}>
                  <img
                    src={DeleteIconList}
                    style={{
                      objectFit: "cover",
                      opacity: isDeleteDisable ? "0.4" : "1",
                    }}
                    alt='Delete Icon'
                  />
                </Box>
              </Box>
              <Box>
                <Box>
                  <TextField
                    error={item.option?.length > 100}
                    variant='filled'
                    size='small'
                    name={item.id}
                    className='titlefield '
                    placeholder='Title'
                    value={item?.option}
                    inputProps={{ maxLength: 100 }}
                    sx={{
                      width: "94%",
                    }}
                    onChange={(e) => handleChange(e)}
                  />
                </Box>
              </Box>
            </Box>
          )
        );
      })}
      <Box>
        <Box
          sx={{
            display: "inline-flex",
            alignItems: "center",
            cursor: "pointer",
            margin: { sm: "10px 10px 10px 0px", xs: "10px 10px 10px -4px" },
            "&:hover": {
              color: "#4B9EF9",
            },
          }}
          onClick={onAddOption}>
          <AddIcon />
          <Typography
            variant='h7regular'
            sx={{
              color: "#2d2d39",
              pl: "8px",
              textTransform: "capitalize",
              "&:hover": {
                color: "#4B9EF9",
              },
            }}>
            Add Video
          </Typography>
        </Box>
        <Box
          sx={{
            display: "inline-flex",
            alignItems: "center",
            cursor: "pointer",
            margin: { sm: "10px 10px 10px 0px", xs: "10px 10px 10px -4px" },
            "&:hover": {
              color: "#4B9EF9",
            },
          }}
          onClick={onAddImageOption}>
          <AddIcon />
          <Typography
            variant='h7regular'
            sx={{
              color: "#2d2d39",
              pl: "8px",
              textTransform: "capitalize",
              "&:hover": {
                color: "#4B9EF9",
              },
            }}>
            Add Image
          </Typography>
        </Box>
      </Box>
    </>
  );
};
