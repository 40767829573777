import { Container, Grid } from "@mui/material";
import { TitleSubTitle } from "@platformx/utilities";
import builder from "./builder";

const DynamicSectionComponent = ({
  fields,
  state,
  setState,
  errors,
  register,
  clearErrors,
  isEdit,
  control,
  title,
}: any) => {
  return (
    <Container>
      <Grid container spacing={5} rowSpacing={2}>
        {fields?.map((field) => (
          <>
            {field?.type === "tag" ? (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className='textFiled'
                id={`${field?.name}_id`}>
                {builder(
                  field,
                  state,
                  errors,
                  register,
                  clearErrors,
                  setState,
                  fields,
                  isEdit,
                  title,
                  control,
                  null,
                )}
              </Grid>
            ) : (
              <>
                <Grid item xs={12} sm={5} md={5} lg={5} className='leftFiled'>
                  <TitleSubTitle
                    titleVariant='h6medium'
                    subTitleVariant='p4regular'
                    title={
                      field?.validations?.[0]?.type === "required"
                        ? `${field?.title}*`
                        : field?.title
                    }
                    subTitle={field?.description}
                  />
                </Grid>
                <Grid item xs={12} sm={7} md={7} lg={7} className='textFiled'>
                  {builder(
                    field,
                    state,
                    errors,
                    register,
                    clearErrors,
                    setState,
                    fields,
                    isEdit,
                    title,
                    control,
                    null,
                  )}
                </Grid>
              </>
            )}
          </>
        ))}
      </Grid>
    </Container>
  );
};

export default DynamicSectionComponent;
