import Cached from "@mui/icons-material/Cached";
import { Box, Container, Paper, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import Slider from "react-slick";
import "../../Style.css";
import { usePrelemImpression } from "../../components/ImpressionHooks/PrelemImpressionHook";
import prelemTypes from "../../globalStyle";
import { getPrelemType } from "../../utils/Themefunctions";
import "./MultiSlot2.css";
import { useCustomStyle } from "./MultiSlot2.style";
import MultiSlotCard2 from "./MultiSlotCard2";

const windowSettings = {
  arrows: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
};

const tabletSettings = {
  arrows: true,
  slidesToShow: 3,
  slidesToScroll: 3,
};

const mobileSettings = {
  arrows: false,
  slidesToShow: 1,
  variableWidth: true,
};

const MOBILE_WIDTH_THRESHOLD = 768;
const TABLE_WIDTH_THRESHOLD = 1024;

const MultiSlot2 = ({ content, analytics, authoringHelper, secondaryArgs }) => {
  const prelemType = content.selected_prelem_bg_color;
  const classes = useCustomStyle(getPrelemType(prelemType));
  const globalClasses = prelemTypes();
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const firstRender = useRef(true);

  usePrelemImpression(analytics, inView, secondaryArgs);

  const defaultStructureData = () => {
    let multiSlot2StructureData;
    try {
      multiSlot2StructureData = {
        "@context": "https://schema.org",
        "@type": "ItemList",
        name: content?.Title,
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            name: content?.Slots?.[0]?.Title,
          },
          {
            "@type": "ListItem",
            position: 2,
            name: content?.Slots?.[1]?.Title,
          },
          {
            "@type": "ListItem",
            position: 3,
            name: content?.Slots?.[2]?.Title,
          },
          {
            "@type": "ListItem",
            position: 4,
            name: content?.Slots?.[3]?.Title,
          },
        ],
      };
    } catch (e) {
      multiSlot2StructureData = {};
    }

    return multiSlot2StructureData;
  };

  const generateStructureData = () => {
    let multiSlot2StructureData;
    const tempSD = String(authoringHelper?.lastSavedStructuredData || "");

    if (firstRender.current) {
      const defaultSD = defaultStructureData();
      const stringifyStructureData = defaultSD && JSON.stringify(defaultSD);
      authoringHelper?.sendDefaultStructureDataForResetToAuthoringCB(stringifyStructureData || "");

      if (String(tempSD).length > 0) {
        multiSlot2StructureData = JSON.parse(tempSD);
      } else {
        multiSlot2StructureData = defaultStructureData();
      }
      firstRender.current = false;
    } else {
      multiSlot2StructureData = defaultStructureData();
    }
    return multiSlot2StructureData;
  };
  useEffect(() => {
    if (analytics?.isAuthoring && analytics?.isSeoEnabled) {
      const structureData = generateStructureData();
      const stringifyStructureData = structureData && JSON.stringify(structureData);
      authoringHelper?.sendStructureDataToAuthoringCB(stringifyStructureData || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content?.Title, content?.Slots]);
  const onAdd = (slotNumber: number) => {
    const { onToggleContentGallery } = secondaryArgs?.multiSlot || {};
    if (onToggleContentGallery) {
      onToggleContentGallery(undefined, undefined, slotNumber);
    }
  };

  // handle responsive when editing in aurthoring
  const [device, setDevice] = useState("desktop");
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < MOBILE_WIDTH_THRESHOLD) setDevice("mobile");
      else if (window.innerWidth <= TABLE_WIDTH_THRESHOLD) setDevice("tablet");
      else setDevice("desktop");
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const sliderSettings =
    secondaryArgs?.prelemBaseEndpoint?.device === "tablet" || device === "tablet"
      ? { ...tabletSettings }
      : secondaryArgs?.prelemBaseEndpoint?.device === "mobile" || device === "mobile"
        ? { ...mobileSettings }
        : { ...windowSettings };
  return (
    <div
      ref={authoringHelper?.innerRef}
      className={`${classes.multiSlot2Wrapper} ${globalClasses.prelemType3} prelem prelemType3 multiSlot2BG`}>
      <Container
        className={
          authoringHelper?.isEditPage ? "grid_full_width prelem-py" : "grid_container prelem-py"
        }>
        <Box ref={ref}>
          <Box className='multiSlotHeading'>
            <Typography
              variant='h2medium'
              id='Title'
              className='headingText'
              sx={{
                display: authoringHelper?.isEditing || content?.Title ? "block" : "none",
              }}>
              {content?.Title}
            </Typography>
          </Box>
          <Slider {...sliderSettings} dots={false} infinite={false}>
            {content?.Slots?.map((item: any, index: number) => {
              return (
                <Box key={`card-${item.Id}`} p={1}>
                  {Object.keys(item)?.length !== 0 && (
                    <Paper onClick={() => onAdd(index)} className='paperCard'>
                      <Box
                        className='paperCardInnerWrapper'
                        sx={{
                          display: secondaryArgs?.editState ? "flex" : "none",
                        }}>
                        <Box className='addCardsIcon'>
                          <Cached className='iconRefresh' />
                        </Box>
                      </Box>
                      <MultiSlotCard2
                        content={content?.Slots?.[index]}
                        secondaryArgs={secondaryArgs}
                        index={index}
                        analytics={analytics}
                      />
                    </Paper>
                  )}
                </Box>
              );
            })}
          </Slider>
        </Box>
      </Container>
    </div>
  );
};

export default MultiSlot2;
