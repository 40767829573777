import { useEffect, useState } from "react";
import XGalleryWithText from "./XGalleryWithText";

const GallerywithOptions = ({ setState, state, field }) => {
  const [items, setItems] = useState<any>(
    state[field?.name] ? state[field?.name] : [{ id: "1", option: "", video: "" }],
  );

  useEffect(() => {
    setState({ ...state, [field?.name]: items });
  }, [items]);

  const updateGallery = (videoObj, currentItem, type) => {
    let updatedItem = {};
    if (type === "video") {
      updatedItem = items.map((cItem) =>
        cItem.id === currentItem ? { ...cItem, video: videoObj } : cItem,
      );
    } else {
      updatedItem = items.map((cItem) =>
        cItem.id === currentItem ? { ...cItem, image: videoObj } : cItem,
      );
    }
    setItems(updatedItem);
  };

  // const updateImage = (updatedPartialObj, currentItem) => {
  //   const updatedItem = items.map((cItem) =>
  //     cItem.id === currentItem ? { ...cItem, image: updatedPartialObj } : cItem,
  //   );
  //   setItems(updatedItem);
  // };

  // return <XGalleryWithText callBack={updateVideo} items={items} setItems={setItems} />;
  return (
    <XGalleryWithText
      callBack={updateGallery}
      editData={{
        original_image: state[field?.name]?.original_image,
        published_images: state[field?.name]?.published_images,
      }}
      isCrop={true}
      name={field?.name}
      items={items}
      setItems={setItems}
    />
  );
};
export default GallerywithOptions;
