import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Box, Container, Grid, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import "../../Style.css";
import { usePrelemImpression } from "../../components/ImpressionHooks/PrelemImpressionHook";
import prelemTypes from "../../globalStyle";
import { getPrelemType } from "../../utils/Themefunctions";
import { useCustomStyle } from "./DynamicPrelem.style";
import DynamicPrelemCard from "./DynamicPrelemCard";
import DynamicPrelemCard2 from "./DynamicPrelemCard2";

const DynamicPrelem = ({ content, analytics, authoringHelper, secondaryArgs }) => {
  const prelemType = content.selected_prelem_bg_color;
  const classes = useCustomStyle(getPrelemType(prelemType));
  const globalClasses = prelemTypes();
  const getCardArr = (data: any) => {
    if (data?.length > 4) {
      return data.slice(0, 4);
    } else if (data?.length > 0) {
      return data;
    }
    return [];
  };
  const [cardArr, setCardArr] = useState(getCardArr(content?.Slots));
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  usePrelemImpression(analytics, inView, secondaryArgs);

  useEffect(() => {
    setCardArr(getCardArr(content?.Slots));
  }, [content?.Slots]);

  const getGridValues = (index: number) => {
    let sm = 4,
      em = 3;
    if (cardArr.length === 1) {
      sm = 12;
      em = 12;
    } else if (cardArr.length === 2) {
      sm = 6;
      em = 6;
    } else if (cardArr.length === 3) {
      if (index === 0) {
        em = 6;
        sm = 12;
      } else {
        em = 3;
        sm = 6;
      }
    } else if (cardArr.length === 4) {
      sm = 6;
      em = 3;
    }
    return { sm, em };
  };
  return (
    <div
      ref={authoringHelper?.innerRef}
      className={`${classes.dynamicPrelemWrapper} ${globalClasses.prelemType1} prelem prelemType1 dynamicPrelemBg`}>
      <Container
        className={
          authoringHelper?.isEditPage
            ? "grid_full_width prelem-py"
            : "grid_container grid_container_nopadding prelem-py"
        }>
        <Box ref={ref} className='gridCard'>
          <Box className='topContent'>
            <Typography
              variant='h2semibold'
              className='headingText'
              id='Title'
              sx={{
                display: authoringHelper?.isEditing || content?.Title ? "block" : "none",
              }}>
              {content?.Title}
            </Typography>
            <Typography
              variant='p3regular'
              className='descriptionText'
              id='Description'
              sx={{
                display: authoringHelper?.isEditing || content?.Description ? "block" : "none",
              }}>
              {content?.Description}
            </Typography>
          </Box>
          <Grid
            container
            sx={{
              position: "relative",
              "&:hover": {
                ".add-content-overlay": {
                  display: authoringHelper?.authoringHoverShow ? "flex !important" : "none",
                },
              },
            }}>
            {cardArr?.length > 0 ? (
              cardArr.map((item: any, index: any) => {
                const { sm, em } = getGridValues(index);
                return (
                  <Grid
                    key={`${item?.Title}_${index.toString()}`}
                    item
                    xs={12}
                    sm={sm}
                    em={em}
                    rowSpacing={1}
                    p={1}>
                    {cardArr && Object.keys(item).length !== 0 && (
                      <Fragment>
                        <Box className='CardBoxWp' sx={{ display: { xs: "block", md: "none" } }}>
                          <DynamicPrelemCard
                            content={cardArr[index]}
                            secondaryArgs={secondaryArgs}
                            analytics={analytics}
                            cardIndex={index}
                          />
                        </Box>
                        <Box
                          className='CardBoxWp'
                          sx={{ display: { xs: "none", md: "block", em: "none" } }}>
                          <DynamicPrelemCard
                            content={cardArr[index]}
                            secondaryArgs={secondaryArgs}
                            analytics={analytics}
                            cardIndex={index}
                          />
                        </Box>
                        <Box
                          className='CardBoxWp'
                          sx={{
                            display: { xs: "none", md: "none", em: "block" },
                          }}>
                          {em === 12 || em === 6 ? (
                            <DynamicPrelemCard2
                              content={cardArr[index]}
                              secondaryArgs={secondaryArgs}
                              analytics={analytics}
                              cardIndex={index}
                            />
                          ) : (
                            <DynamicPrelemCard
                              content={cardArr[index]}
                              secondaryArgs={secondaryArgs}
                              analytics={analytics}
                              cardIndex={index}
                            />
                          )}
                        </Box>
                      </Fragment>
                    )}
                  </Grid>
                );
              })
            ) : (
              <React.Fragment>
                <Box className='noDataFoundWrapper'>
                  <img
                    src='https://platx-dspace-dev.fanuep.com/server/api/core/bitstreams/3b8398a0-299a-4b4e-ad6f-2a5bbb306e9a/content'
                    alt='NoDataFound'
                  />
                </Box>
                <Typography variant='h2' className='noDataFound'>
                  No data found
                </Typography>
              </React.Fragment>
            )}
            <Box className='add-content-overlay'>
              <Box
                sx={{ cursor: "pointer" }}
                onClick={() => secondaryArgs?.multiSlot?.onToggleContentGallery()}>
                <AutorenewIcon className='autorenewIcon' />
                <Typography className='overLaytextgap' variant='h3regular' color='textColor'>
                  Replace
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default DynamicPrelem;
