import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Box, CardMedia, Container, Grid, Typography } from "@mui/material";
import { PlayIcon, ProgressiveLoader } from "@platformx/shared/static-assets";
import { formCroppedUrlString } from "@platformx/utilities";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import "../../Style.css";
import { usePrelemImpression } from "../../components/ImpressionHooks/PrelemImpressionHook";
import { SkeletonLoader } from "../../components/SkeletonLoader/SkeletonLoader";
import VideoPlayer from "../../components/VideoPlayers/VideoPlayer";
import prelemTypes from "../../globalStyle";
import useContentData from "../../utils/DynamicSchema/useContentData";
import { getPrelemType } from "../../utils/Themefunctions";
import { getGalleryItems } from "../../utils/helper";
import { useCustomStyle } from "./ImageVideoCarousel1New.style";

const ImageVideoCarousel1New = ({ content, analytics, authoringHelper, secondaryArgs }) => {
  const prelemType = content.selected_prelem_bg_color;
  const classes = useCustomStyle(getPrelemType(prelemType));
  const globalClasses = prelemTypes();
  const { bucketName, gcpUrl } = secondaryArgs;
  const [cardArr, setCardArr] = useState(content?.Slots);

  const { contentValue, loader } = useContentData(content, secondaryArgs);

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const [loaded, setIsLoaded] = useState(false);
  usePrelemImpression(analytics, inView, secondaryArgs);

  const [active, setActive] = useState<number>(0);
  const [playVideo, setPlayVideo] = useState(false);

  const playVodEnable = () => {
    if (!authoringHelper?.isEditing) setPlayVideo(true);
  };

  const handleChange = (panel: number) => {
    if (
      (cardArr?.[active]?.Thumbnail &&
        cardArr?.[panel]?.Thumbnail &&
        (playVideo || cardArr?.[active]?.Thumbnail !== cardArr?.[panel]?.Thumbnail)) ||
      (!(cardArr?.[active]?.Thumbnail && cardArr?.[panel]?.Thumbnail) &&
        cardArr?.[active]?.Url !== cardArr?.[panel]?.Url)
    ) {
      setIsLoaded(false);
    }
    setActive(panel);
    setPlayVideo(false);
  };

  useEffect(() => {
    if (!loader) {
      const gallery = getGalleryItems(contentValue);
      setCardArr(gallery);
    }
  }, [loader]);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  return (
    <div
      ref={authoringHelper?.innerRef}
      className={`${classes.imageVideoCarousel1Wrapper} ${globalClasses.prelemType1} prelem prelemType1 image-video-carousel1 imageVideoCarousel1Bg`}>
      <Container
        className={
          authoringHelper?.isEditPage ? "grid_full_width prelem-py" : "grid_container prelem-py"
        }
        ref={ref}>
        <Typography
          variant='h2medium'
          className='centerText headingText'
          id='title'
          component='h2'
          sx={{
            display: authoringHelper?.isEditing || content?.title ? "block" : "none",
          }}>
          {content?.title}
        </Typography>
        <Typography
          variant='p3regular'
          component='p'
          className='centerText descriptionText'
          id='sub_title'
          sx={{
            display: authoringHelper?.isEditing || content?.sub_title ? "block" : "none",
          }}>
          {content?.sub_title}
        </Typography>
        <Grid className='gridWrapper' container>
          <Grid item xs={12} em={3} className='leftSideBarWrapper'>
            {cardArr?.length > 0 &&
              cardArr.slice(0, 6).map((item: any, index: number) => (
                <Typography
                  key={index}
                  variant={active === index ? "p2bold" : "p2regular"}
                  component='h3'
                  onClick={() => {
                    if (active !== index) {
                      return handleChange(index);
                    }
                    return;
                  }}
                  className={`headingText TitleHead leftsideBar afterHeading  ${
                    active === index ? "active" : ""
                  }`}>
                  {item?.option}
                </Typography>
              ))}
          </Grid>
          <Grid
            item
            em={9}
            xs={12}
            sx={{
              pl: { em: "30px" },
              pr: { md: "0px", em: "0px" },
            }}
            className='rightimagevideo'>
            {/* Right Side */}
            {cardArr?.[active]?.video?.Thumbnail ? (
              playVideo ? (
                <Box className='thumbnail'>
                  <VideoPlayer
                    playerProp={{
                      posterImg: "",
                      videoUrl: cardArr?.[active]?.video?.Url,
                      classname: "react-player-wrap",
                    }}
                  />
                </Box>
              ) : (
                <Box className='imgWrapper'>
                  <img
                    alt='carousel1'
                    src={
                      formCroppedUrlString(
                        gcpUrl,
                        bucketName,
                        cardArr?.[active]?.video?.Thumbnail,
                        "",
                        "vod",
                      ).src
                    }
                    onLoad={handleImageLoad}
                    className='imgProp'
                    style={{
                      display: loaded ? "block" : "none",
                    }}
                  />
                  {loaded && (
                    <Box onClick={playVodEnable} className='videoEnable'>
                      <Box className='iconWrapper'>
                        <img alt='play' src={PlayIcon} onClick={playVodEnable} />
                      </Box>
                    </Box>
                  )}
                  {!loaded ? (
                    <Box className='imageWrapper1'>
                      <img src={ProgressiveLoader} alt='Loading...' height='200' width='400' />
                    </Box>
                  ) : null}
                </Box>
              )
            ) : (
              <>
                <CardMedia
                  component='img'
                  image={
                    formCroppedUrlString(
                      gcpUrl,
                      bucketName,
                      cardArr?.[active]?.image?.published_images?.[0].folder_path,
                      "webp",
                    ).src
                  }
                  alt={`Image${active}`}
                  onLoad={handleImageLoad}
                  className='imgProp2'
                  sx={{
                    display: loaded ? "block" : "none",
                  }}
                />
                {!loaded && (
                  <Box className='imgWrapper2'>
                    <SkeletonLoader />
                  </Box>
                )}
              </>
            )}
          </Grid>
          <Box className={authoringHelper?.isEditing ? "overlay" : "hideElementClass"}>
            <Box
              className='pointer'
              onClick={() => secondaryArgs?.multiSlot?.onToggleContentGallery("gallery", true)}>
              <AutorenewIcon className='autorenewIcon' />
              <Typography className='overLaytextgap' variant='h3regular' color='textColor'>
                Replace
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Container>
    </div>
  );
};

export default ImageVideoCarousel1New;
