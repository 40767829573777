import { Box, Card, CardActions, CardContent, CardMedia, Typography } from "@mui/material";
import { PlatFormXDateTimeFormat, getImage, handleHtmlTags } from "@platformx/utilities";
import { useState } from "react";
import { useClickImpression } from "../../components/ImpressionHooks/ClickImpressionHook";
import { getIcon } from "../../components/Utils/helperFns";
import { onClickCard } from "../../utils/helper";
import ImageVideoGalleryModalSliderNew from "../ImageVideoGalleryModalSlider/ImageVideoGalleryModalSliderNew";

const BlogTilesCard = ({ content, secondaryArgs, analytics, cardIndex }) => {
  const [modalStatus, setModalStatus] = useState(false);
  const { triggerClickAnalytics } = useClickImpression();
  const styles = `
    .doublebr {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
     }
     .onelineelsp {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
     }`;

  const toggleModalStatus = () => {
    if (!secondaryArgs?.editState) setModalStatus(!modalStatus);
  };

  const formedUrl = getImage(content, secondaryArgs);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { color, imageUrl } = formedUrl;
  return (
    <>
      <style>{styles}</style>
      <Box
        sx={{
          "&:hover": {
            ".button-name": {
              display: secondaryArgs?.editState ? "none" : "block",
            },
          },
        }}
        className='overlay-wrapper blogTilesCardWrapper'>
        <Card
          className='blogTilesCard'
          onClick={(e) =>
            onClickCard(
              e,
              content?.EditorialItemPath,
              content,
              secondaryArgs,
              cardIndex,
              analytics,
              triggerClickAnalytics,
              toggleModalStatus,
            )
          }>
          <Box sx={{ display: "inline-block", position: "relative" }}>
            <CardMedia
              className='imgheight blogTilesCardMedia'
              sx={{
                height: {
                  xs: "204px",
                  sm: "382px",
                  md: "245px",
                  em: "310px",
                  // lg: "411px",
                },
                backgroundColor: color ? color : "",
              }}
              image={imageUrl ? imageUrl : ""}>
              <Box className='cardOverlay'></Box>
              <Box className='contentIcons'>
                <img
                  alt='BlogTilesCardimg'
                  src={getIcon(
                    content?.ContentType,
                    content?.Content_icon,
                    secondaryArgs?.gcpUrl,
                    secondaryArgs?.bucketName,
                  )}
                  style={{ filter: "brightness(100)" }}
                  className='fullwidth'
                  width={25}
                  height={25}
                />
              </Box>
            </CardMedia>
          </Box>
          <CardContent className='blogTilesCardContent'>
            <Typography gutterBottom variant='h3bold' className='doublebr title' component='h3'>
              {content.Title}
            </Typography>
            <CardActions className='actions'>
              <Box className='Boxdivcontent centerItem'>
                <Typography
                  gutterBottom
                  variant='p4bold'
                  component='div'
                  className='publishedBy marginZero'>
                  <Box className='dotpoint'>
                    {content?.Author?.trim() || content?.PublishedBy || content?.LastModifiedBy}.
                  </Box>
                </Typography>
                <Typography variant='p4regular' className='publishedBy gap'>
                  {PlatFormXDateTimeFormat(content?.PublishedDate || content?.lastModifiedDate)}
                </Typography>
              </Box>
            </CardActions>
            <Typography variant='p3regular' className='doublebr marginZero'>
              {handleHtmlTags(content.Description)}
            </Typography>
          </CardContent>
        </Card>
      </Box>
      {modalStatus && !secondaryArgs?.editState && (
        <ImageVideoGalleryModalSliderNew
          openModal={modalStatus}
          handleClose={toggleModalStatus}
          content={content}
          secondaryArgs={secondaryArgs}
        />
      )}
    </>
  );
};

export default BlogTilesCard;
