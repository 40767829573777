import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = (selectedType) => {
  const theme = useTheme();
  return makeStyles(() => {
    return {
      featureTilesWrapper: {
        "&.featureTilesBg": {
          background: theme.palette?.[selectedType]?.BACKGROUND,
          "& .labelText": {
            color: theme.palette?.[selectedType]?.LABEL,
          },
          "& .headingText": {
            color: theme.palette?.[selectedType]?.TITLE,
          },
          "& .descriptionText": {
            color: theme.palette?.[selectedType]?.PARAGRAPH,
          },
          "& .text-truncated-3line": {
            display: "-webkit-box",
            WebkitLineClamp: "3",
            textOverflow: "ellipsis",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            wordWrap: "break-word",
          },
          "& .text-truncated-1line": {
            display: "-webkit-box",
            WebkitLineClamp: "1",
            textOverflow: "ellipsis",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            wordWrap: "break-word",
          },
          "& .nohovereffect": {
            position: "absolute",
            bottom: 0,
            background: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%)`,
            zIndex: 2,
            "& .contentwp": {
              marginBottom: "0 !important",
            },
          },
          "& .hovereffect": {
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "end",
            overflow: "hidden",
            cursor: "pointer",
            transition: "all 0.5s ease",
            background: "linear-gradient(to bottom, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 1) 100%)",
            "& .contentwp": {
              transition: "all 0.5s ease",
              "& .text-truncated-1line, & .text-truncated-3line": {
                transition: "all 0.5s ease",
              },
              "& .text-truncated-3line": {
                minHeight: "66px",
                transition: "all 0.5s ease",
              },
            },
            "&:hover": {
              background: "rgba(0, 0, 0, 0.75)",
              transition: "all 0.5s ease",
              "& .contentwp": {
                marginBottom: "0 !important",
                transition: "all 0.5s ease",
                "& .text-truncated-1line": {
                  margin: "0px",
                },
                "& .text-truncated-3line": {
                  minHeight: "auto",
                },
              },
            },
          },
          "& .featureTilesBox": {
            "& .nopadding": {
              padding: "0 !important",
            },
            "& .heading": {
              textAlign: "center",
              marginBottom: theme.spacing(4),
            },
            "& .heightAuto": {
              height: "100%",
            },
            "& .tilesImageContainer": {
              display: "flex",
              [theme.breakpoints.up("xs")]: {
                padding: "5px",
              },
              [theme.breakpoints.up("md")]: {
                height: "auto",
                padding: "7px",
              },
              [theme.breakpoints.up("lg")]: {
                height: "50%",
                padding: "10px",
              },
            },
            "& .tilesImageInnerWrapper": {
              position: "relative",
              width: "100%",
              borderRadius: theme.borderRadius.value,
              overflow: "hidden",
              [theme.breakpoints.up("xs")]: {
                height: "270px",
              },
              [theme.breakpoints.up("md")]: {
                height: "320px",
              },
              [theme.breakpoints.up("em")]: {
                height: "365px",
              },
              [theme.breakpoints.up("lg")]: {
                height: "296px",
              },
            },
            "& .imageRender": {
              position: "relative",
              height: "100%",
              width: "100%",
              left: "0",
              top: "0",
              display: "flex",
            },
            "& .contentwp": {
              width: "100%",
              marginBottom: "-150px",
              [theme.breakpoints.up("xs")]: {
                padding: "15px",
              },
              [theme.breakpoints.up("md")]: {
                padding: "20px",
                marginBottom: "-155px",
              },
              [theme.breakpoints.up("lg")]: {
                marginBottom: "-155px",
              },
              [theme.breakpoints.up("xl")]: {
                marginBottom: "-145px",
              },
              "&.middleCard": {
                // [theme.breakpoints.up("lg")]: {
                //   marginBottom: "-130px",
                // },
              },
            },
            "& .text-truncated-3line": {
              [theme.breakpoints.up("xs")]: {
                margin: "8px 0 24px",
              },
              [theme.breakpoints.up("sm")]: {
                margin: "8px 0 16px",
              },
              minHeight: "54px",
            },
            "& .text-truncated-1line": {
              [theme.breakpoints.up("xs")]: {
                marginBottom: "15px",
              },
              [theme.breakpoints.up("sm")]: {
                marginBottom: "20px",
              },
              [theme.breakpoints.up("xl")]: {
                marginBottom: "10px",
              },
            },
          },
          "& .gridPadding": {
            [theme.breakpoints.up("xs")]: {
              padding: "5px",
            },
            [theme.breakpoints.up("md")]: {
              padding: "7px",
            },
            [theme.breakpoints.up("lg")]: {
              padding: "10px",
            },
          },
          /*middle Column */
          "& .tilesImageInnerWrapper1": {
            position: "relative",
            width: "100%",
            maxHeight: "365px",
            borderRadius: theme.borderRadius.value,
            overflow: "hidden",
            [theme.breakpoints.up("xs")]: {
              height: "270px",
            },
            [theme.breakpoints.up("md")]: {
              height: "320px",
            },
            [theme.breakpoints.up("em")]: {
              height: "365px",
            },
            [theme.breakpoints.up("lg")]: {
              height: "365px",
            },
          },
          /*last column */
          "& .columnWrapper": {
            position: "relative",
            width: "100%",
            borderRadius: theme.borderRadius.value,
            overflow: "hidden",
            [theme.breakpoints.up("xs")]: {
              height: "270px",
            },
            [theme.breakpoints.up("md")]: {
              height: "320px",
            },
            [theme.breakpoints.up("em")]: {
              height: "365px",
            },
            [theme.breakpoints.up("lg")]: {
              height: "auto",
            },
          },
          "& .secondColumnLastCard": {
            position: "relative",
            width: "100%",
            borderRadius: theme.borderRadius.value,
            overflow: "hidden",
            [theme.breakpoints.up("xs")]: {
              height: "270px",
            },
            [theme.breakpoints.up("md")]: {
              height: "320px",
            },
            [theme.breakpoints.up("em")]: {
              height: "365px",
            },
            [theme.breakpoints.up("lg")]: {
              height: "225.5px",
            },
          },
          "& .fullHeight": {
            height: "100%",
            display: "flex",
          },
        },
      },
    };
  })();
};
