import { useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    RewardsWrapper: {
      "&.rewardsWrapperSection": {
        width: "100%",
        "& .rewardsMenu": {
          "& button": {
            width: "auto",
            padding: "10px 15px",
            marginRight: "10px",
            border: "none",
            color: theme.palette.prelemType1.LABEL,
            "&.Mui-selected": {
              color: theme.palette.prelemType1.TITLE,
            },
            [theme.breakpoints.up("md")]: {
              minWidth: "80px",
            },
            [theme.breakpoints.up("lg")]: {
              minWidth: "auto",
            },
          },
          "& span.Platform-x-Tabs-indicator": {
            backgroundColor: theme?.palette?.prelemType1?.TITLE,
          },
          "& .Platform-x-Tabs-scrollButtonsHideMobile": {
            // width: "25px",
            display: "flex",
            "&.Mui-disabled": {
              display: "none",
            },
          },
        },
        "& .rewardsDetails": {
          marginTop: "16px",
          position: "relative",
          "& .tabPanel": {
            padding: 0,
          },
          "& .orderActionColumn": {
            [theme.breakpoints.between("sm", "md")]: {
              minWidth: "100%",
              display: "flex",
            },
            [theme.breakpoints.up("md")]: {
              width: "150px",
            },
          },
          "& .expiredRewards": {
            "& .rewardProduct": {
              opacity: 0.5,
            },
            "& .coupanDetailSection": {
              opacity: 0.5,
            },
            "&:hover": {
              border: `solid 1px ${theme.palette.prelemType1.LINE}`,
            },
          },
        },
        "& .rewardProduct": {
          "& img": {
            opacity: 0,
            animation: `fadeInAnimation 2s ease-in forwards`,
          },
        },
        "& .infinite-scroll-component": {
          float: "left",
          width: "100%",
        },
        "& .searchWrapper": {
          "& .Platform-x-InputBase-root.Platform-x-OutlinedInput-root input": {
            textAlign: "left",
            paddingLeft: "0px",
          },
          "& .Platform-x-InputBase-root.Platform-x-OutlinedInput-root": {
            border: `solid 2px ${theme.palette.prelemType1.BACKGROUND}`,
            height: "45px",
            paddingTop: "0px",
            marginTop: "3px",
          },
        },
      },
      "& .searchAndFilter": {
        alignItems: "center",
        "& .searchSection": {
          position: "relative",
          background: "#fff",
          marginRight: "2px",
          "& .searchWrapper": {
            position: "absolute",
            width: "280px",
            right: "42px",
            top: "-5px",
          },
        },
        "& .filterApplied": {
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          backgroundColor: theme.palette.prelemType1.LINK,
          top: "-5px",
          position: "absolute",
        },
        "& .filterSection": {
          position: "relative",
          background: "#fff",
          "&.active": {
            "& .filterOptions": {
              display: "block",
            },
          },
          "& .filterOptions": {
            background: theme.palette.prelemType1.BACKGROUND,
            border: `solid 1px ${theme.palette.prelemType1.LINE}`,
            display: "none",
            position: "absolute",
            top: "40px",
            right: "0px",
            width: "120px",
            zIndex: 1,
            borderRadius: "8px",
            padding: "0px 10px",
            minWidth: "150px",
          },
        },
      },
      "& .selectFilter": {
        "& .Platform-x-Select-select.Platform-x-Select-outlined.Platform-x-InputBase-input.Platform-x-OutlinedInput-input":
          {
            padding: "10px",
          },
      },
      "& .nodataOrError": {
        width: "100%",
        maxWidth: "360px",
        position: "relative",
        "& .title": {
          position: "absolute",
          left: "20px",
          right: "20px",
        },
        "& img": {
          width: "100%",
        },
      },
    },
  };
});
