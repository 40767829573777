import { Box, CardMedia, Typography } from "@mui/material";
import { getImage } from "@platformx/utilities";
import { useState } from "react";
import ImageVideoGalleryModalSliderNew from "../../../prelems/ImageVideoGalleryModalSlider/ImageVideoGalleryModalSliderNew";
import { onClickCard } from "../../../utils/helper";
import { useClickImpression } from "../../ImpressionHooks/ClickImpressionHook";
import { getIcon } from "../../Utils/helperFns";
import { useCustomStyle } from "./XCard5.style";

const XCard5 = ({ content, secondaryArgs, cardIndex = 0, analytics = {} }) => {
  const classes = useCustomStyle();
  const { triggerClickAnalytics } = useClickImpression();

  const formedUrl = getImage(content, secondaryArgs);
  const { color, imageUrl } = formedUrl;

  const [modalStatus, setModalStatus] = useState(false);

  const toggleModalStatus = () => {
    if (!secondaryArgs?.editState) setModalStatus(!modalStatus);
  };
  return (
    <>
      <Box
        className={`${classes.XCard5Wrapper} XCard5Box`}
        onClick={(e) =>
          onClickCard(
            e,
            content?.EditorialItemPath,
            content,
            secondaryArgs,
            cardIndex,
            analytics,
            triggerClickAnalytics,
            toggleModalStatus,
          )
        }>
        <Box className='XCard5innderBox'>
          <Box className='imgWrapper'>
            <CardMedia
              component={imageUrl ? "img" : "div"}
              image={imageUrl ? imageUrl : ""}
              title={imageUrl ? content?.Thumbnail?.AltText : "image"}
              sx={{ backgroundColor: color ? color : "", height: "100%", width: "100%" }}
            />
            <Box className='mainContentBox'>
              <Box className='contentWrapperCard5Box'>
                <Box className='iconWrapper'>
                  <img
                    src={getIcon(
                      content?.ContentType,
                      content?.Content_icon,
                      secondaryArgs?.gcpUrl,
                      secondaryArgs?.bucketName,
                    )}
                    alt='icon'
                    width={25}
                    height={25}
                  />
                </Box>
                <Box className='titleWrapper'>
                  <Typography variant='p1regular' color='textColor'>
                    {content?.Title}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {modalStatus && !secondaryArgs?.editState && (
        <ImageVideoGalleryModalSliderNew
          openModal={modalStatus}
          handleClose={toggleModalStatus}
          content={content}
          secondaryArgs={secondaryArgs}
        />
      )}
    </>
  );
};

export default XCard5;
