import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import {
  PlatFormXDateTimeFormat,
  getImage,
  handleHtmlTags,
  onClickCardUrlNavigate,
} from "@platformx/utilities";
import { useState } from "react";
import { useClickImpression } from "../../components/ImpressionHooks/ClickImpressionHook";
import { getIcon } from "../../components/Utils/helperFns";
import ImageVideoGalleryModalSliderNew from "../ImageVideoGalleryModalSlider/ImageVideoGalleryModalSliderNew";

const DynamicPrelemCard2 = ({ content, secondaryArgs, analytics, cardIndex }) => {
  const { triggerClickAnalytics } = useClickImpression();
  const [modalStatus, setModalStatus] = useState(false);
  const styles = `
    .doublebr {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
     }
     .oneline {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
       }
     .threeline {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
       }
     `;

  const toggleModalStatus = () => {
    if (!secondaryArgs?.editState) setModalStatus(!modalStatus);
  };

  const onClickCard = (e: any, id: string) => {
    if (secondaryArgs.editState) {
      e.preventDefault();
    } else {
      if (typeof window !== "undefined") {
        const url = onClickCardUrlNavigate(id, content, secondaryArgs);
        triggerClickAnalytics(
          url,
          cardIndex,
          analytics,
          secondaryArgs,
          content?.Title,
          content?.ContentType,
        );
        if (
          ["gallery", "videogallery", "imagegallery"].includes(content?.ContentType.toLowerCase())
        ) {
          toggleModalStatus();
        } else if (url) {
          window.open(url, "_self");
        }
      }
    }
  };

  const formedUrl = getImage(content, secondaryArgs);
  const { color, imageUrl } = formedUrl;

  return (
    <>
      <style>{styles}</style>
      <Box
        sx={{
          "&:hover": {
            ".button-name": {
              display: secondaryArgs?.editState ? "none" : "block",
            },
          },
        }}
        className='overlay-wrapper'>
        <Card
          className='cardContentBox'
          onClick={(e) => onClickCard(e, content?.EditorialItemPath)}>
          <Box
            className='imgBox'
            sx={{
              height: "100% !important",
            }}>
            <CardMedia
              sx={{
                height: "100%",
                backgroundColor: color ? color : "",
              }}
              image={imageUrl ? imageUrl : ""}
              component={imageUrl ? "img" : "div"}
            />
          </Box>
          <CardContent className='card2Content'>
            <Box className='card2ContentInnerWrapper'>
              <img
                alt='DynamicPrelemCard2'
                src={getIcon(
                  content?.ContentType,
                  content?.Content_icon,
                  secondaryArgs?.gcpUrl,
                  secondaryArgs?.bucketName,
                )}
                style={{ width: "100%", filter: "brightness(100)" }}
                width={25}
                height={25}
              />
            </Box>
            <Box>
              <Typography variant='h4semibold' className='cardTitle' color='textColor'>
                {content?.Title}
              </Typography>
              <Typography variant='p3regular' className='cardDescription' color='textColor'>
                {handleHtmlTags(content?.Description)}
              </Typography>
            </Box>
            <Box className='card2bottomButtonBox'>
              <Box className='adminDatdWrapper'>
                <Typography variant='p3semibold' color='textColor'>
                  {content?.Author?.trim() || content?.PublishedBy || content?.LastModifiedBy}
                </Typography>
                <Typography variant='p4regular' color='textColor'>
                  {PlatFormXDateTimeFormat(content?.PublishedDate || content?.lastModifiedDate)}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
      {modalStatus && !secondaryArgs?.editState && (
        <ImageVideoGalleryModalSliderNew
          openModal={modalStatus}
          handleClose={toggleModalStatus}
          content={content}
          secondaryArgs={secondaryArgs}
        />
      )}
    </>
  );
};

export default DynamicPrelemCard2;
