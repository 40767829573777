import { Box, Container, Typography } from "@mui/material";
import { DottedBg } from "@platformx/shared/static-assets";
import {
  Analytics,
  AuthoringHelper,
  SecondaryArgs,
  completeButtonUrl,
  formCroppedUrlString,
} from "@platformx/utilities";
import { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import "../../Style.css";
import BasicButton from "../../components/BasicButton/BasicButton";
import ImageRender from "../../components/ImageRender";
import { usePrelemImpression } from "../../components/ImpressionHooks/PrelemImpressionHook";
import TwoColumnLayout from "../../components/layouts/TwoColumns/TwoColumnLayout";
import prelemTypes from "../../globalStyle";
import { getButtonVariant, getPrelemType } from "../../utils/Themefunctions";
import { useCustomStyle } from "./WebsiteSummaryWithSubHeading2.style";

const WebsiteSummaryWithSubHeading2 = ({
  content,
  analytics,
  authoringHelper,
  secondaryArgs,
}: WebsiteSummaryWithSubHeading2Props) => {
  const prelemType = content.selected_prelem_bg_color;
  const classes = useCustomStyle(getPrelemType(prelemType));
  const globalClasses = prelemTypes();
  const refTitle = useRef<any>();

  const ButtonObj1 = {
    Button_Name: "Button1_Name",
    Button_RedirectURL: "Button1_RedirectURL",
    Button_Type: "Button1_Type",
    Button_Value: "Button1_Value",
    Button_Action: "Button1_Action",
    Button_Content: "Button1_Content",
  };
  const ButtonDataObj1 = {
    Button_Name: content?.Button1_Name,
    Button_RedirectURL: content?.Button1_RedirectURL,
    Button_Type: content?.Button1_Type,
    Button_Value: content?.Button1_Value,
    Button_Action: content?.Button1_Action,
    Button_Content: content?.Button1_Content,
  };
  const firstRender = useRef(true);
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const gridVal = {
    md: [12, 12],
    em: [6, 6],
  };

  const { original_image_relative_path, ext }: any =
    content?.ImageCompound?.ImageCompound_1?.original_image || {};
  const imgUrl = formCroppedUrlString(
    secondaryArgs?.gcpUrl,
    secondaryArgs?.bucketName,
    original_image_relative_path,
    ext,
  ).src;

  const defaultStructureData = () => {
    let WebsiteSummaryWithSubHeading2StructureData;
    try {
      WebsiteSummaryWithSubHeading2StructureData = {
        "@context": "https://schema.org/",
        "@type": "WebPage",
        name: content?.Title1,
        image: imgUrl,
        description: content?.Description,
        url: completeButtonUrl(
          content?.Button1_Action,
          content?.Button1_RedirectURL,
          secondaryArgs?.prelemBaseEndpoint?.buttonBaseUrl,
        ),
      };
    } catch (e) {
      WebsiteSummaryWithSubHeading2StructureData = {};
    }
    return WebsiteSummaryWithSubHeading2StructureData;
  };
  const genrateStructureData = () => {
    let WebsiteSummaryWithSubHeading2StructureData;
    const tempSD = String(authoringHelper?.lastSavedStructuredData);

    if (firstRender.current) {
      const defaultSD = defaultStructureData();
      const stringifyStructureData = defaultSD && JSON.stringify(defaultSD);
      authoringHelper?.sendDefaultStructureDataForResetToAuthoringCB(stringifyStructureData || "");

      if (String(tempSD).length > 0) {
        WebsiteSummaryWithSubHeading2StructureData = JSON.parse(tempSD);
      } else {
        WebsiteSummaryWithSubHeading2StructureData = defaultStructureData();
      }
      firstRender.current = false;
    } else {
      WebsiteSummaryWithSubHeading2StructureData = defaultStructureData();
    }
    return WebsiteSummaryWithSubHeading2StructureData;
  };

  useEffect(() => {
    if (analytics?.isAuthoring && analytics?.isSeoEnabled) {
      const structureData = genrateStructureData();
      const stringifyStructureData = structureData && JSON.stringify(structureData);
      authoringHelper?.sendStructureDataToAuthoringCB(stringifyStructureData || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    content?.Description,
    content?.ImageCompound,
    content?.Title1,
    content?.Title2,
    content?.Button1_Value,
    content?.Button1_RedirectURL,
  ]);

  usePrelemImpression(analytics, inView, secondaryArgs);
  const firstColumnContent = () => {
    return (
      <Box className='firstColumn'>
        <Box className='leftGridItem'>
          <Box className='innerHeading'>
            <Box className='title'>
              <Typography variant='labelbold' className='headingText' id='Title1' ref={refTitle}>
                {content?.Title1}
              </Typography>
            </Box>
            <Typography
              variant='h2medium'
              id='Title2'
              className='headingText'
              sx={{
                display: authoringHelper?.isEditing || content?.Title2 ? "block" : "none",
              }}>
              {content?.Title2}
            </Typography>
          </Box>
          <Typography
            variant='p3regular'
            id='Description'
            className='descriptionText'
            sx={{
              display: authoringHelper?.isEditing || content?.Description ? "block" : "none",
            }}>
            {content?.Description}
          </Typography>
          <BasicButton
            openButtonEditWindow={authoringHelper?.openButtonEditWindowInAuthoringCB}
            isAuthoring={analytics?.isAuthoring}
            currentBtnEditing={authoringHelper?.selectedButtonNameForEditing}
            variant={getButtonVariant(prelemType, "Button1")}
            analyticsEnabled={analytics?.isAnalyticsEnabled}
            ButtonObj={ButtonObj1}
            isEditing={authoringHelper?.isEditing}
            buttonDataObj={ButtonDataObj1}
            secondaryArgs={secondaryArgs}
            analytics={analytics}
            style={{
              display:
                authoringHelper?.isEditing ||
                (content?.Button1_Value && content?.Button1_Value !== "\n")
                  ? "inline-flex"
                  : "none",
            }}
          />
        </Box>
      </Box>
    );
  };
  const secondColumnContent = () => {
    return (
      <Box className='rightGridItem widthheight100'>
        <Box className='dottedBg'>
          <img alt='picture' src={DottedBg} width='437' height='225' className='rightImage1' />
        </Box>
        <Box className='rightImageWrapper'>
          <ImageRender
            originalImage={content?.ImageCompound?.ImageCompound_1?.original_image}
            publishedImages={content?.ImageCompound?.ImageCompound_1?.published_images}
            secondaryArgs={secondaryArgs}
            cropType={content?.cropType?.toLowerCase()}
            imgOrder={{
              1440: "card2",
              1280: "card2",
              1024: "card2",
              768: "card2",
              600: "card2",
              320: "card2",
            }}
            index={0}
            isEditing={authoringHelper?.isEditing}
          />
        </Box>
      </Box>
    );
  };

  return (
    <div
      ref={authoringHelper?.innerRef}
      className={`${classes.websiteSummaryWithSubHeading2Wrapper} ${globalClasses.prelemType1} prelem prelemType1 website-summary-with-subheading2 websiteSummaryWithSubHeading2Bg`}>
      <Container
        className={
          authoringHelper?.isEditPage ? "grid_full_width prelem-py" : "grid_container prelem-py"
        }
        ref={ref}>
        <TwoColumnLayout
          firstColumnContent={firstColumnContent()}
          secondColumnContent={secondColumnContent()}
          gridVal={gridVal}
          customClassName='websiteSummaryWithHeading2'
          noGap={true}
        />
      </Container>
    </div>
  );
};

interface WebsiteSummaryWithSubHeading2Props {
  content: Content;
  analytics: Analytics;
  authoringHelper?: AuthoringHelper;
  secondaryArgs: SecondaryArgs;
}

interface Content {
  Title1?: string;
  Title2?: string;
  Description?: string;
  Button1_Action?: string;
  Button1_Content?: string;
  Button1_Name?: string;
  Button1_RedirectURL?: string;
  Button1_RestEndPonit?: string;
  Button1_Type?: string;
  Button1_Value?: string;
  cropType: "single" | "multiple";
  aspectRatio?: any[];
  TagName?: string;
  ImageCompound: {
    ImageCompound_1: ImageCompound;
  };
  selected_prelem_bg_color: string;
}

interface ImageCompound {
  original_image: object;
  published_images: PublishedImages[];
}

interface PublishedImages {
  aspect_ratio: string;
  bucket_path: string;
  folder_path: string;
  visibility: string;
  ext: string;
}

export default WebsiteSummaryWithSubHeading2;
