import { getDisplayContentType } from "@platformx/prelems";
import {
  CommonPlateformXDialog,
  Loader,
  ShowToastError,
  ShowToastSuccess,
} from "@platformx/utilities";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom-v6";
import {
  calculateInitialValues,
  calculateInitialValuesWithData,
  createContentType,
  fetchSchema,
  fetchSchemaWithData,
  publishContentType,
  updateContentType,
} from "../utils/helperFns";
import { DynamicComponent } from "./DynamicComponent/DynamicComponent";

export const DynamicComponentContainer = () => {
  //Parameters
  const { contentType, documentId } = useParams();
  //state
  const [template, setTemplate] = useState<any>({});
  const [contentInstance, setContentInstance] = useState<any>({});

  const [showPublishConfirm, setShowPublishConfirm] = useState(false);
  // const [workflow, setWorkflow] = useState({});
  const [isDraft, setIsDraft] = useState<boolean>(true);
  const [pageState, setPageState] = useState("save");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);

  //hooks
  // const { userId, role } = GetLoginDetails();
  // const { getWorkflowDetails } = useWorkflow();
  const { t } = useTranslation();
  const navigate = useNavigate();
  //ref
  const savedData = useRef({ id: "", name: "" });

  //Publish Success Dialog Close Handle
  const handleCloseDialog = () => {
    setShowPublishConfirm(false);
  };

  //saveData
  const saveContent = async (status) => {
    setIsLoading(true);
    try {
      const detailsRes: any = await createContentType(
        contentInstance,
        contentType,
        template?.schema_document_type,
      );
      const { message, id, name } = detailsRes?.authoring_saveSchemaContent;
      if (message === "Successfully created!!!") {
        //ask statuscodes instead of this
        setIsLoading(false);
        setIsDraft(false);
        savedData.current = { id, name };
        if (status === "save") {
          ShowToastSuccess(`${contentType} ${t("created_toast")}`);
        } else {
          try {
            setIsLoading(true);
            const response: any = await publishContentType(
              savedData,
              contentType,
              template?.schema_document_type,
            );
            if (response?.authoring_publishSchemaContent?.message === "Published successfully!!!") {
              setIsLoading(false);
              setShowPublishConfirm(true);
            }
          } catch (err: any) {
            setIsLoading(false);
            ShowToastError(
              err.graphQLErrors.length > 0 ? err.graphQLErrors[0].message : t("api_error_toast"),
            );
          }
        }
      } else {
        ShowToastSuccess(detailsRes.authoring_createContent.message);
      }
    } catch (err: any) {
      setIsLoading(false);
      ShowToastError(t("api_error_toast"));
    }
  };

  //updateData
  const updateContent = async (status) => {
    setIsLoading(true);
    try {
      const detailsRes: any = await updateContentType(
        savedData,
        contentInstance,
        contentType,
        template?.schema_document_type,
      );
      const { message } = detailsRes?.authoring_updateSchemaContent;
      if (message === "Successfully updated!!!") {
        //ask statuscodes instead of this
        setIsLoading(false);
        setIsDraft(false);
        if (status === "update") {
          const displayName = getDisplayContentType(contentType);
          ShowToastSuccess(`${displayName} ${t("updated_toast")}`);
        } else {
          try {
            setIsLoading(true);
            const response: any = await publishContentType(
              savedData,
              contentType,
              template?.schema_document_type,
            );
            if (response?.authoring_publishSchemaContent?.message === "Published successfully!!!") {
              setIsLoading(false);
              setShowPublishConfirm(true);
            }
          } catch (err: any) {
            setIsLoading(false);
            ShowToastError(
              err.graphQLErrors.length > 0 ? err.graphQLErrors[0].message : t("api_error_toast"),
            );
          }
        }
      } else {
        ShowToastSuccess(detailsRes.authoring_createContent.message);
      }
    } catch (err: any) {
      setIsLoading(false);
      ShowToastError(t("api_error_toast"));
    }
  };

  //publish data
  const publishContent = async () => {
    setIsLoading(true);
    try {
      const response: any = await publishContentType(
        savedData,
        contentType,
        template?.schema_document_type,
      );
      if (response?.authoring_publishSchemaContent?.message === "Published successfully!!!") {
        setIsLoading(false);
        setShowPublishConfirm(true);
      }
    } catch (err: any) {
      setIsLoading(false);
      ShowToastError(
        err.graphQLErrors.length > 0 ? err.graphQLErrors[0].message : t("api_error_toast"),
      );
    }
  };

  const handlePublish = () => {
    const { id } = savedData.current;
    if (isDraft) {
      //if any changes available
      if (documentId || id) {
        //if already saved
        updateContent("publish");
      } else {
        saveContent("publish");
      }
    } else {
      publishContent();
    }
  };

  //OnSubmit React Hook Form (Both Save as Draft/ Publish)
  const handleSave = () => {
    const { id } = savedData.current;
    if (pageState === "save") {
      //if user click save as draft button
      if (documentId || id) {
        updateContent("update");
      } else {
        saveContent("save");
      }
    } else {
      //if user click publish button
      handlePublish();
    }
  };

  //GetSchema API
  const getSchema = async () => {
    try {
      // setIsLoading(true);
      setIsLoadingData(true);
      const detailsRes: any = await fetchSchema(contentType); //change useQuery check Charts
      const schema = detailsRes?.authoring_getSchema;
      setTemplate(schema);
    } catch (err: any) {
      ShowToastError(t("api_error_toast"));
    } finally {
      // setIsLoading(false);
      setIsLoadingData(false);
    }
  };

  //GetSchemaData API
  const getSchemaWithData = async (initialValues) => {
    try {
      // setIsLoading(true);
      setIsLoadingData(true);
      const detailsRes: any = await fetchSchemaWithData(
        contentType,
        documentId,
        template?.schema_document_type,
      );
      const schema = detailsRes?.authoring_getSchemaContent;
      const finalschema = calculateInitialValuesWithData(initialValues, schema);
      setContentInstance(finalschema);
      savedData.current = { id: schema?.document_id, name: schema?.name };
    } catch (err: any) {
      ShowToastError(t("api_error_toast"));
    } finally {
      // setIsLoading(false);
      setIsLoadingData(false);
    }
  };

  //get data and calculate initial values
  useEffect(() => {
    if (template?.fields?.length > 0) {
      const initialValues = calculateInitialValues(template?.fields);
      if (documentId) {
        //On Edit Flow
        getSchemaWithData(initialValues);
      } else {
        //On Create Flow
        setContentInstance({ ...initialValues });
      }
    }
  }, [template]);

  //get Schema Details
  useEffect(() => {
    if (contentType) {
      getSchema();
      //  getWorkflowDetails(role, userId, setWorkflow, capitalizeFirstLetter(contentType));
    }
  }, [contentType]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const listUrl = template?.schema_document_type === "collection" ? "collection" : "content";
  return (
    <>
      {isLoading && <Loader />}
      {isLoadingData ? (
        <Loader />
      ) : (
        <DynamicComponent
          contentType={contentType}
          template={template}
          handleSave={handleSave}
          contentInstance={contentInstance}
          setContentInstance={setContentInstance}
          isEdit={documentId ? true : false}
          setPageState={setPageState}
          listUrl={listUrl}
        />
      )}
      {showPublishConfirm ? (
        <CommonPlateformXDialog
          isDialogOpen={showPublishConfirm}
          title={t("congratulations")}
          subTitle={
            showPublishConfirm
              ? `${t("your")} ${contentType} ${t("publish_popup_message")}`
              : t("requested_action")
          }
          closeButtonHandle={handleCloseDialog}
          confirmButtonText={t("go_to_listing")}
          confirmButtonHandle={() => navigate(`/${listUrl}/${contentType}`)}
          modalType='publish'
        />
      ) : null}
    </>
  );
};
