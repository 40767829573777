/* eslint-disable wrap-regex */
import { Box, Container, Typography } from "@mui/material";
import {
  Analytics,
  AuthoringHelper,
  SecondaryArgs,
  formCroppedUrlString,
  handleHtmlTags,
  structureDataUrlPoint,
} from "@platformx/utilities";
import { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import "../../Style.css";
import BasicButton from "../../components/BasicButton/BasicButton";
import CommonDescription from "../../components/CommonDescription";
import ImageRender from "../../components/ImageRender";
import { usePrelemImpression } from "../../components/ImpressionHooks/PrelemImpressionHook";
import TwoColumnLayout from "../../components/layouts/TwoColumns/TwoColumnLayout";
import prelemTypes from "../../globalStyle";
import { getButtonVariant, getDescriptionColor, getPrelemType } from "../../utils/Themefunctions";
import { useCustomStyle } from "./ContactUs.style";

// ts-ignore
const ContactUs = ({
  content,
  analytics,
  authoringHelper,
  secondaryArgs,
}: //secondaryArgs,
ContactUsProp) => {
  const prelemType = content.selected_prelem_bg_color;
  const classes = useCustomStyle(getPrelemType(prelemType));
  const globalClasses = prelemTypes();
  const ButtonObj = {
    Button_Name: "Button1_Name",
    Button_RedirectURL: "Button1_RedirectURL",
    Button_Type: "Button1_Type",
    Button_Value: "Button1_Value",
    Button_Action: "Button1_Action",
    Button_Content: "Button1_Content",
  };

  const ButtonDataObj = {
    Button_Name: content?.Button1_Name,
    Button_RedirectURL: content?.Button1_RedirectURL,
    Button_Type: content?.Button1_Type,
    Button_Value: content?.Button1_Value,
    Button_Action: content?.Button1_Action,
    Button_Content: content?.Button1_Content,
  };

  const firstRender = useRef(true);

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const defaultStructureData = () => {
    let contactUsStructureData;
    const { original_image_relative_path, ext }: any =
      content?.ImageCompound?.ImageCompound_1?.original_image || {};
    const img = formCroppedUrlString(
      secondaryArgs?.gcpUrl,
      secondaryArgs?.bucketName,
      original_image_relative_path,
      ext,
    ).src;
    try {
      contactUsStructureData = {
        "@context": "https://schema.org/",
        "@type": "ContactPage",
        description: handleHtmlTags(content?.Description),
        image: img,
        name: content?.Title,
        url: structureDataUrlPoint(
          {
            Button_Action: content?.Button1_Action,
            Button_RedirectURL: content?.Button1_RedirectURL,
            Button_Content: content.Button1_Content,
          },
          secondaryArgs,
        ), // to be changed, modified during stablization
      };
    } catch (e) {
      contactUsStructureData = {};
    }

    return contactUsStructureData;
  };

  const generateStructureData = () => {
    let contactUsStructureData;
    const tempSD = String(authoringHelper?.lastSavedStructuredData);

    if (firstRender.current === true) {
      const defaultSD = defaultStructureData();
      const stringifyStructureData = defaultSD && JSON.stringify(defaultSD);
      authoringHelper?.sendDefaultStructureDataForResetToAuthoringCB(stringifyStructureData || "");

      if (String(tempSD).length > 0) {
        contactUsStructureData = JSON.parse(tempSD);
      } else {
        contactUsStructureData = defaultStructureData();
      }
      firstRender.current = false;
    } else {
      contactUsStructureData = defaultStructureData();
    }
    return contactUsStructureData;
  };

  useEffect(() => {
    if (analytics?.isAuthoring && analytics?.isSeoEnabled) {
      const structureData = generateStructureData();
      const stringifyStructureData = structureData && JSON.stringify(structureData);
      authoringHelper?.sendStructureDataToAuthoringCB(stringifyStructureData || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    content?.Description,
    content?.ImageCompound?.ImageCompound_1?.original_image,
    content?.Title,
    content?.Button1_RedirectURL,
  ]);

  usePrelemImpression(analytics, inView, secondaryArgs);

  /* AnalyticsEnabled dependency added as many times we are not getting analytics provider*async call)
  1. we are first checking in publish app if analytics provider is avaiable or not
  2. if its available we are setting AnalyticsEnabled to true
  3. if its not available we are setting false
*/
  const firstColumnContent = () => {
    return (
      <Box className='widthheight100'>
        <ImageRender
          originalImage={content?.ImageCompound?.ImageCompound_1?.original_image}
          publishedImages={content?.ImageCompound?.ImageCompound_1?.published_images}
          secondaryArgs={secondaryArgs}
          imgOrder={{
            1440: "card2",
            1280: "landscape",
            1024: "card2",
            768: "square",
            600: "card2",
            320: "card2",
          }}
          index={0}
          isEditing={authoringHelper?.isEditing}
        />
      </Box>
    );
  };
  const secondColumnContent = () => {
    return (
      <Box>
        <Typography
          variant='h2semibold'
          id={"Title"}
          className='headingText'
          sx={{
            display: authoringHelper?.isEditing || content?.Title ? "block" : "none",
          }}>
          {content.Title}
        </Typography>
        <CommonDescription
          color={getDescriptionColor(prelemType)}
          content={content}
          analytics={analytics}
          secondaryArgs={secondaryArgs}
        />
        <BasicButton
          openButtonEditWindow={authoringHelper?.openButtonEditWindowInAuthoringCB}
          isAuthoring={analytics?.isAuthoring}
          currentBtnEditing={authoringHelper?.selectedButtonNameForEditing}
          //buttonRef={authoringHelper.buttonRef}
          //buttonContentEditable={authoringHelper.buttonContentEditable}
          variant={getButtonVariant(prelemType, "Button1")}
          analyticsEnabled={analytics?.isAnalyticsEnabled}
          ButtonObj={ButtonObj}
          isEditing={authoringHelper?.isEditing}
          buttonDataObj={ButtonDataObj}
          secondaryArgs={secondaryArgs}
          analytics={analytics}
          style={{
            display:
              authoringHelper?.isEditing ||
              (content?.Button1_Value && content?.Button1_Value !== "\n")
                ? "inline-flex"
                : "none",
          }}
        />
      </Box>
    );
  };
  return (
    <div
      ref={authoringHelper?.innerRef}
      className={`${classes.contactUsWrapper} ${globalClasses.prelemType1} prelem prelemType1 contact-us contactUsBg`}>
      <Container
        className={
          authoringHelper?.isEditPage ? "grid_full_width prelem-py" : "grid_container prelem-py"
        }
        ref={ref}>
        <Box className='contactUs'>
          <TwoColumnLayout
            firstColumnContent={firstColumnContent()}
            secondColumnContent={secondColumnContent()}
            customClassName='contactus'
            col1Align='center'
            col2Align='center'
          />
        </Box>
      </Container>
    </div>
  );
};

interface ContactUsProp {
  content: Content;
  analytics: Analytics;
  authoringHelper?: AuthoringHelper;
  secondaryArgs: SecondaryArgs;
}

interface Content {
  Title?: string;
  SubTitle?: string;
  Description?: string;
  Button1_Action?: string;
  Button1_Content?: string;
  Button1_Name?: string;
  Button1_RedirectURL?: string;
  Button1_RestEndPonit?: string;
  Button1_Type?: string;
  Button1_Value?: string;
  selected_prelem_bg_color?: string;
  TagName?: string;
  ImageCompound: {
    ImageCompound_1: {
      published_images: Image[];
      original_image?: object;
    };
  };
}
interface Image {
  aspect_ratio: string;
  bucket_path: string;
  folder_path: string;
  visibility: string;
  ext: string;
}

export default ContactUs;
