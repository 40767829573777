import { XImageRenderWithText } from "@platformx/x-image-render";
import { useEffect, useState } from "react";

const ImageOptions = ({ setState, state, field }) => {
  const [items, setItems] = useState<any>(
    state[field?.name] ? state[field?.name] : [{ id: "1", option: "", image: "" }],
  );

  useEffect(() => {
    setState({ ...state, [field?.name]: items });
  }, [items]);

  const updateImage = (updatedPartialObj, currentItem) => {
    const updatedItem = items.map((cItem) =>
      cItem.id === currentItem ? { ...cItem, image: updatedPartialObj } : cItem,
    );
    setItems(updatedItem);
  };

  return (
    <XImageRenderWithText
      callBack={updateImage}
      editData={{
        original_image: state[field?.name]?.original_image,
        published_images: state[field?.name]?.published_images,
      }}
      isCrop={true}
      name={field?.name}
      items={items}
      setItems={setItems}
    />
  );
};
export default ImageOptions;
