import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = (selectedType) => {
  const theme = useTheme();
  return makeStyles(() => {
    return {
      videoBanner2Wrapper: {
        "&.videoBanner2WrapperBg": {
          background: theme.palette?.[selectedType]?.BACKGROUND,
          "& .labelText": {
            color: theme.palette?.[selectedType]?.LABEL,
          },
          "& .headingText": {
            color: theme.palette?.[selectedType]?.TITLE,
          },
          "& .descriptionText": {
            color: theme.palette?.[selectedType]?.PARAGRAPH,
          },
          "& .banner": {
            [theme.breakpoints.up("xs")]: {
              width: "237px",
              height: "237px",
            },
            [theme.breakpoints.up("md")]: {
              width: "350px",
              height: "350px",
            },
            [theme.breakpoints.up("lg")]: {
              width: "392px",
              height: "392px",
            },
            [theme.breakpoints.up("xl")]: {
              width: "446px",
              height: "446px",
            },
          },
          "& .silhouette": {
            background: theme.palette?.[selectedType]?.BACKGROUND,
          },
          "& .fullViewport": {
            display: "flex",
            position: "relative",
            flexDirection: "column",
            height: `calc(100vh - ${theme.palette.header.HEADER_HEIGHT})`,
          },
          "& .mouse-down": {
            color: theme.palette?.[selectedType]?.TITLE,
            "& svg": {
              color: theme.palette?.[selectedType]?.TITLE,
            },
            "&:before": {
              backgroundColor: theme.palette?.[selectedType]?.TITLE,
            },
          },
          "& .mask1": {
            WebkitMaskRepeat: "no-repeat",
            maskRepeat: "no-repeat",
          },
        },
      },
    };
  })();
};
