import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import { PlatFormXDateTimeFormat, getImage, handleHtmlTags } from "@platformx/utilities";
import { useState } from "react";
import { useClickImpression } from "../../components/ImpressionHooks/ClickImpressionHook";
import { getIcon } from "../../components/Utils/helperFns";
import { onClickCard } from "../../utils/helper";
import ImageVideoGalleryModalSliderNew from "../ImageVideoGalleryModalSlider/ImageVideoGalleryModalSliderNew";

const DynamicPrelemCard = ({ content, secondaryArgs, analytics, cardIndex }) => {
  const { triggerClickAnalytics } = useClickImpression();
  const [modalStatus, setModalStatus] = useState(false);

  const toggleModalStatus = () => {
    if (!secondaryArgs?.editState) setModalStatus(!modalStatus);
  };

  const formedUrl = getImage(content, secondaryArgs);
  const { color, imageUrl } = formedUrl;
  return (
    <>
      <Box
        sx={{
          "&:hover": {
            ".button-name": {
              display: secondaryArgs?.editState ? "none" : "block",
            },
          },
        }}
        className='overlay-wrapper'>
        <Card
          className='cardContentBox'
          onClick={(e) =>
            onClickCard(
              e,
              content?.EditorialItemPath,
              content,
              secondaryArgs,
              cardIndex,
              analytics,
              triggerClickAnalytics,
              toggleModalStatus,
            )
          }>
          <Box className='imgBox'>
            <CardMedia
              sx={{
                backgroundColor: color || "",
                height: "100%",
              }}
              image={imageUrl || ""}>
              <Box className='imgboxOverlay'></Box>
              <Box className='IconBox'>
                <img
                  alt='DynamicPrelemCardImg'
                  style={{ filter: "brightness(100)" }}
                  src={getIcon(
                    content?.ContentType,
                    content?.Content_icon,
                    secondaryArgs?.gcpUrl,
                    secondaryArgs?.bucketName,
                  )}
                  width={25}
                  height={25}
                />
              </Box>
            </CardMedia>
          </Box>

          <CardContent className='childCard'>
            <Typography variant='h4semibold' className='cardTitle'>
              {content.Title}
            </Typography>
            <Typography variant='p3regular' className='cardDescription'>
              {handleHtmlTags(content?.Description)}
            </Typography>
          </CardContent>
          <Box className='devider' />
          <Box className='BottomButtonBox childCard'>
            <Box className='adminDatdWrapper'>
              <Typography variant='p3regular' className='authorName'>
                {content?.Author?.trim() || content?.PublishedBy || content?.LastModifiedBy}
              </Typography>
              <Typography variant='p4regular'>
                {PlatFormXDateTimeFormat(content?.PublishedDate || content?.lastModifiedDate)}
              </Typography>
            </Box>
          </Box>
        </Card>
      </Box>
      {modalStatus && !secondaryArgs?.editState && (
        <ImageVideoGalleryModalSliderNew
          openModal={modalStatus}
          handleClose={toggleModalStatus}
          content={content}
          secondaryArgs={secondaryArgs}
        />
      )}
    </>
  );
};

export default DynamicPrelemCard;
