import { Box, Button, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { AIGif, Errorpopicon, SuccessIcon, WarningIcon } from "@platformx/shared/static-assets";
import FormatSubtitle from "./FormatSubtitle";
import { DialogList } from "./uitls/dialogTypes";

export default function CommonPlateformXDialog({
  disableConfirmButton = false,
  isDialogOpen,
  title,
  subTitle,
  closeButtonText,
  confirmButtonText,
  closeButtonHandle,
  confirmButtonHandle,
  crossButtonHandle,
  modalType,
  notification = "",
}: DialogList) {
  // const navigate = useNavigate();
  // const handleCopy = () => {
  //   navigator.clipboard.writeText(pageUrl !== undefined ? pageUrl : "");
  // };
  // const viewButtonHandle = () => {
  //   if (modalType === "publish") {
  //     window.open(pageUrl, "_blank");
  //   }
  //   if (modalType === "save") {
  //     navigate("/preview-page/desktop");
  //   }
  //   // if (modalType == 'draft') {
  //   //   navigate('/vod-preview');
  //   // }
  // };
  return (
    <div>
      <Dialog
        fullWidth
        open={isDialogOpen}
        onClose={modalType ? crossButtonHandle : closeButtonHandle}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        sx={{
          ".Platform-x-Paper-root": {
            padding: "25px !important",
          },
          ".Platform-x-Box-root": {
            margin: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          ".Platform-x-DialogContent-root": {
            overflowY: "hidden !important",
          },
          ".Platform-x-DialogActions-root": {
            margin: "20px 0 0 0 !important",
          },
          ".Platform-x-Dialog-paper": {
            maxWidth: { xs: "100%", sm: "600px", lg: "650px" },
            width: { xs: "100%", sm: "600px", lg: "650px" },
            maxHeight: { lg: "450px" },
            // height: { lg: "400px" },
            margin: { xs: "0px" },
            position: { xs: "absolute", md: "relative" },
            bottom: { xs: 0 },
            borderBottomLeftRadius: { xs: 0, md: 4 },
            borderBottomRightRadius: { xs: 0, md: 4 },
            "& .popupCloseIcon": {
              position: "absolute",
              right: "20px",
              top: "10px",
            },
          },
          textAlign: "center",
        }}>
        <Box
          sx={{
            textAlign: "center",
            color: "#fd0c0d",
            margin: { xs: 0, md: "71px 0 4px" },
            height: "120px",
          }}>
          <img
            src={
              modalType === "publish" || modalType === "draft"
                ? SuccessIcon
                : modalType === "delete"
                  ? Errorpopicon
                  : modalType === "generateContent"
                    ? AIGif
                    : WarningIcon
            }
            alt={
              modalType === "publish" || modalType === "draft"
                ? "Success Icon"
                : modalType === "delete"
                  ? "Errorpopicon"
                  : modalType === "generateContent"
                    ? "AI Icon"
                    : "Warning Icon"
            }
            style={{ height: "100px", width: "100px" }}
          />
        </Box>
        {title ? (
          <DialogTitle
            id='alert-dialog-title'
            variant='h3bold'
            sx={{
              textAlign: "center",
              padding: modalType ? "0px 0px" : "16px 24px",
              paddingTop: modalType === "unsavedWarning" ? "50px" : "0px",
              textTransform: "capitalize",
              paddingBottom: "24px",
            }}>
            {title}
          </DialogTitle>
        ) : (
          ""
        )}
        {subTitle ? (
          <DialogContent
            sx={{
              textAlign: "center",
              padding: "0px 20px",
              flex: "inherit",
            }}>
            <Typography
              variant='p2regular'
              sx={{
                "&:first-letter": {
                  textTransform: "capitalize",
                },
              }}>
              <FormatSubtitle text={subTitle}></FormatSubtitle>
            </Typography>
          </DialogContent>
        ) : (
          ""
        )}
        <Box sx={{ display: "flex" }}>
          {notification && (
            <Typography
              variant='p3semibold'
              color='headerParagraphColor'
              className='singlebr single-item'
              m={0}>
              <img
                src={WarningIcon}
                alt='warning'
                style={{ width: "25px", float: "left", marginRight: "10px" }}
              />
              {notification}
            </Typography>
          )}
        </Box>

        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "30px",
          }}>
          {closeButtonText ? (
            <Button
              variant='secondaryButton'
              sx={{
                marginRight: "12px",
                textTransform: "capitalize",
              }}
              onClick={closeButtonHandle}>
              {closeButtonText}
            </Button>
          ) : (
            ""
          )}
          {confirmButtonText ? (
            <Button
              type='button'
              disabled={disableConfirmButton}
              variant='primaryButton'
              onClick={confirmButtonHandle}
              autoFocus
              sx={{
                textTransform: "capitalize",
              }}>
              {confirmButtonText}
            </Button>
          ) : (
            ""
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
