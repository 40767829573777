import { Box } from "@mui/material";
import { DamContentGallery } from "@platformx/x-image-render";
import React, { useRef, useState } from "react";
import { Options } from "./Options";
// import "./x-image-render.css";

const XVideoWithText = ({ callBack, items, setItems }) => {
  const currentItem = useRef(0);
  // const [returnData, setReturnData] = useState(editData);
  const [galleryDialogOpen, setGalleryDialogOpen] = useState(false);

  const handleSelectedVideo = (video) => {
    callBack(video, currentItem.current);
    toggleGallery(false, "done");
  };

  const toggleGallery = (toggleState: boolean, type: string) => {
    setGalleryDialogOpen(toggleState);
    if (type === "cancel") {
      // setSelectedImage({
      //   title: "",
      //   Thumbnail: "",
      //   description: "",
      //   bitStreamId: "",
      // });
    }
  };

  const showGallery = (id) => {
    window.scrollTo(0, 0);
    currentItem.current = id;
    setGalleryDialogOpen(true);
  };

  // useEffect(() => {
  //   if (editData && JSON.stringify(editData) !== JSON.stringify(returnData)) {
  //     setReturnData(editData);
  //   }
  // }, [editData]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#FFF",
        }}>
        {galleryDialogOpen && (
          <DamContentGallery
            handleSelectedVideo={handleSelectedVideo}
            toggleGallery={toggleGallery}
            assetType={"Video"}
            dialogOpen={galleryDialogOpen}
          />
        )}
      </Box>
      <Options showGallery={showGallery} items={items} setItems={setItems} />
    </>
  );
};

export default React.memo(XVideoWithText);
