import { Box, Container, Typography } from "@mui/material";
import { useInView } from "react-intersection-observer";
import BasicButton from "../../components/BasicButton/BasicButton";
import ImageRender from "../../components/ImageRender";
import { usePrelemImpression } from "../../components/ImpressionHooks/PrelemImpressionHook";
import Slider from "../../components/Slider/Slider";
import prelemTypes from "../../globalStyle";
import { useCustomStyle } from "./ImageCarousel2.style";

const ImageCarousel2 = ({ content, analytics, authoringHelper, secondaryArgs }) => {
  const ButtonObj1 = {
    Button_Name: "Button1_Name",
    Button_RedirectURL: "Button1_RedirectURL",
    Button_Type: "Button1_Type",
    Button_Value: "Button1_Value",
    Button_Action: "Button1_Action",
    Button_Content: "Button1_Content",
  };
  const ButtonDataObj1 = {
    Button_Name: content?.Button1_Name,
    Button_RedirectURL: content?.Button1_RedirectURL,
    Button_Type: content?.Button1_Type,
    Button_Value: content?.Button1_Value,
    Button_Action: content?.Button1_Action,
    Button_Content: content?.Button1_Content,
  };
  const ButtonObj2 = {
    Button_Name: "Button2_Name",
    Button_RedirectURL: "Button2_RedirectURL",
    Button_Type: "Button2_Type",
    Button_Value: "Button2_Value",
    Button_Action: "Button2_Action",
    Button_Content: "Button2_Content",
  };
  const ButtonDataObj2 = {
    Button_Name: content?.Button2_Name,
    Button_RedirectURL: content?.Button2_RedirectURL,
    Button_Type: content?.Button2_Type,
    Button_Value: content?.Button2_Value,
    Button_Action: content?.Button2_Action,
    Button_Content: content?.Button2_Content,
  };
  const ButtonObj3 = {
    Button_Name: "Button3_Name",
    Button_RedirectURL: "Button3_RedirectURL",
    Button_Type: "Button3_Type",
    Button_Value: "Button3_Value",
    Button_Action: "Button3_Action",
    Button_Content: "Button3_Content",
  };
  const ButtonDataObj3 = {
    Button_Name: content?.Button3_Name,
    Button_RedirectURL: content?.Button3_RedirectURL,
    Button_Type: content?.Button3_Type,
    Button_Value: content?.Button3_Value,
    Button_Action: content?.Button3_Action,
    Button_Content: content?.Button3_Content,
  };
  const ButtonObj4 = {
    Button_Name: "Button4_Name",
    Button_RedirectURL: "Button4_RedirectURL",
    Button_Type: "Button4_Type",
    Button_Value: "Button4_Value",
    Button_Action: "Button4_Action",
    Button_Content: "Button4_Content",
  };
  const ButtonDataObj4 = {
    Button_Name: content?.Button4_Name,
    Button_RedirectURL: content?.Button4_RedirectURL,
    Button_Type: content?.Button4_Type,
    Button_Value: content?.Button4_Value,
    Button_Action: content?.Button4_Action,
    Button_Content: content?.Button4_Content,
  };
  const ButtonObj5 = {
    Button_Name: "Button5_Name",
    Button_RedirectURL: "Button5_RedirectURL",
    Button_Type: "Button5_Type",
    Button_Value: "Button5_Value",
    Button_Action: "Button5_Action",
    Button_Content: "Button5_Content",
  };
  const ButtonDataObj5 = {
    Button_Name: content?.Button5_Name,
    Button_RedirectURL: content?.Button5_RedirectURL,
    Button_Type: content?.Button5_Type,
    Button_Value: content?.Button5_Value,
    Button_Action: content?.Button5_Action,
    Button_Content: content?.Button5_Content,
  };

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const settings = {
    autoplay: false,
    showThumb: false,
    animationType: "slideIn",
  };

  usePrelemImpression(analytics, inView, secondaryArgs);
  const classes = useCustomStyle();
  const globalClasses = prelemTypes();

  // const getImg = (index: number) => {
  //   const { original_image_relative_path, ext }: any =
  //     (content?.img_gallery && content?.img_gallery[index]?.original_image) || {};
  //   const img = formCroppedUrl(
  //     secondaryArgs?.gcpUrl,
  //     secondaryArgs?.bucketName,
  //     original_image_relative_path,
  //     ext,
  //   );
  //   return img;
  // };

  // const thumbImg = Object.entries(content?.img_gallery).map((_, index: number) => {
  //   return getImg(index);
  // });

  return (
    <Box
      ref={authoringHelper?.innerRef}
      className={`${classes.imageCarouselWrapper} ${globalClasses.prelemType2} prelem prelemType2 imageCarousel`}>
      <Box ref={ref}>
        <Slider {...settings} sliderName='sliderContainer1'>
          {content?.img_gallery &&
            Object.entries(content?.img_gallery).map(([key, value], index) => (
              <Box key={index} className='imgCrouselWrapper'>
                {value ? (
                  <Box className='crouselWrapper'>
                    <ImageRender
                      originalImage={content?.img_gallery[index]?.original_image}
                      publishedImages={content?.img_gallery[index]?.published_images}
                      secondaryArgs={secondaryArgs}
                      imgOrder={{
                        1440: "hero",
                        1280: "hero",
                        1024: "card2",
                        768: "card2",
                        600: "card2",
                        320: "card2",
                      }}
                      index={index}
                      isEditing={authoringHelper?.isEditing}
                    />
                    <Box className='imageCrouselBox'>
                      <Container
                        className={
                          authoringHelper?.isEditPage ? "grid_full_width" : "grid_container"
                        }>
                        <Typography
                          variant='h2semibold'
                          color='textColor'
                          key={key}
                          className={authoringHelper?.isEditPage ? "typoText" : "typoText"}
                          id={`Title_${index + 1}`}>
                          {content?.[`Title_${index + 1}`]}
                        </Typography>
                        <Box id='button'>
                          <BasicButton
                            openButtonEditWindow={
                              authoringHelper?.openButtonEditWindowInAuthoringCB
                            }
                            isAuthoring={analytics?.isAuthoring}
                            currentBtnEditing={authoringHelper?.selectedButtonNameForEditing}
                            variant='defaultButton1'
                            analyticsEnabled={analytics?.isAnalyticsEnabled}
                            ButtonObj={
                              index === 0
                                ? ButtonObj1
                                : index === 1
                                  ? ButtonObj2
                                  : index === 2
                                    ? ButtonObj3
                                    : index === 3
                                      ? ButtonObj4
                                      : ButtonObj5
                            }
                            isEditing={authoringHelper?.isEditing}
                            buttonDataObj={
                              index === 0
                                ? ButtonDataObj1
                                : index === 1
                                  ? ButtonDataObj2
                                  : index === 2
                                    ? ButtonDataObj3
                                    : index === 3
                                      ? ButtonDataObj4
                                      : ButtonDataObj5
                            }
                            secondaryArgs={secondaryArgs}
                            analytics={analytics}
                          />
                        </Box>
                      </Container>
                    </Box>
                  </Box>
                ) : null}
              </Box>
            ))}
        </Slider>
      </Box>
    </Box>
  );
};

ImageCarousel2.defaultProps = {
  authoringHelper: {
    innerRef: null,
    sendStructureDataToAuthoringCB: () => {},
    sendDefaultStructureDataForResetToAuthoringCB: () => {},
    openButtonEditWindowInAuthoringCB: () => {},
    selectedButtonNameForEditing: "",
    isEditing: false,
    buttonRef: null,
    buttonContentEditable: false,
    lastSavedStructuredData: "",
    isEditPage: false,
  },

  analytics: {
    isSeoEnabled: false,
    isAuthoring: false,
    isAnalyticsEnabled: true,
    position: 0,
    pageId: 19,
    prelemId: 19,
    pageTitle: "Image Carousel 1",
    pageDesc:
      "This prelem can be used to create an image carousel of 5 images. All the image will have some text & CTA. Users can use it as the hero banner of the website.",
    pageTags: "Image Carousel, Images, Gallery, Hero Banner",
    prelemTags: "Image Carousel, Images, Gallery, Hero Banner",
  },
  secondaryArgs: {
    prelemBaseEndpoint: {
      APIEndPoint: "https://platx-api-dev.fanuep.com/platform-x/",
      device: "window",
      buttonBaseUrl: "https://platx-publish-dev.fanuep.com/",
    },
    editState: false,
    multiSlot: {},
    gcpUrl: "https://storage.googleapis.com",
    bucketName: "cropped_image_public",
  },
};

export default ImageCarousel2;
