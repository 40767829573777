import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = (selectedType) => {
  const theme = useTheme();
  return makeStyles(() => {
    return {
      dynamicPrelemWithCarousel2: {
        "&.dynamicPrelemWithCarousel2Wrapper": {
          background: theme.palette?.[selectedType]?.BACKGROUND,
          "& .labelText": {
            color: theme.palette?.[selectedType]?.LABEL,
          },
          "& .headingText": {
            color: theme.palette?.[selectedType]?.TITLE,
          },
          "& .descriptionText": {
            color: theme.palette?.[selectedType]?.PARAGRAPH,
          },
          "& .textWrapper": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          },
          "& .navigationWrapper": {
            display: "flex",
            minWidth: "68px",
            justifyContent: "space-between",
          },
          "& .noResultFound": {
            display: "flex",
            justifyContent: "center",
            width: "inherit",
            padding: "30px 0",
          },
          "& .add-content-overlay": {
            background: `rgba(${theme.palette.overlay["editOverlay"]})`,
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "0",
            left: "",
            display: "none",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "1",
          },
          "& .arrowIcons": {
            fill: theme.palette?.[selectedType]?.TITLE,
            cursor: "pointer",
            "&.arrowIconsDisabled": {
              opacity: 0.5,
            },
          },
        },
      },
    };
  })();
};
