import { Box, Container, Grid, Typography } from "@mui/material";
import {
  Analytics,
  AuthoringHelper,
  SecondaryArgs,
  completeButtonUrl,
  formCroppedUrlString,
} from "@platformx/utilities";
import { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import "../../Style.css";
import BasicButton from "../../components/BasicButton/BasicButton";
import ImageRender from "../../components/ImageRender";
import { usePrelemImpression } from "../../components/ImpressionHooks/PrelemImpressionHook";
import TwoColumnLayout from "../../components/layouts/TwoColumns/TwoColumnLayout";
import prelemTypes from "../../globalStyle";
import { getButtonVariant, getPrelemType } from "../../utils/Themefunctions";
import { useCustomStyle } from "./WebsiteSummaryWithSubHeading.style";

const WebsiteSummaryWithSubHeading = ({
  content,
  analytics,
  authoringHelper,
  secondaryArgs,
}: WebsiteSummaryWithSubHeadingProps) => {
  const prelemType = content.selected_prelem_bg_color;
  const classes = useCustomStyle(getPrelemType(prelemType));
  const globalClasses = prelemTypes();
  const refTitle = useRef<any>();

  const ButtonObj1 = {
    Button_Name: "Button1_Name",
    Button_RedirectURL: "Button1_RedirectURL",
    Button_Type: "Button1_Type",
    Button_Value: "Button1_Value",
    Button_Action: "Button1_Action",
    Button_Content: "Button1_Content",
  };
  const ButtonDataObj1 = {
    Button_Name: content?.Button1_Name,
    Button_RedirectURL: content?.Button1_RedirectURL,
    Button_Type: content?.Button1_Type,
    Button_Value: content?.Button1_Value,
    Button_Action: content?.Button1_Action,
    Button_Content: content?.Button1_Content,
  };
  const firstRender = useRef(true);
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const gridVal = {
    md: [12, 12],
    em: [6, 6],
  };

  const defaultStructureData = () => {
    let WebsiteSummaryWithSubHeadingStructureData;
    const { original_image_relative_path, ext }: any =
      content?.ImageCompound?.ImageCompound_1?.original_image || {};
    const img = formCroppedUrlString(
      secondaryArgs?.gcpUrl,
      secondaryArgs?.bucketName,
      original_image_relative_path,
      ext,
    ).src;

    try {
      WebsiteSummaryWithSubHeadingStructureData = {
        "@context": "https://schema.org/",
        "@type": "WebPage",
        name: content?.Title1,
        image: img,
        description: content?.Description,
        url: completeButtonUrl(
          content?.Button1_Action,
          content?.Button1_RedirectURL,
          secondaryArgs?.prelemBaseEndpoint?.buttonBaseUrl,
        ),
      };
    } catch (e) {
      WebsiteSummaryWithSubHeadingStructureData = {};
    }
    return WebsiteSummaryWithSubHeadingStructureData;
  };
  const genrateStructureData = () => {
    let WebsiteSummaryWithSubHeadingStructureData;
    const tempSD = String(authoringHelper?.lastSavedStructuredData);

    if (firstRender.current) {
      const defaultSD = defaultStructureData();
      const stringifyStructureData = defaultSD && JSON.stringify(defaultSD);
      authoringHelper?.sendDefaultStructureDataForResetToAuthoringCB(stringifyStructureData || "");

      if (String(tempSD).length > 0) {
        WebsiteSummaryWithSubHeadingStructureData = JSON.parse(tempSD);
      } else {
        WebsiteSummaryWithSubHeadingStructureData = defaultStructureData();
      }
      firstRender.current = false;
    } else {
      WebsiteSummaryWithSubHeadingStructureData = defaultStructureData();
    }
    return WebsiteSummaryWithSubHeadingStructureData;
  };

  useEffect(() => {
    if (analytics?.isAuthoring && analytics?.isSeoEnabled) {
      const structureData = genrateStructureData();
      const stringifyStructureData = structureData && JSON.stringify(structureData);
      authoringHelper?.sendStructureDataToAuthoringCB(stringifyStructureData || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    content?.Description,
    content?.ImageCompound?.ImageCompound_1?.original_image,
    content?.Title1,
    content?.Title2,
    content?.Button1_Value,
    content?.Button1_RedirectURL,
  ]);

  usePrelemImpression(analytics, inView, secondaryArgs);
  const firstColumnContent = () => {
    return (
      <Box className='imageWrapper widthheight100'>
        <ImageRender
          originalImage={content?.ImageCompound?.ImageCompound_1?.original_image}
          publishedImages={content?.ImageCompound?.ImageCompound_1?.published_images}
          secondaryArgs={secondaryArgs}
          cropType={content?.cropType?.toLowerCase()}
          imgOrder={{
            1440: "square",
            1280: "square",
            1024: "square",
            768: "square",
            600: "square",
            320: "square",
          }}
          index={0}
          isEditing={authoringHelper?.isEditing}
        />
      </Box>
    );
  };
  const secondColumnContent = () => {
    return (
      <Box className='secondColumnContentWrapper'>
        <Box className='headingWrapper'>
          <Box className='title'>
            <Typography variant='labelbold' id='Title1' ref={refTitle} className='labelText'>
              {content.Title1}
            </Typography>
          </Box>
          <Typography
            variant='h2medium'
            id='Title2'
            className='headingText'
            sx={{
              display: authoringHelper?.isEditing || content?.Title2 ? "block" : "none",
            }}>
            {content.Title2}
          </Typography>
        </Box>
        <Typography
          variant='p3regular'
          id='Description'
          className='descriptionText'
          sx={{
            display: authoringHelper?.isEditing || content?.Description ? "block" : "none",
          }}>
          {content.Description}
        </Typography>
        <BasicButton
          openButtonEditWindow={authoringHelper?.openButtonEditWindowInAuthoringCB}
          isAuthoring={analytics?.isAuthoring}
          currentBtnEditing={authoringHelper?.selectedButtonNameForEditing}
          variant={getButtonVariant(prelemType, "Button1")}
          analyticsEnabled={analytics?.isAnalyticsEnabled}
          ButtonObj={ButtonObj1}
          isEditing={authoringHelper?.isEditing}
          buttonDataObj={ButtonDataObj1}
          secondaryArgs={secondaryArgs}
          analytics={analytics}
          style={{
            display:
              authoringHelper?.isEditing ||
              (content?.Button1_Value && content?.Button1_Value !== "\n")
                ? "inline-flex"
                : "none",
          }}
        />
      </Box>
    );
  };
  return (
    <div
      ref={authoringHelper?.innerRef}
      className={`${classes.websiteSummaryWithSubHeadingWrapper} ${globalClasses.prelemType1} prelem prelemType1 website-summary-with-heading websiteSummaryWithSubHeadingBg`}>
      <Container
        className={
          authoringHelper?.isEditPage ? "grid_full_width prelem-py" : "grid_container prelem-py"
        }
        ref={ref}>
        <Grid container ref={ref}>
          <TwoColumnLayout
            firstColumnContent={firstColumnContent()}
            secondColumnContent={secondColumnContent()}
            gridVal={gridVal}
            customClassName='websiteSummaryWithHeading'
            noGap={true}
            col1Align='start'
            col2Align='start'
          />
        </Grid>
      </Container>
    </div>
  );
};

interface WebsiteSummaryWithSubHeadingProps {
  content: Content;
  analytics: Analytics;
  authoringHelper?: AuthoringHelper;
  secondaryArgs: SecondaryArgs;
}

interface Content {
  Title1?: string;
  Title2?: string;
  Description?: string;
  Button1_Action?: string;
  Button1_Content?: string;
  Button1_Name?: string;
  Button1_RedirectURL?: string;
  Button1_RestEndPonit?: string;
  Button1_Type?: string;
  Button1_Value?: string;
  cropType: "single" | "multiple";
  aspectRatio?: any[];
  TagName?: string;
  ImageCompound: {
    ImageCompound_1: {
      published_images: Image[];
      original_image?: object;
    };
  };
  selected_prelem_bg_color?: string;
}
interface Image {
  aspect_ratio: string;
  bucket_path: string;
  folder_path: string;
  visibility: string;
  ext: string;
}

export default WebsiteSummaryWithSubHeading;
