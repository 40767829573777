import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = (selectedType) => {
  const theme = useTheme();
  return makeStyles(() => {
    return {
      websiteSummaryWithSubHeadingWrapper: {
        "&.websiteSummaryWithSubHeadingBg": {
          background: theme.palette?.[selectedType]?.BACKGROUND,
          "& .labelText": {
            color: theme.palette?.[selectedType]?.LABEL,
          },
          "& .headingText": {
            color: theme.palette?.[selectedType]?.TITLE,
          },
          "& .descriptionText": {
            color: theme.palette?.[selectedType]?.PARAGRAPH,
          },
          "& .title": {
            borderBottom: `1px solid ${theme.palette?.[selectedType]?.LINE}`,
            [theme.breakpoints.down("md")]: {
              marginTop: "12px",
            },
          },
          "& .imageWrapper": {
            paddingRight: 0,
            [theme.breakpoints.up("em")]: {
              paddingRight: "24px",
            },
          },
          "& .headingWrapper": {
            maxWidth: "80%",
            [theme.breakpoints.up("md")]: {
              maxWidth: "350px",
            },
            [theme.breakpoints.up("lg")]: {
              maxWidth: "420px",
            },
          },
          "& .secondColumnContentWrapper": {
            paddingRight: 0,
            paddingTop: "24px",
            width: "100%",
            [theme.breakpoints.down("md")]: {
              textAlign: "left",
              paddingTop: 0,
            },
          },
        },
      },
    };
  })();
};
