import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = (selectedType) => {
  const theme = useTheme();
  return makeStyles(() => {
    return {
      paragraphWithHeadlineWrapper: {
        "&.paragraphWithHeadlineBg": {
          background: theme.palette?.[selectedType]?.BACKGROUND,
          "& .labelText": {
            color: theme.palette?.[selectedType]?.LABEL,
          },
          "& .headingText": {
            color: theme.palette?.[selectedType]?.TITLE,
          },
          "& .descriptionText": {
            color: theme.palette?.[selectedType]?.PARAGRAPH,
          },
          "& .heading": {
            [theme.breakpoints.up("xs")]: {
              paddingRight: 0,
            },
            [theme.breakpoints.up("md")]: {
              paddingRight: "30px",
            },
            [theme.breakpoints.up("em")]: {
              paddingRight: "75px",
            },
            [theme.breakpoints.up("lg")]: {
              paddingRight: "150px",
            },
          },
          "& .detail": {
            textAlign: "left",
            marginBottom: 0,
          },
        },
      },
    };
  })();
};
