import { Skeleton } from "@mui/material";

const SkeletonLoader = () => {
  return (
    <Skeleton
      sx={{
        minHeight: "inherit",
        height: "inherit",
        width: "inherit",
        minwidth: "inherit",
        borderRadius: "inherit",
      }}
      variant='rectangular'
      animation='wave'
      data-testid='skeleton-loader'
    />
  );
};

export { SkeletonLoader };
