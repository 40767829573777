import { useLazyQuery } from "@apollo/client";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DoneIcon from "@mui/icons-material/Done";
import SearchIcon from "@mui/icons-material/Search";
import TagIcon from "@mui/icons-material/Tag";
import {
  Badge,
  Box,
  Button,
  Fab,
  FormControl,
  Grid,
  ListItem,
  Typography,
  useTheme,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { fetchAllMultislotContentList, fetchTagList } from "@platformx/authoring-apis";
import { searchIcon } from "@platformx/shared/static-assets";
import {
  BackArrow,
  Loader,
  ShowToastError,
  capitalizeFirstLetter,
  hasOwnProp,
} from "@platformx/utilities";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import GalleryItems from "../ContentGallery/GalleryItems";
import GalleryItemSearchResults from "./GalleryItemSearchResult";
import GalleryLeftSideBar from "./GalleryLeftSideBar";
import QueryDropDown from "./QueryDropDown/QueryDropDown";
import { ContentProps, TagMagic } from "./utils/contentGalleryTypes";

type DynamicContentGalleryProps = {
  prelemId?: any;
  pageFrom?: string;
  selectedFilters?: any;
  multi_select?: boolean;
  handleSelectedContent?: any;
  onToggleContentGallery?: any;
  single_select?: any;
  selectedItem?: any;
  name?: string;
  allowed_content?: any;
  allowed_collection?: any;
};

/**
 *
 * @param arr array
 * @param multi_select boolean
 * @returns array
 */
const customPairAdded = (arr, multi_select, single_select) => {
  if (multi_select || single_select) {
    const newArray = arr.map((ele) => {
      return {
        ...ele,
        is_checked: false,
      };
    });
    return newArray;
  }
  return arr;
};

/**
 *
 * @param arr array
 * @returns boolean
 */
const saveButtonOnHandle = (arr) => {
  return !arr.some((ele) => ele?.is_checked === true);
};

const DynamicContentGallery = (props: DynamicContentGalleryProps) => {
  const {
    prelemId,
    pageFrom = "",
    multi_select = false,
    handleSelectedContent,
    onToggleContentGallery,
    selectedFilters,
    single_select = false,
    selectedItem,
    name,
    allowed_content,
    allowed_collection,
  } = props;
  const { t } = useTranslation();
  const filter = "ALL";
  const rows = 20;
  const [startIndex, setStartIndex] = useState<number>(0);
  const [isLazyLoad, setIsLazyLoad] = useState<boolean>(false);
  const [isHideSearchBox, setHideSearchBox] = useState<boolean>(false);
  const [isShowItems, setShowItems] = useState<boolean>(true);
  const [isShowCategory, setShowCategory] = useState<boolean>(false);
  const [tagData, setTagData] = useState<any>({});
  const [search, setSearch] = useState("");
  const [items, setItems] = useState<ContentProps[]>([]);
  const [searchItems, setSearchItems] = useState<ContentProps[]>([]);
  const [isSuggestionEnabled, setSuggestionEnabled] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const [selectedTag, setselectedTag] = useState<any>([]);
  const tagRef = useRef<any>({
    tags: [],
  });
  const [tagObj, setTagObj] = useState<TagMagic>({});
  const [fetchMultiSlotContentList] = useLazyQuery(fetchAllMultislotContentList);
  const [runFetchTagList] = useLazyQuery(fetchTagList);
  const queryButton = (search || selectedTag?.length > 0) && pageFrom === "query" ? true : false;

  const saveButtonHandle = multi_select || single_select ? saveButtonOnHandle(items) : false;
  // const isError = false;
  const inlineCss = `
.vod-cat .Platform-x-FormControlLabel-label{
  
  padding: 9px 10px;
  border-radius: 3px;
  margin: 0px;
  font-size: 12px;
}
.Platform-x-FormControlLabel-root.vod-cat{
  margin-left: 0px;
  margin-right: 10px;
}
.vod-cat .Mui-checked + .Platform-x-FormControlLabel-label {
  background-color: #000;
  color: #fff;
  border-radius: 3;
}

.vod-cat .Platform-x-ButtonBase-root.Platform-x-Checkbox-root{
  display: none;
}
.vod-cat .Platform-x-ButtonBase-root.Platform-x-Checkbox-root.Mui-checked{
  display: none;
  background-color: #000;
  color: #fff;
  border-radius: 0;
  padding-left: 0;
  padding-top: 6px;
  padding-bottom: 6px;

}
.vod-cat .Platform-x-SvgIcon-root{
  font-size: 12px;
}
.Platform-x-Paper-root.Platform-x-Paper-elevation {
  border-radius:0px !important;
}
.Platform-x-Paper-root.Platform-x-Paper-elevation.contenttype_card{
    border-radius:5px !important;
}
.Platform-x-ButtonBase-root.Platform-x-AccordionSummary-root.Mui-expanded {
  background: #f5f6f8;
  min-height: 0px;
  border-radius:0px !important;
}
.Platform-x-InputBase-root{
  min-height: 40px !important;
  font-size: 14px;
}
 `;
  function debounce(func, timeout = 500) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, timeout);
    };
  }
  const getTagData = () => {
    runFetchTagList({
      variables: { start: 0, rows: 1000 },
    })
      .then((res) => {
        if (res?.data?.authoring_getTagsList) {
          setTagData(res?.data?.authoring_getTagsList);
        }
      })
      .catch((err) => {
        console.error(JSON.stringify(err, null, 2));
      });
    // }
  };

  const backToContentHandel = () => {
    setHideSearchBox(false);
  };
  const mobileSearchIconHandel = () => {
    setHideSearchBox(true);
  };

  /**
   *save handle
   */
  const handleDone = () => {
    if (multi_select || single_select) {
      const filterItem = items.filter((ele: any) => ele?.is_checked);
      handleSelectedContent({ slots: filterItem }, name);
      onToggleContentGallery(-1);
    } else {
      const queryParam = {
        filter: filter,
        tags: tagRef?.current?.tags,
        searchTerm: search || "",
        pagination: {
          start: 0,
          rows: prelemId === "Prelem_053" ? 5 : 4,
        },
      };
      handleSelectedContent({ slots: items, queryParam });
    }
  };

  const getSearchSuggestions = (fil, sear) => {
    fetchMultiSlotContentList({
      variables: {
        filter: fil,
        searchTerm: sear,
        tags: tagRef?.current?.tags,
        pagination: { start: 0, rows: 20 },
        isSuggestive: true,
      },
    })
      .then((res) => {
        if (res?.data?.authoring_getDynamicContentSearch) {
          // add extra key value pair
          const pairAdded = customPairAdded(
            res?.data?.authoring_getDynamicContentSearch,
            multi_select,
            single_select,
          );
          // setSearchItems(res?.data?.authoring_getDynamicContentSearch);

          setSearchItems(pairAdded);
          setSuggestionEnabled(true);
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const getAllContentTypes = (fil, sear = "") => {
    setLoading(true);
    setStartIndex(0);
    fetchMultiSlotContentList({
      variables: {
        filter: fil,
        searchTerm: sear,
        tags: tagRef?.current?.tags,
        pagination: { start: 0, rows: rows },
        isSuggestive: false,
      },
    })
      .then((res) => {
        if (res?.data?.authoring_getDynamicContentSearch) {
          setLoading(false);
          // add extra key value pair
          const pairAdded = customPairAdded(
            res?.data?.authoring_getDynamicContentSearch,
            multi_select,
            single_select,
          );
          // setItems(res?.data?.authoring_getDynamicContentSearch);

          if (single_select || multi_select) {
            const newShallowArray = [...pairAdded];
            pairAdded?.forEach((val, index) => {
              const newObj: any = newShallowArray?.[index];
              selectedItem?.forEach((item: any) => {
                newObj.is_checked =
                  val?.CurrentPageURL === item?.CurrentPageURL
                    ? item?.is_checked || true
                    : val?.is_checked;
                newShallowArray[index] = newObj;
              });
            });
            selectedItem?.length > 0 ? setItems(newShallowArray) : setItems(pairAdded);
          } else {
            setItems(pairAdded);
          }

          if (res?.data?.authoring_getDynamicContentSearch?.length < 18) {
            setIsLazyLoad(false);
          } else {
            setIsLazyLoad(true);
          }
        } else {
          setLoading(false);
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchMoreContentTypes = (fil, sear, index) => {
    setLoading(true);
    fetchMultiSlotContentList({
      variables: {
        filter: fil,
        searchTerm: sear || "",
        tags: tagRef?.current?.tags,
        pagination: { start: index, rows: rows },
        isSuggestive: false,
      },
    })
      .then((res) => {
        if (res?.data?.authoring_getDynamicContentSearch) {
          // add extra key value pair
          const pairAdded = customPairAdded(
            res?.data?.authoring_getDynamicContentSearch,
            multi_select,
            single_select,
          );
          // const newData = [...items, ...(res?.data?.authoring_getDynamicContentSearch || {})];
          const newData = [...items, ...(pairAdded || {})];

          if (single_select || multi_select) {
            const newShallowArray = [...newData];
            // eslint-disable-next-line no-shadow
            newData?.forEach((val, index) => {
              const newObj: any = newShallowArray?.[index];
              selectedItem?.forEach((item: any) => {
                newObj.is_checked =
                  val?.CurrentPageURL === item?.CurrentPageURL
                    ? item?.is_checked || true
                    : val?.is_checked;
                newShallowArray[index] = newObj;
              });
            });
            selectedItem?.length > 0 ? setItems(newShallowArray) : setItems(() => newData);
          } else {
            setItems(() => newData);
          }

          if (res?.data?.authoring_getDynamicContentSearch?.length < 18) {
            setIsLazyLoad(false);
          } else {
            setIsLazyLoad(true);
          }
        }
        setLoading(false);
      })
      .catch((error: any) => {
        const statusCode = error?.networkError?.statusCode || 0;
        ShowToastError(t("api_error_toast"), statusCode);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTagOnChange = (event) => {
    let tagsArray: any = tagRef?.current?.tags;
    const copyObj = tagObj ? JSON.parse(JSON.stringify(tagObj)) : {};

    const { id: keye, value: val } = event.target;
    if (event.target.checked) {
      tagsArray = [...tagsArray, event.target.value];
      if (tagObj && hasOwnProp(tagObj, "id")) {
        const tempObj = [...tagObj[keye], val];
        copyObj[keye] = tempObj;
        setTagObj(copyObj);
      } else {
        copyObj[keye] = [val];
        setTagObj(copyObj);
      }
    } else {
      tagsArray.splice(tagsArray.indexOf(event.target.value), 1);
      if (Object.keys(tagObj || {}).length > 0 && tagObj[keye].length > 1) {
        tagObj[keye].splice(tagObj[keye].indexOf(val), 1);
        copyObj[keye] = tagObj[keye];
        setTagObj(copyObj);
      } else {
        delete copyObj[keye];
        setTagObj(copyObj);
      }
    }
    tagRef.current = {
      ...tagRef?.current,
      tags: tagsArray?.includes("Courses") ? ["Courses"] : tagsArray,
    };
    setselectedTag(tagsArray?.includes("Courses") ? ["Courses"] : tagsArray);
    getAllContentTypes(filter, search);
  };

  // const clearTag = () => {
  //   setselectedTag([]);
  //   tagRef.current = { ...tagRef?.current, tags: [] };
  //   setTagObj({});
  //   getTagData();
  //   getAllContentTypes(filter, search);
  // };
  const fetchMoreData = () => {
    const nextIndex = startIndex + rows;
    setStartIndex(() => nextIndex);
    fetchMoreContentTypes(filter, search, nextIndex);
  };

  const multiSelectedHandle = (_, index) => {
    const newShallowArray = [...items];
    const newObj: any = newShallowArray?.[index];
    newObj.is_checked = !newObj?.is_checked;
    newShallowArray[index] = newObj;
    setItems(newShallowArray);
  };
  const singleSelectedHandle = (_, index) => {
    const newShallowArray = items.map((item, i) => {
      if (i !== index && item?.is_checked) {
        return { ...item, is_checked: false };
      }
      return item;
    });
    const newObj: any = newShallowArray?.[index];
    newObj.is_checked = !newObj?.is_checked;
    newShallowArray[index] = newObj;
    setItems(newShallowArray);
  };
  const handleSearchChange = useCallback(
    debounce((fil, sear) => getSearchSuggestions(fil, sear)),
    [],
  );
  useEffect(() => {
    const tagsArray = selectedFilters?.tags || [];
    const searchTerm = selectedFilters?.searchTerm;
    setSearch(searchTerm);
    let getTags = [];
    if (single_select || multi_select) {
      const tempTag = allowed_collection
        ? allowed_collection.concat(allowed_content)
        : allowed_content;
      getTags = tagsArray?.includes("Courses")
        ? ["Courses"]
        : tempTag?.length > 0
          ? tempTag
          : tagsArray;
    } else {
      getTags = tagsArray?.includes("Courses") ? ["Courses"] : tagsArray;
    }
    setselectedTag(getTags);

    tagRef.current = {
      ...tagRef?.current,
      tags: getTags,
    };
    getAllContentTypes(filter, searchTerm);
  }, [filter]);

  useEffect(() => {
    if (Object.keys(tagData).length === 0) {
      getTagData();
    }
    if (
      single_select ||
      (multi_select && (allowed_content?.length > 0 || allowed_collection?.length > 0))
    ) {
      setselectedTag(
        allowed_collection ? allowed_collection.concat(allowed_content) : allowed_content,
      );
    }

    const closeDropdown: Parameters<Document["addEventListener"]>[1] = (event: any) => {
      const hasClass = event?.target?.classList.contains("suggestionitems");
      if (!hasClass) {
        setSuggestionEnabled(false);
      }
    };
    document.addEventListener("click", closeDropdown);
    return () => {
      document.removeEventListener("click", closeDropdown);
    };
  }, []);
  const categoryClickHandle = () => {
    if (isShowItems) {
      setShowItems(false);
    } else {
      setShowItems(true);
    }
    if (isShowCategory) {
      setShowCategory(false);
    } else {
      setShowCategory(true);
    }
  };

  const backButtonHandle = () => {
    setShowCategory(false);
    setShowItems(true);
  };
  const onSearchChange = (e) => {
    const { value } = e.currentTarget;
    setSearch(value);
    if (value.length > 3) {
      handleSearchChange(filter, value);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      getAllContentTypes(filter, e.target.value);
      setSearch(e.target.value);
      setSuggestionEnabled(false);
    }
  };

  const resetSearch = () => {
    setSearch("");

    setSearchItems([]);
    setSuggestionEnabled(false);
    getAllContentTypes(filter, "");
  };

  // const handleFilterChange = (e) => {
  //   const { value } = e.target;
  //   setFilter(value);
  // };

  const handlePageSearch = (pageSearchName) => {
    setSearch(pageSearchName);
    getAllContentTypes(filter, pageSearchName);
    setSearchItems([]);
  };
  return (
    <Box
      sx={{
        position: "absolute",
        backgroundColor: "#fff",
        width: "100%",
        height: "100vh",
        zIndex: "10",
      }}>
      {loading && <Loader />}
      {isHideSearchBox && (
        <Grid
          container
          sx={{
            display: { xs: "flex", em: "none" },
            padding: "15px",
          }}>
          <Grid
            item
            xs={10}
            sm={10}
            sx={{
              margin: "auto 0",
              paddingBottom: { xs: "20px", sm: "20px" },
            }}>
            <FormControl fullWidth>
              <TextField
                sx={{
                  backgroundColor: "#f5f6f8",
                  height: "40px",
                  ".Platform-x-Input-root:before": {
                    borderBottom: "2px solid #2d2d39",
                  },
                  ".Platform-x-Input-root:after": {
                    borderBottom: "2px solid #000000",
                  },
                  ".Platform-x-Input-root.Mui-disabled:before": {
                    borderBottom: "2px solid #c3c3cb",
                  },
                  "Platform-x-OutlinedInput-root": {
                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "0px",
                  },
                }}
                variant='filled'
                placeholder={t("search")}
                value={search}
                onChange={onSearchChange}
                onKeyPress={handleKeyPress}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <ArrowBackIosIcon onClick={backToContentHandel} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position='end'>
                      {search && (
                        <CloseRoundedIcon
                          onClick={resetSearch}
                          sx={{
                            cursor: "pointer",
                            position: "absolute",
                            right: "18px",
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={2}
            sm={1}
            sx={{
              margin: "auto 0",
              paddingBottom: { xs: "20px", sm: "20px" },
              display: "flex",
            }}></Grid>
        </Grid>
      )}
      {!isHideSearchBox && (
        <Grid
          container
          sx={{
            display: { xs: "flex", em: "none" },
            padding: "15px",
          }}>
          <Grid
            item
            xs={9}
            sm={10}
            sx={{
              margin: "auto 0",
              paddingBottom: { xs: "20px", sm: "20px" },
              display: "flex",
            }}>
            <ArrowBackIosIcon onClick={() => onToggleContentGallery(-1)} />
            <Typography variant='h4bold'>{t("prelem_choose_content")}</Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sm={1}
            sx={{
              margin: "auto 0",
              paddingBottom: { xs: "0px", sm: "20px" },
              display: "flex",
            }}>
            <Typography
              variant='h5regular'
              sx={{
                marginRight: "10px",
                marginLeft: "10px",
                display: "flex",
                alignItems: "center",
              }}
              onClick={mobileSearchIconHandel}>
              <SearchIcon />
            </Typography>
            <Typography
              variant='h5regular'
              sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
              onClick={categoryClickHandle}>
              {selectedTag?.length > 0 ? (
                <Badge badgeContent={selectedTag.length} color='error'>
                  <TagIcon />
                </Badge>
              ) : (
                <TagIcon />
              )}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        sx={{
          display: { xs: "none", em: "flex" },
          padding: "15px 15px",
          // height: "70px",
          alignItems: "center",
          justifyContent: "center",
          borderBottom: "1px solid #d9dbe9",
        }}>
        <Grid
          item
          xs={12}
          sm={12}
          em={3}
          lg={3}
          sx={{
            margin: "auto 0",
            display: "flex",
            alignItems: "center",
          }}>
          <BackArrow handleReturn={() => onToggleContentGallery(-1)} />
          <Typography variant='h4bold'>{t("prelem_choose_content")}</Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          em={5}
          lg={6}
          sx={{
            padding: {
              xs: "10px 0",
              sm: "0",
              display: "flex",
              justifyContent: "center",
            },
          }}>
          <Grid item xs={12} sm={12} em={12} lg={9}>
            <FormControl fullWidth>
              <TextField
                className='contentTypeCard'
                variant='filled'
                placeholder={t("search")}
                value={search}
                onChange={onSearchChange}
                inputProps={{ style: { textAlign: "left" } }}
                onKeyPress={handleKeyPress}
                sx={{
                  ".Platform-x-InputBase-root.Platform-x-OutlinedInput-root": {
                    borderLeft: 0,
                    height: "50px",
                    border: "solid 1px #D9DBE9 !important",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start' style={{ marginLeft: "15px" }}>
                      <img
                        src={searchIcon}
                        alt='search'
                        style={{ height: "20.25px", width: "20.25px" }}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position='end'>
                      {search && (
                        <CloseRoundedIcon
                          onClick={resetSearch}
                          sx={{
                            cursor: "pointer",
                            position: "absolute",
                            right: "18px",
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          em={4}
          lg={3}
          container
          spacing={0}
          direction='column'
          alignItems='end'
          justifyContent='center'
          sx={{ display: "flex" }}>
          <Box justifyContent='end'>
            <Button
              variant='secondaryButton'
              className='sm'
              onClick={() => onToggleContentGallery(-1)}>
              {t("cancel")}
            </Button>
            <Button
              variant='primaryButton'
              className='sm'
              sx={{
                marginLeft: "12px",
              }}
              // disabled={items.length > 0 ? false : true}
              disabled={saveButtonHandle}
              onClick={handleDone}>
              {t("save")}
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={0}>
        <style>{inlineCss}</style>
        {isShowCategory && (
          <Grid
            container
            xs={12}
            // sm={3}
            em={3}
            xl={2}
            lg={2}
            sx={{ display: { xs: "block", em: "none" } }}>
            <Box
              sx={{
                padding: "15px",
                backgroundColor: "#ced3d9",
              }}>
              <Stack direction='row' alignItems='center' gap={1}>
                <Box component='span'>
                  <ArrowBackIosIcon onClick={backButtonHandle} />
                </Box>
                <Typography variant='h4medium' component='span' sx={{ textTransform: "uppercase" }}>
                  {t("categories")}
                </Typography>
                <Box
                  component='span'
                  sx={{
                    margin: "0px 15px 0px 0",
                    // float: 'right',
                    right: "0",
                    position: "absolute",
                  }}>
                  <SearchIcon />
                </Box>
              </Stack>
            </Box>
            {tagData &&
              tagData?.length > 0 &&
              tagData.map((categories, index) => {
                return (
                  <GalleryLeftSideBar
                    key={index}
                    categoriesdata={categories}
                    updateTagField={handleTagOnChange}
                    selectedTags={selectedTag}
                    allowed_collection={allowed_collection}
                    allowed_content={allowed_content}
                    // multi_select={multi_select}
                    // single_select={single_select}
                    // categoryContent={
                    //   multi_select || single_select
                    //     ? allowed_content?.length > 0 || allowed_content === null
                    //       ? "Content Type"
                    //       : ""
                    //     : ""
                    // }
                    // categoryCollection={
                    //   multi_select || single_select
                    //     ? allowed_collection?.length > 0 || allowed_collection === null
                    //       ? "Collection"
                    //       : ""
                    //     : ""
                    // }
                  />
                );
              })}
          </Grid>
        )}

        <Grid
          container
          xs={12}
          // sm={3}
          em={3}
          xl={2.5}
          lg={2.5}
          sx={{
            // backgroundColor: "#ced3d9",
            overflowY: "scroll",
            overflowX: "hidden",
            height: "calc(100vh - 100px)",
            // height: '620px',
            display: { xs: "none", em: "block" },
          }}>
          <Box
            sx={{
              padding: "15px",
              justifyContent: "space-between",
              display: "flex",
              backgroundColor: "#F7F7FC",
              // textTransform: "uppercase",
            }}>
            <Typography variant='h6semibold'>{capitalizeFirstLetter(t("categories"))}</Typography>

            {/* <Box
                component='span'
                sx={{
                  display: "flex",
                }}>
                <SearchIcon />
              </Box> */}
          </Box>
          {tagData &&
            tagData?.length > 0 &&
            tagData.map((categories, index) => {
              return (
                <GalleryLeftSideBar
                  key={index}
                  categoriesdata={categories}
                  updateTagField={handleTagOnChange}
                  selectedTags={selectedTag}
                  // multi_select={multi_select}
                  // single_select={single_select}
                  allowed_collection={allowed_collection}
                  allowed_content={allowed_content}
                  // categoryContent={
                  //   multi_select || single_select
                  //     ? allowed_content?.length > 0 || allowed_content === null
                  //       ? "Content Type"
                  //       : ""
                  //     : ""
                  // }
                  // categoryCollection={
                  //   multi_select || single_select
                  //     ? allowed_collection?.length > 0 || allowed_collection === null
                  //       ? "Collection"
                  //       : ""
                  //     : ""
                  // }
                />
              );
            })}
        </Grid>

        {isShowItems && (
          <Grid
            item
            xs={12}
            // sm={12}
            em={9}
            xl={9.5}
            lg={9.5}
            sx={{
              backgroundColor: theme?.palette?.customColor?.BGLIGHT,
              // border: "1px solid #ccc",
            }}>
            {queryButton || tagRef?.current?.tags?.length > 0 ? (
              <Box
                sx={{
                  padding: "0 14px",
                  border: "1px solid #ced3d9",
                  borderTop: "none",
                  minHeight: "45px",
                  borderLeft: "none",
                  display: { xs: "block", em: "flex" },
                  alignItems: "center",
                  position: { xs: "fixed", sm: "fixed", em: "initial" },
                  top: { em: "0" },
                  bottom: { xs: "0" },
                  left: { xs: "0" },
                  background: { xs: "#fff" },
                  zIndex: { xs: "9999999" },
                  width: { xs: "100%" },
                }}>
                {tagRef?.current?.tags?.length > 0 ? (
                  <>
                    <Box
                      sx={{
                        padding: "20px 20px 20px 4px",
                        display: { xs: "block", em: "none" },
                      }}>
                      <Box component='span'>{t("selected_tags")}</Box>
                      {/* <Box
                        component='span'
                        sx={{
                          float: "right",
                          display: "inline-block",
                          cursor: "pointer",
                          fontSize: "12px",
                          color: theme?.palette?.customColor?.TERTIARY,
                          textDecoration: "underline",
                        }}
                        onClick={clearTag}>
                        {t("clear_tags")}
                      </Box> */}
                    </Box>
                    <Box>
                      <Typography
                        variant='h6medium'
                        sx={{ display: { xs: "none", em: "block" }, width: "fit-content" }}>
                        {`${t("selected_tags")}: `}
                      </Typography>
                    </Box>
                    <Box
                      component='span'
                      sx={{
                        width: { xs: "100%", em: "auto" },
                        display: "inline-block",
                        paddingBottom: { xs: "20px", em: "0px" },
                        maxWidth: "calc(100% - 210px)",
                      }}>
                      {(tagRef?.current?.tags || [])?.map((tags, index) => {
                        return (
                          <ListItem
                            key={index}
                            sx={{
                              display: "inline-block",
                              width: "auto",
                              marginLeft: "5px",
                              color: "#A0A3BD",
                              fontSize: theme?.fontSize?.fontSize_14,
                              fontWeight: theme?.fontWeight?.fontWeightSemiBold,
                              lineHeight: "1.5",
                              padding: "0px",
                            }}>
                            {tags}
                            {index === selectedTag?.length - 1 || tags === null ? "" : ","}
                          </ListItem>
                        );
                      })}
                    </Box>
                  </>
                ) : null}

                {/* query button */}
                {queryButton ? (
                  <Box
                    component='div'
                    sx={{
                      float: "right",
                      position: "absolute",
                      right: "0",
                      display: { xs: "none", em: "flex", lg: "flex", xl: "flex" },
                      cursor: "pointer",
                      fontSize: "12px",
                      // color: theme?.palette?.customColor?.TERTIARY,
                    }}>
                    <QueryDropDown
                      stateManage={{ searchTerm: search }}
                      filterData={tagRef?.current?.tags}
                    />
                  </Box>
                ) : // <Box
                //   component='span'
                //   sx={{
                //     float: "right",
                //     position: "absolute",
                //     right: "0",
                //     width: { em: "10%", lg: "8%", xl: "7%" },
                //     display: { xs: "none", em: "inline-block" },
                //     cursor: "pointer",
                //     fontSize: "12px",
                //     color: theme?.palette?.customColor?.TERTIARY,
                //     textDecoration: "underline",
                //     lineHeight: "22px",
                //   }}
                //   onClick={clearTag}>
                //   {t("clear_tags")}
                // </Box>
                null}
              </Box>
            ) : null}
            <Box
              sx={{
                padding: "14px 35px 10px 10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}>
              <Typography
                variant='h4medium'
                sx={{
                  display: {
                    xs: "none",
                    em: "block",
                    // em: 'block',
                    // lg: 'block',
                  },
                }}>
                {t("search_results")}
              </Typography>
              <Typography
                component='span'
                sx={{
                  display: {
                    xs: "block",
                    em: "none",
                    // em: 'none',
                    // lg: 'none',
                  },
                }}
                variant='h4medium'>
                {t("recently_added")}
              </Typography>

              {/* <Box
                  component='span'
                  sx={{
                    width: "34px",
                    height: "34px",
                    display: "flex",
                    alignItems: "center",
                    padding: "5px",
                    pointerEvents: "auto",
                    borderRadius: "3px",
                    border: "solid 1px #ced3d9",
                    backgroundColor: "#fff",
                  }}>
                  <img src={IconListViewSvg} alt='IconListViewSvg' />
                </Box> */}
            </Box>

            <Box
              sx={{
                paddingRight: "0px",
                paddingLeft: "0px",
                margin: "0px",
              }}>
              <GalleryItems
                loading={loading}
                galleryObj={items}
                isLazyLoad={isLazyLoad}
                multi_select={multi_select}
                fetchMoreData={fetchMoreData}
                multiSelectedHandle={multiSelectedHandle}
                singleSelectedHandle={singleSelectedHandle}
                single_select={single_select}
              />
            </Box>
            {isSuggestionEnabled && searchItems?.length > 0 ? (
              <Box
                sx={{
                  position: {
                    xs: "absolute",
                    sm: "absolute",
                    em: "absolute",
                  },
                  top: "70px",
                  left: {
                    xs: "33px",
                    sm: "33px",
                    em: "220px",
                    lg: "415px",
                  },
                  width: { xs: "70%", sm: "70%", em: "46%", lg: "32%" },
                  zIndex: "999999",
                }}
                className='searchsuggestion'>
                <GalleryItemSearchResults
                  searchResults={searchItems}
                  handlePageSearch={handlePageSearch}
                />
              </Box>
            ) : null}
          </Grid>
        )}
      </Grid>
      <Box
        sx={{
          display: { xs: isShowCategory ? "none" : "block", em: "none" },
          position: "fixed",
          bottom: 0,
          right: 0,
          zIndex: "9999999",
        }}>
        <Box sx={{ margin: "0 25px 25px 0" }} onClick={handleDone}>
          <Fab size='large' color='primary' aria-label='add'>
            <DoneIcon style={{ color: "#fff" }} />
          </Fab>
        </Box>
      </Box>
    </Box>
  );
};

export default DynamicContentGallery;
