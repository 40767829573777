import AutorenewIcon from "@mui/icons-material/Autorenew";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, CardMedia, Container, Tab, Typography } from "@mui/material";
import { formCroppedUrlString } from "@platformx/utilities";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import Slider from "react-slick";
import { usePrelemImpression } from "../../components/ImpressionHooks/PrelemImpressionHook";
import prelemTypes from "../../globalStyle";
import "../../Style.css";
import useContentData from "../../utils/DynamicSchema/useContentData";
import { getGalleryItems } from "../../utils/helper";
import ImageVideoGalleryModalSliderNew from "../ImageVideoGalleryModalSlider/ImageVideoGalleryModalSliderNew";
import "./ImageAndVideoGalleryNew.css";
import { useCustomStyle } from "./ImageAndVideoGalleryNew.style";

// ts-ignore
const ImageAndVideoGalleryNew = ({ content, analytics, authoringHelper, secondaryArgs }) => {
  const [contentType, setContentType] = useState("image");
  const [indexPos, setIndexPos] = useState(0);
  const [selectedItem, setSelectedItem] = useState("");
  const [open, setOpen] = useState(false);
  const [imageGallery, setImageGallery] = useState<any>([]);
  const [videoGallery, setVideoGallery] = useState<any>([]);

  const windowSettings = {
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    arrows: false,
    infinite: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          className: "center",
          centerMode: true,
          infinite: true,
          centerPadding: "60px",
          arrows: false,
        },
      },
    ],
  };
  const tabletSettings = {
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  const mobileSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    arrows: false,
  };

  const sliderSettings =
    secondaryArgs?.prelemBaseEndpoint?.device === "tablet"
      ? { ...tabletSettings }
      : secondaryArgs?.prelemBaseEndpoint?.device === "mobile"
        ? { ...mobileSettings }
        : { ...windowSettings };

  const handleOpen = (item) => {
    if (authoringHelper?.isModalShow) {
      const indexPosition = [...imageGallery, ...videoGallery].findIndex(
        (gal) => gal.id === item.id,
      );
      setOpen(true);
      setIndexPos(indexPosition);
      setSelectedItem(item.id);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setContentType(newValue);
  };

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  usePrelemImpression(analytics, inView, secondaryArgs);
  /* AnalyticsEnabled dependency added as many times we are not getting analytics provider*async call)
  1. we are first checking in publish app if analytics provider is avaiable or not
  2. if its available we are setting AnalyticsEnabled to true
  3. if its not available we are setting false
*/
  const classes = useCustomStyle();
  const globalClasses = prelemTypes();

  const { contentValue, loader } = useContentData(content, secondaryArgs);

  useEffect(() => {
    if (!loader) {
      const gallery = getGalleryItems(contentValue);
      if (gallery.length > 0) {
        const updatedImageGallery = gallery.filter((item) =>
          Object.prototype.hasOwnProperty.call(item, "image"),
        );
        const updatedVideoGallery = gallery.filter((item) =>
          Object.prototype.hasOwnProperty.call(item, "video"),
        );
        setImageGallery(updatedImageGallery);
        setVideoGallery(updatedVideoGallery);
      }
    }
  }, [loader]);

  return (
    <div
      ref={authoringHelper?.innerRef}
      className={`${classes.imageAndVideoGalleryWrapper} ${globalClasses.prelemType1} prelem prelemType1 image-video-gallery imageAndVideoGalleryBg`}>
      <Container
        className={
          authoringHelper?.isEditPage ? `grid_full_width prelem-py` : `grid_container prelem-py`
        }
        ref={ref}>
        <Box>
          <Box className='textCenter'>
            <Typography variant='h2semibold' id='title'>
              {content.title}
            </Typography>
            <TabContext value={contentType}>
              <Box>
                <TabList
                  onChange={handleChange}
                  TabIndicatorProps={{
                    style: { display: "none" },
                  }}
                  className='buttonsWrapper'>
                  <Tab label='Image' value='image' className='tab-image tab-item' />
                  <Tab label='Video' value='video' className='tab-video tab-item' />
                </TabList>
              </Box>
              <Box
                className='positionRelative'
                // sx={{
                //   "&:hover": {
                //     ".add-content-overlay": {
                //       display: authoringHelper?.authoringHoverShow
                //         ? "flex"
                //         : "none",
                //     },
                //   },
                // }}
              >
                <TabPanel value='image' className='tabPanelWrapper'>
                  <Box
                    className={authoringHelper?.isEditing ? "replaceWrapper" : "hideElementClass"}>
                    <Box
                      className='replaceIconWrapper'
                      onClick={() =>
                        secondaryArgs?.multiSlot?.onToggleContentGallery(contentType, true)
                      }>
                      <AutorenewIcon />
                      <Typography className='overLaytextgap' variant='h3regular' color='textColor'>
                        Replace
                      </Typography>
                    </Box>
                  </Box>
                  <Slider {...sliderSettings}>
                    {imageGallery?.map((item, index) => {
                      const formedUrl = formCroppedUrlString(
                        secondaryArgs?.gcpUrl,
                        secondaryArgs?.bucketName,
                        item.image.original_image.original_image_relative_path,
                        item.image.original_image.ext,
                      ).src;
                      return (
                        <Box key={index}>
                          <Box className='imgBoxWrapper' onClick={() => handleOpen(item)}>
                            <CardMedia
                              component={formedUrl ? "img" : "div"}
                              image={formedUrl ? formedUrl : ""}
                              // sx={{ backgroundColor: color ? color : "" }}
                              alt={item?.option}
                            />
                          </Box>
                          <Typography variant='p3regular'>{item?.option}</Typography>
                        </Box>
                      );
                    })}
                  </Slider>
                </TabPanel>
                <TabPanel value='video' className='tabPanelWrapper'>
                  <Slider {...sliderSettings}>
                    {videoGallery?.map((item, index) => {
                      const formedUrl = formCroppedUrlString(
                        secondaryArgs?.gcpUrl,
                        secondaryArgs?.bucketName,
                        item.video.Thumbnail,
                        "",
                        "vod",
                      ).src;
                      return (
                        <Box key={index}>
                          <Box className='imgBoxWrapper' onClick={() => handleOpen(item)}>
                            <CardMedia
                              component={formedUrl ? "img" : "div"}
                              image={formedUrl ? formedUrl : ""}
                              // sx={{ backgroundColor: color ? color : "" }}
                              alt='galleryimg'
                            />
                            <Box className={`positionAbsolute video-player-overlay`}>
                              <Box>
                                <PlayArrowRoundedIcon className='playIconSize' />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant='p3regular'>{item?.option}</Typography>
                        </Box>
                      );
                    })}
                  </Slider>
                </TabPanel>
              </Box>
            </TabContext>
          </Box>
          {open && (
            <ImageVideoGalleryModalSliderNew
              openModal={open}
              indexPos={indexPos}
              handleClose={handleClose}
              content={content}
              secondaryArgs={secondaryArgs}
              selectedItem={selectedItem}
            />
          )}
        </Box>
      </Container>
    </div>
  );
};

export default ImageAndVideoGalleryNew;
