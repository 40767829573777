import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = (selectedType) => {
  const theme = useTheme();
  return makeStyles(() => {
    return {
      faq1Wrapper: {
        "&.faq1Bg": {
          background: theme.palette?.[selectedType]?.BACKGROUND,
          "& .faq": {
            [theme.breakpoints.up("xs")]: {
              padding: "0 0 20px 0",
              textAlign: "center",
            },
            [theme.breakpoints.up("em")]: {
              padding: "0px 74px 0px 0px",
              textAlign: "left",
            },
            width: "100%",
          },
          "& .title": {
            marginTop: 0,
          },
          "& .accordianWrapper": {
            height: "100%",
            width: "100%",
            overflow: "auto",
            position: "relative",
            textAlign: "left",
            minHeight: "300px",
            [theme.breakpoints.up("xs")]: {
              maxHeight: "560px",
            },
            [theme.breakpoints.up("sm")]: {
              maxHeight: "580px",
            },
            [theme.breakpoints.up("md")]: {
              maxHeight: "590px",
            },
            [theme.breakpoints.up("em")]: {
              maxHeight: "548px",
            },
            [theme.breakpoints.up("lg")]: {
              maxHeight: "590px",
            },
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "& .accordianHeading": {
              background: theme.palette?.[selectedType]?.ACCORDIAN.BACKGROUND,
              "& svg": {
                color: theme.palette?.[selectedType]?.ACCORDIAN.COLOR,
              },
            },
          },
        },
      },
    };
  })();
};
