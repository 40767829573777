import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { LanguageList, countries, genderData, getFlag } from "@platformx/utilities";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { trackUserProfileSaveRendering } from "../../../../utils/tracking/rpiTracking";
import { errorRequest } from "../../../ConstantData";
import FormValidate from "../../../CustomHook/FormValidate";
import DropDown from "../../../DropDown/Dropdown";
import Loader from "../../../Loader/Loader";
import StringOnBlurTextBox from "../../../TextBox/StringTextBoxComponent/StringOnBlurTextBox";
import ToastService from "../../../ToastContainer/ToastService";
import {
  getUniqueTimeZone,
  getUserDetailsService,
  updateUserDetailsService,
} from "../../helperProfile";
import { useCustomStyle } from "./EditProfile.style";

type ProfileProps = {
  secondaryArgs: any;
  open: boolean;
  handleCloseModel: (e?: any) => any;
  setShouldProfileUpdate: (e?: any) => any;
  setSuccessPopUp: (e?: any) => any;
};

const EditProfile = ({
  secondaryArgs = {},
  open,
  handleCloseModel,
  setShouldProfileUpdate,
  setSuccessPopUp,
}: ProfileProps) => {
  const { t } = useTranslation();
  const classes = useCustomStyle();
  const [loading, setLoading] = useState(false);
  const {
    emailValidation,
    mandatoryValidate,
    allFieldAreCheckToValue,
    allFieldAreCheckToEmpty,
    // mandatoryValidateWithMinAndMax,
  } = FormValidate();

  const [isDisabled, setIsDisabled] = useState(false);
  const [usdTime, setUsdTime] = useState<any[]>([]);

  const [stateManage, setStateManage] = useState<any>({
    dob: "",
    title: "",
    gender: "",
    country: "",
    emailID: "",
    lastName: "",
    language: "",
    firstName: "",
    description: "",
    mobileNumber: "",
    ISD: "+91",
    defaultLanguage: "English (UK)",
    timezone: "Asia/Calcutta 22:27:58 GMT+0530 (IST)",
    memberShipId: "",
    loyaltyCardNumber: "",
  });
  const getDefaultValue: any =
    LanguageList().find((ele) => ele?.label === stateManage?.defaultLanguage) || {};

  const [stateErrorManage, setStateErrorManage] = useState<any>({
    emailIDError: "",
    mobileNumberError: "",
    firstNameError: "",
  });

  const handleISD = (_event: any, newValue: any) => {
    setStateManage((prevFormData: any) => {
      return {
        ...prevFormData,
        ISD: newValue?.label,
      };
    });
  };

  const handleLanguage = (_event: any, newValue: any) => {
    setStateManage((prevFormData: any) => {
      return {
        ...prevFormData,
        defaultLanguage: newValue?.label,
      };
    });
  };

  const handleInputChange = (event: any) => {
    event.preventDefault && event.preventDefault();
    const { name = "", value = "" } = event?.target || {};
    setStateManage({
      ...stateManage,
      [name]: value,
    });
  };

  /**
   * error msg update
   */
  const errorMsgUpdate = () => {
    return {
      ...stateErrorManage,
      emailIDError: emailValidation(stateManage.emailID),
      firstNameError: mandatoryValidate(t("first_name"), stateManage.firstName),
      // mobileNumberError: mandatoryValidateWithMinAndMax({
      //   name: t("mobile_number"),
      //   value: stateManage.mobileNumber,
      //   min: 10,
      //   max: 12,
      // }),
    };
  };

  const updateProfile = async (e: any) => {
    setLoading(true);
    const userId = localStorage.getItem("userId");
    e.preventDefault && e.preventDefault();
    const inputEmptyValidate = allFieldAreCheckToValue({
      emailID: stateManage.emailID,
      firstName: stateManage.firstName,
      // mobileNumber: stateManage.mobileNumber,
    });

    const errorMsg = errorMsgUpdate();
    setStateErrorManage(errorMsg);
    if (inputEmptyValidate) {
      // all error message are resolve comes true
      const errorMsgCheck = allFieldAreCheckToEmpty({
        ...errorMsg,
      });
      if (errorMsgCheck) {
        const saveData = {
          ...stateManage,
          mobileNumber: stateManage.ISD
            ? `${stateManage.ISD}-${stateManage.mobileNumber}`
            : stateManage.mobileNumber,
        };
        try {
          const res = await updateUserDetailsService({
            userId: userId,
            userDetails: saveData,
            secondaryArgs: secondaryArgs,
          });
          const { data: { errors = [], data = {} } = {} } = res;
          const isSuccess = data?.publish_updateUserProfile?.message || "";
          if (isSuccess) {
            setSuccessPopUp(true);
            handleCloseModel();
            setIsDisabled(true);
            setShouldProfileUpdate(true);
            trackUserProfileSaveRendering(
              { container_type: "my_profile", pageName: "user/profile" },
              secondaryArgs,
            );
          } else {
            setIsDisabled(false);
            setSuccessPopUp(false);
            ToastService.failToast(errors.length > 0 ? errors[0]?.message : t(errorRequest));
            // eslint-disable-next-line no-console
            console.error(errors);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const strTime = new Date().toLocaleString([], {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    hour12: false,
  });
  const time = new Date(strTime).toTimeString().slice(0, -21);
  const defaultTimeZone = `${Intl.DateTimeFormat().resolvedOptions().timeZone} ${time}(IST)`;

  useEffect(() => {
    getUniqueTimeZone().forEach((val: any) => {
      setUsdTime((prev) => [...prev, { label: val.label, time: val.time }]);
    });
    setStateManage((prevFormData: any) => {
      return {
        ...prevFormData,
        timezone: defaultTimeZone,
      };
    });
  }, []);

  const handleChangeTimeZone = (_event: any, newValue: { label: any }) => {
    setStateManage((prevFormData: any) => {
      return {
        ...prevFormData,
        timezone: newValue?.label,
      };
    });
  };

  const getUserDetailsServiceApi = async () => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      const res = await getUserDetailsService({
        userId: userId,
        secondaryArgs: secondaryArgs,
      });
      const { status = 0, data: { errors = [], data: { publish_viewProfile = {} } = {} } = {} } =
        res;
      const { preferred_sites_languages = {}, default_site = "" } = publish_viewProfile;
      const lastSpaceIndex = publish_viewProfile?.phone?.lastIndexOf("-");
      const ISD = publish_viewProfile?.phone?.substring(0, lastSpaceIndex).trim(); // Everything before the last space
      const tempPhone = publish_viewProfile?.phone?.substring(lastSpaceIndex + 1); // Everything after the last space

      if (status === 200 && Object.keys(publish_viewProfile || {}).length > 0) {
        const languageUpdate: any =
          LanguageList().find(
            (ele) => ele?.id === preferred_sites_languages?.[default_site] || "en",
          ) || {};

        const newObj = {
          ...stateManage,
          dob: publish_viewProfile?.dob,
          gender: publish_viewProfile?.gender,
          emailID: publish_viewProfile?.email,
          lastName: publish_viewProfile?.last_name,
          firstName: publish_viewProfile?.first_name,
          mobileNumber: tempPhone,
          ISD,
          timezone: publish_viewProfile?.timezone || "Asia/Calcutta 22:27:58 GMT+0530 (IST)",
          defaultLanguage: languageUpdate?.label,
          memberShipId: publish_viewProfile?.member_id,
          loyaltyCardNumber: publish_viewProfile?.loyalty_card_number,
        };
        setStateManage(newObj);
      } else {
        setStateManage({});
        // eslint-disable-next-line no-console
        console.error(errors);
      }
    }
  };

  useEffect(() => {
    getUserDetailsServiceApi();
  }, []);

  const onKeyDown = (e) => {
    e.preventDefault();
    return false;
  };

  return (
    <Modal
      open={open}
      // onClose={handleCloseModel}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "100%", em: "60%" },
          height: { xs: "100dvh", em: "auto" },
          // bgcolor: "background.paper",
          boxShadow: 24,
          p: { xs: "10px", em: 4 },
          borderRadius: { xs: 0, em: 2 },
          overflowY: { xs: "scroll", em: "hidden" },
        }}
        className={`${classes.profileScreenWrapper} prelem prelemType1 profileScreenBg`}>
        <Box sx={{ height: "100%", width: "100%" }}>
          <CancelOutlinedIcon fontSize='large' className='close' onClick={handleCloseModel} />
          <Box>
            <Box className='signUpScreen'>
              <Grid xs={12}>
                <Box className='profilePageWrapper'>
                  <Box className='profileForm'>
                    <Grid container className='formContainer'>
                      <Grid item xs={12} sm={12} em={12} className='gap'>
                        <Box className='gridContentWrapper'>
                          <Typography variant='h4semibold' className='marginZero'>
                            {t("basic_details")}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={12} em={6} className='gap'>
                        <Box className='gridContentWrapper'>
                          <StringOnBlurTextBox
                            maxLength={20}
                            name='firstName'
                            label={t("first_name")}
                            isDisabled={isDisabled}
                            isCloseIcon={!isDisabled}
                            cssClass='input-control-textbox'
                            value={stateManage.firstName}
                            handleChange={handleInputChange}
                            errorMessage={stateErrorManage.firstNameError}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={12} em={6} className='gap'>
                        <Box className='gridContentWrapper'>
                          <StringOnBlurTextBox
                            maxLength={20}
                            name='lastName'
                            label={t("last_name")}
                            cssClass='input-control-textbox'
                            value={stateManage.lastName}
                            handleChange={handleInputChange}
                            isDisabled={isDisabled}
                            errorMessage={""}
                            isCloseIcon={!isDisabled}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={12} em={6} className='gap'>
                        <Box className='gridContentWrapper'>
                          <Box className='mobileWithIsdcode'>
                            <Autocomplete
                              disablePortal
                              id='auto-complete-textbox'
                              className='auto-complete-textbox defaultCode smallTxtBox'
                              options={countries}
                              size='small'
                              popupIcon={<ExpandMoreIcon />}
                              value={stateManage.ISD ?? ""}
                              onChange={handleISD}
                              disabled={isDisabled}
                              renderOption={(props, option) => (
                                <Box component='li' className='flagRow' {...props}>
                                  <Box className='flagIcon'>
                                    <img
                                      loading='lazy'
                                      alt='country flag'
                                      title='country flag'
                                      width='25'
                                      src={`https://flagcdn.com/w20/${option?.code?.toLowerCase()}.png`}
                                      sizes='(max-width: 600px) 50vw, 100vw'
                                    />
                                  </Box>
                                  {option.label}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder='ISD'
                                  variant='filled'
                                  className='autoComplete selectCountrycode'
                                />
                              )}
                            />
                            <StringOnBlurTextBox
                              maxLength={12}
                              name='mobileNumber'
                              isDisabled={isDisabled}
                              label={t("mobile_number")}
                              cssClass='input-control-textbox mobileTxtBox'
                              handleChange={handleInputChange}
                              value={stateManage.mobileNumber}
                              customInPutAllowField={"number"}
                              errorMessage={stateErrorManage.mobileNumberError}
                              isCloseIcon={!isDisabled}
                            />
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={12} em={6} className='gap'>
                        <Box className='gridContentWrapper'>
                          <Autocomplete
                            disablePortal
                            defaultValue={getDefaultValue?.label}
                            id='auto-complete-textbox'
                            className='auto-complete-textbox'
                            options={LanguageList()}
                            value={stateManage.defaultLanguage}
                            onChange={handleLanguage}
                            disabled={isDisabled}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                onKeyDown={onKeyDown}
                                onKeyPress={onKeyDown}
                                name='defaultLanguage'
                                variant='filled'
                                className='autoComplete selectDefaultCountry'
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position='start' className='flagWrapper'>
                                      <img
                                        src={getFlag(getDefaultValue?.id)}
                                        alt='flag'
                                        className='flagImage'
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                            popupIcon={<ExpandMoreIcon />}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={12} em={6} className='gap'>
                        <Box className='gridContentWrapper'>
                          <StringOnBlurTextBox
                            maxLength={32}
                            name='emailID'
                            isDisabled={true}
                            isCloseIcon={false}
                            label={t("email_id")}
                            value={stateManage.emailID}
                            cssClass='input-control-textbox'
                            handleChange={handleInputChange}
                            errorMessage={stateErrorManage.emailIDError}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={12} em={6} className='gap'>
                        <Box className='gridContentWrapper'>
                          <DropDown
                            name='gender'
                            arrayData={genderData()}
                            label={t("gender")}
                            cssClass='input-control-selectbox'
                            value={stateManage.gender}
                            parentHandler={handleInputChange}
                            stateValue={stateManage.gender}
                            isDisabled={isDisabled}></DropDown>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={12} em={6} className='gap'>
                        <Box className='gridContentWrapper'>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              inputFormat='DD/MM/YYYY'
                              label={t("dob")}
                              disabled={isDisabled}
                              className='customCalender1'
                              maxDate={dayjs(new Date())}
                              value={dayjs(stateManage.dob)}
                              renderInput={(props) => (
                                <TextField
                                  {...props}
                                  variant='filled'
                                  className='customCalender'
                                  value={dayjs(stateManage.dob)}
                                  inputProps={{
                                    ...props.inputProps,
                                    readOnly: true,
                                  }}
                                />
                              )}
                              // defaultValue={dayjs(stateManage.dob)}
                              onChange={(newValue) => {
                                handleInputChange({
                                  target: {
                                    name: "dob",
                                    value: newValue,
                                  },
                                });
                              }}
                            />
                          </LocalizationProvider>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={12} em={6} className='gap'>
                        <Box className='gridContentWrapper'>
                          <Autocomplete
                            disablePortal
                            disabled={isDisabled}
                            id='timeZone'
                            options={usdTime}
                            value={stateManage.timezone}
                            onChange={handleChangeTimeZone}
                            renderInput={(params) => <TextField {...params} name='timezone' />}
                            popupIcon={<ExpandMoreIcon />}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} em={6} className='gap'>
                        <Box className='gridContentWrapper'>
                          <StringOnBlurTextBox
                            maxLength={50}
                            name='loyaltyCarNumber'
                            label={t("loyalty_card_number")}
                            isDisabled={true}
                            isCloseIcon={false}
                            cssClass='input-control-textbox'
                            value={stateManage.loyaltyCardNumber}
                            handleChange={handleInputChange}
                            errorMessage={""}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} em={6} className='gap'>
                        <Box className='gridContentWrapper'>
                          <StringOnBlurTextBox
                            maxLength={50}
                            name='memberShipId'
                            label={t("member_ship_id")}
                            cssClass='input-control-textbox'
                            value={stateManage.memberShipId}
                            handleChange={handleInputChange}
                            isDisabled={true}
                            errorMessage={""}
                            isCloseIcon={false}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} em={12} className='gap'>
                        <Box className='gridContentWrapper' sx={{ float: "right" }}>
                          <Button
                            variant='tertiaryButton1'
                            className='actionButton'
                            onClick={updateProfile}
                            data-testid='save-profile'>
                            {t("update")}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Box>
          </Box>
        </Box>
        {loading ? <Loader /> : null}
      </Box>
    </Modal>
  );
};

export default EditProfile;
