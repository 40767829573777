const validTypes = ["PRIMARY_PRELEM", "SECONDARY", "TERTIARY"];
const getTypographyColor = (type) => {
  switch (type) {
    case "PRIMARY_PRELEM":
      return "primaryTitle";
    case "SECONDARY_PRELEM":
      return "secondaryTitle";
    case "TERTIARY_PRELEM":
      return "tertiaryTitle";
    default:
      return "primaryTitle";
  }
};

const getDescriptionColor = (type) => {
  switch (type) {
    case "PRIMARY_PRELEM":
      return "primaryParagraph";
    case "SECONDARY_PRELEM":
      return "secondaryParagraph";
    case "TERTIARY_PRELEM":
      return "tertiaryTitle";
    default:
      return "primaryParagraph";
  }
};
const getLabelColor = (type) => {
  switch (type) {
    case "PRIMARY_PRELEM":
      return "primaryLabel";
    case "SECONDARY_PRELEM":
      return "secondaryLabel";
    case "TERTIARY_PRELEM":
      return "tertiaryLabel";
    default:
      return "primaryLabel";
  }
};
const getLinkColor = (type) => {
  switch (type) {
    case "PRIMARY_PRELEM":
      return "primaryAnchorLink";
    case "SECONDARY_PRELEM":
      return "secondaryAnchorLink";
    case "TERTIARY_PRELEM":
      return "tertiaryAnchorLink";
    default:
      return "primaryLabel";
  }
};

const getButtonVariant = (type, btnType) => {
  switch (type) {
    case "PRIMARY_PRELEM":
      return `primary${btnType}`;
    case "SECONDARY_PRELEM":
      return `secondary${btnType}`;
    case "TERTIARY_PRELEM":
      return `tertiary${btnType}`;
    default:
      return `primary${btnType}`;
  }
};
const getImageColor = (type) => {
  switch (type) {
    case "PRIMARY_PRELEM":
      return "grayscale(100%) brightness(0)";
    case "SECONDARY_PRELEM":
      return "grayscale(100%) brightness(100%)";
    case "TERTIARY_PRELEM":
      return "grayscale(100%) brightness(0)";
    default:
      return "grayscale(100%) brightness(0)";
  }
};

const getPrelemType = (type) => {
  switch (type) {
    case "PRIMARY_PRELEM":
      return "prelemType1";
    case "SECONDARY_PRELEM":
      return "prelemType2";
    case "TERTIARY_PRELEM":
      return "prelemType3";
    default:
      return "prelemType1";
  }
};

export {
  getButtonVariant,
  getDescriptionColor,
  getImageColor,
  getLabelColor,
  getLinkColor,
  getPrelemType,
  getTypographyColor,
  validTypes,
};
