import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Box, Button, CardMedia, Typography } from "@mui/material";
import { PlatFormXDateTimeFormat, getImage } from "@platformx/utilities";
import { useState } from "react";
import ImageVideoGalleryModalSliderNew from "../../../prelems/ImageVideoGalleryModalSlider/ImageVideoGalleryModalSliderNew";
import { onClickCard } from "../../../utils/helper";
import { useClickImpression } from "../../ImpressionHooks/ClickImpressionHook";
import { getIcon } from "../../Utils/helperFns";
import { useCustomStyle } from "./XCard4.style";

const XCard4 = ({ content, secondaryArgs, cardIndex = 0, analytics = {} }) => {
  const classes = useCustomStyle();
  const { triggerClickAnalytics } = useClickImpression();
  const formedUrl = getImage(content, secondaryArgs);
  const { color, imageUrl } = formedUrl;

  const [modalStatus, setModalStatus] = useState(false);

  const toggleModalStatus = () => {
    if (!secondaryArgs?.editState) setModalStatus(!modalStatus);
  };
  return (
    <>
      <Box className={`${classes.XCard4Wrapper} XCard4Box`}>
        <Box className='XCard4innderBox'>
          <Box className='imgWrapper'>
            <Box className='IconTopRight'>
              <img
                src={getIcon(
                  content?.ContentType,
                  content?.Content_icon,
                  secondaryArgs?.gcpUrl,
                  secondaryArgs?.bucketName,
                )}
                alt='icon'
                height={25}
                width={25}
              />
            </Box>
            <CardMedia
              component={imageUrl ? "img" : "div"}
              image={imageUrl ? imageUrl : ""}
              title={imageUrl ? content?.Thumbnail?.AltText : "image"}
              sx={{ backgroundColor: color ? color : "", height: "100%", width: "100%" }}
            />
            <Box className='mainContentBox'>
              <Box className='InnerWrapper'>
                <Box className='contentWrapperBox'>
                  <Typography variant='h4bold'>{content?.Title}</Typography>
                  <Box className='dateWrapper'>
                    <Box className='iconWrapper'>
                      <AccessTimeIcon className='accessTime' />
                    </Box>
                    <Typography variant='p4medium' className='publishTime'>
                      {PlatFormXDateTimeFormat(content?.PublishedDate || content?.lastModifiedDate)}
                    </Typography>
                  </Box>
                </Box>
                <Box className='ButtonWrapper'>
                  <Button
                    variant='primaryButton2'
                    onClick={(e) =>
                      onClickCard(
                        e,
                        content?.EditorialItemPath,
                        content,
                        secondaryArgs,
                        cardIndex,
                        analytics,
                        triggerClickAnalytics,
                        toggleModalStatus,
                      )
                    }>
                    View
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {modalStatus && !secondaryArgs?.editState && (
        <ImageVideoGalleryModalSliderNew
          openModal={modalStatus}
          handleClose={toggleModalStatus}
          content={content}
          secondaryArgs={secondaryArgs}
        />
      )}
    </>
  );
};
export default XCard4;
