import { useLazyQuery } from "@apollo/client";
import { useCallback } from "react";

const useAIContent = (query) => {
  const [fetchData, { loading, error, data }] = useLazyQuery(query);

  const fetchContent = useCallback(
    async (collectionId, assetId) => {
      try {
        const result = await fetchData({
          variables: {
            collectionId,
            assetId,
          },
        });
        return result;
      } catch (err) {
        console.error("Error fetching data for AI content:", err);
      }
    },
    [fetchData],
  );

  return { fetchContent, loading, error, data };
};

export default useAIContent;
