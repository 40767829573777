import { Avatar, Box, Chip, Grow, Typography } from "@mui/material";
import { Coin, CouponIcon, EventImgIcon, OfferIcon } from "@platformx/shared/static-assets";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getUserCampaignList } from "../../helperProfile";
import { useCustomStyle } from "./Challanges.style";
import ChallangesSkelton from "./ChallangesSkelton";

interface ChallengeInterface {
  campaignName: string;
  campaignId: string;
  isActive: boolean;
  imgsrc: string;
  pointsEarns: any;
  imageUrl: string;
  destinationUrl: string;
}

const Challenges = ({ secondaryArgs }) => {
  const classes = useCustomStyle();
  const [challanges, setchallanges] = useState<ChallengeInterface[]>([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const getChallangeApi = async () => {
    const res = await getUserCampaignList({
      secondaryArgs: secondaryArgs,
      isLeaderBoard: false,
    });
    const { data: { data: { users_getCampaignList = [] } = {} } = {} }: any = res || {};
    if (users_getCampaignList && users_getCampaignList.length > 0) {
      const activeEvent = users_getCampaignList.filter((item) => item?.isActive);
      setchallanges(activeEvent);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  //RenderIcon logic
  const renderIcon = (pointsEarn) => {
    switch (pointsEarn) {
      case "points":
      case "Points":
        return <img src={Coin} className='coinImage icons' alt='coin' />;
      case "Coupon":
        return <img src={CouponIcon} className='couponImage icons' alt='coupon' />;
      case "Offer":
        return <img src={OfferIcon} className='offerImage icons' alt='offer' />;
      default:
        return <img src={Coin} className='coinImage icons' alt='coin' />;
    }
  };
  //RenderPoint logic
  const renderPoints = (pointsEarns) => {
    const totalPointsLength = pointsEarns?.length;
    const type = pointsEarns?.[0]?.type;

    if (totalPointsLength > 1 && type) {
      return type === "points" || type === "Points"
        ? `${pointsEarns?.[0]?.points_earn} + ${totalPointsLength - 1} more`
        : `${type} + ${totalPointsLength - 1} more`;
    } else if (totalPointsLength === 1) {
      return type === "points" || type === "Points" ? `${pointsEarns?.[0]?.points_earn} Pts` : type;
    }
  };
  useEffect(() => {
    getChallangeApi();
  }, []);
  const openLinkInNewTab = (link) => {
    window && window.open(link, "_blank", "noopener,noreferrer");
  };
  return (
    <Box className={`${classes.challangesWrapper} challangesWrapperSection gap`}>
      <Typography variant='h4medium' className='noTopMargin'>
        {`${t("active_challenges")}`}
      </Typography>
      {loading ? (
        <ChallangesSkelton />
      ) : challanges?.length > 0 ? (
        <Box className='challangesList'>
          {challanges?.length > 0 &&
            challanges?.map((item, index) => (
              <Grow in={true} timeout={1000 * index} key={index}>
                <Box
                  key={item.campaignId}
                  display='flex'
                  alignItems='flex-start'
                  className='challangeItem'
                  onClick={() => openLinkInNewTab(item?.destinationUrl)}>
                  <Box className='challangeImage'>
                    <Avatar
                      src={item?.imageUrl ? item?.imageUrl : EventImgIcon}
                      sx={{ width: 68, height: 68, borderRadius: 0 }}></Avatar>
                  </Box>

                  {/* Right side content */}
                  <Box ml={2}>
                    <Chip
                      label={
                        <Typography variant='p4regular'>
                          {renderPoints(item?.pointsEarns)}
                        </Typography>
                      }
                      className='customchip'
                      icon={renderIcon(item?.pointsEarns?.[0]?.type)} // Render appropriate icon
                    />
                    {/* Paragraph */}
                    <Typography variant='p4medium' className='noMargin one-line-ellipsis'>
                      {item?.campaignName}
                    </Typography>
                  </Box>
                </Box>
              </Grow>
            ))}
        </Box>
      ) : (
        <Box className='noresultFound'>
          <Typography variant='h5regular' className='message'>
            {`${t("no_results_found")}`}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Challenges;
