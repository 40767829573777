import { Box } from "@mui/material";
import { CROP_TYPE, ShowToastError, ShowToastSuccess } from "@platformx/utilities";
import {
  DamContentGallery,
  ImageCrop,
  ShowCaseCrops,
  usePostImageCrop,
} from "@platformx/x-image-render";
import React, { useEffect, useRef, useState } from "react";
import { GalleryOption } from "./GalleryOption";
// import "./x-image-render.css";
import { useTranslation } from "react-i18next";

const XGalleryWithText = ({ callBack, editData, isCrop = true, name = "", items, setItems }) => {
  // const [returnData, setReturnData] = useState(editData);
  const { t } = useTranslation();

  const [galleryDialogOpen, setGalleryDialogOpen] = useState(false);
  const [imageDialog, setImageDialog] = useState(false);
  const { cropRequest, isLoading: processing } = usePostImageCrop();
  const currentItem = useRef(0);
  const [returnData, setReturnData] = useState(editData);
  const [manualCropShow, setManualCropShow] = useState(false);
  const [showCropPreview, setShowCropPreview] = useState(false);

  const handleSelectedVideo = (video) => {
    callBack(video, currentItem.current, "video");
    toggleVideoGallery(false);
  };

  const toggleVideoGallery = (toggleState: boolean) => {
    setGalleryDialogOpen(toggleState);
  };

  const toggleImageGallery = (toggleState: boolean) => {
    setImageDialog(toggleState);
  };

  const showGallery = (type, id) => {
    window.scrollTo(0, 0);
    currentItem.current = id;
    if (type === "images") setImageDialog(true);
    else setGalleryDialogOpen(true);
  };

  const autoCropCallBack = (data, img) => {
    if (data) {
      const {
        images = [],
        ext,
        original_image_relative_path = "",
        visibility = "",
        bitstream_id,
      } = data;
      if (images?.length > 0) {
        const retdata = {
          published_images: images,
          original_image: {
            original_image_relative_path,
            bitStreamId: bitstream_id,
            auto: true,
            ext: ext,
            visibility,
            Thumbnail: img?.Thumbnail,
            Title: img?.Title,
            Description: img?.Description,
          },
          selected_image: img,
        };
        setReturnData(retdata);
        setImageDialog(false);
        ShowToastSuccess(`${t("auto_cropped_successfully")}`);
        if (name) callBack(retdata, currentItem.current);
        else callBack(retdata);
      } else {
        setImageDialog(false);
        setManualCropShow(true);
        ShowToastError(`${t("auto_cropping_failed")}`);
      }
    } else {
      setImageDialog(false);
      setManualCropShow(true);
      ShowToastError(`${t("auto_cropping_failed")}`);
    }
  };

  const autoCropFunc = async (selectedImg) => {
    const payload = {
      bitstreamId: selectedImg.bitStreamId,
      visibility: "public",
    };
    await cropRequest(CROP_TYPE.AUTO, payload, autoCropCallBack, selectedImg);
  };

  const handleSelectedImage = (image) => {
    if (isCrop) {
      autoCropFunc(image);
    } else {
      // noCropFunc(image);
      toggleCropper(true);
      toggleImageGallery(false);
    }
  };

  const handleEdit = () => {
    setShowCropPreview(false);
    setManualCropShow(true);
  };

  // const changeCrop = () => {
  //   // setShowCropPreview(true);
  //   setManualCropShow(true);
  // };
  const toggleCropper = (toggle) => {
    setManualCropShow(toggle);
  };

  const changeCrop = (id) => {
    // setShowCropPreview(true);
    currentItem.current = id;
    setManualCropShow(true);
  };

  const backTo = () => {
    if (manualCropShow) setManualCropShow(false);
    if (showCropPreview) setShowCropPreview(false);
  };

  const doneCropCompleted = (
    cropImages: any = [],
    ext = "",
    original_image_relative_path = "",
    visibility = "",
    bitstream_id = "",
    img: any = {},
  ) => {
    if (cropImages && cropImages.length > 0) {
      const relativeUrl = `${cropImages?.[0]?.folder_path}.${ext}`;
      const data = {
        published_images: cropImages,
        original_image: {
          original_image_relative_path,
          bitStreamId: bitstream_id,
          auto: false,
          ext: ext,
          visibility,
          Thumbnail: img?.Thumbnail,
          Title: img?.Title,
          Description: img?.Description,
        },
        selected_image: img,
        relativeUrl: relativeUrl,
      };
      setReturnData(data);
      if (name) callBack(data, name);
      else callBack(data);
    }
    setManualCropShow(false);
  };

  useEffect(() => {
    if (editData && JSON.stringify(editData) !== JSON.stringify(returnData)) {
      setReturnData(editData);
    }
  }, [editData]);

  const currentSelectedItem = items.find((item) => item.id === currentItem.current);

  // useEffect(() => {
  //   if (editData && JSON.stringify(editData) !== JSON.stringify(returnData)) {
  //     setReturnData(editData);
  //   }
  // }, [editData]);

  // const currentSelectedItem = items.find((item) => item.id === currentItem.current);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#FFF",
        }}>
        {imageDialog && (
          <DamContentGallery
            handleImageSelected={handleSelectedImage}
            toggleGallery={toggleImageGallery}
            assetType={"Image"}
            processing={processing}
            dialogOpen={imageDialog}
            isCrop={isCrop}
          />
        )}
        {galleryDialogOpen && (
          <DamContentGallery
            handleSelectedVideo={handleSelectedVideo}
            toggleGallery={toggleVideoGallery}
            assetType={"Video"}
            dialogOpen={galleryDialogOpen}
          />
        )}
      </Box>
      {manualCropShow && (
        <ImageCrop
          open={manualCropShow}
          isCrop={isCrop}
          backTo={backTo}
          doneCropCompleted={doneCropCompleted}
          originalImage={currentSelectedItem?.image?.original_image}
          prelemId={1}
          i={1}
          published_images={currentSelectedItem?.image?.published_images}
        />
      )}
      {showCropPreview && (
        <ShowCaseCrops
          open={showCropPreview}
          backTo={backTo}
          handleEdit={handleEdit}
          data={returnData}
        />
      )}
      <GalleryOption
        showGallery={showGallery}
        items={items}
        setItems={setItems}
        changeCrop={changeCrop}
      />
    </>
  );
};

export default React.memo(XGalleryWithText);
