import { Box, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { getImage, handleHtmlTags, PlatFormXDateTimeFormat } from "@platformx/utilities";
import { useState } from "react";
import { useClickImpression } from "../../components/ImpressionHooks/ClickImpressionHook";
import { getIcon } from "../../components/Utils/helperFns";
import { onClickCard } from "../../utils/helper";
import ImageVideoGalleryModalSliderNew from "../ImageVideoGalleryModalSlider/ImageVideoGalleryModalSliderNew";

const BlogTilesCard2 = ({ content, secondaryArgs, analytics, cardIndex }) => {
  const { triggerClickAnalytics } = useClickImpression();
  const [modalStatus, setModalStatus] = useState(false);

  const toggleModalStatus = () => {
    if (!secondaryArgs?.editState) setModalStatus(!modalStatus);
  };

  const formedUrl = getImage(content, secondaryArgs);
  const { color, imageUrl } = formedUrl;

  return (
    <>
      <Box
        sx={{
          "&:hover": {
            ".button-name": {
              display: secondaryArgs?.editState ? "none" : "block",
            },
          },
        }}
        className='overlay-wrapper blogTilesCardWrapper blogTilesCard2Type2'>
        <Card
          className='blogTilesCard noShadow'
          onClick={(e) =>
            onClickCard(
              e,
              content?.EditorialItemPath,
              content,
              secondaryArgs,
              cardIndex,
              analytics,
              triggerClickAnalytics,
              toggleModalStatus,
            )
          }>
          <Grid container className='alignText'>
            <Grid xs={12} md={6}>
              <CardMedia
                className='blogTilesCardMedia1'
                sx={{
                  backgroundColor: color ? color : "",
                  height: {
                    xs: "204px",
                    sm: "382px",
                    md: "330px",
                    lg: "479px",
                  },
                }}
                image={imageUrl ? imageUrl : ""}>
                <Box className='cardOverlay1'>
                  <Box className='contentIcons1'>
                    <img
                      alt='BlogTilesCard2Img'
                      src={getIcon(
                        content?.ContentType,
                        content?.Content_icon,
                        secondaryArgs?.gcpUrl,
                        secondaryArgs?.bucketName,
                      )}
                      className='fullwidth'
                      width={25}
                      height={25}
                    />
                  </Box>
                </Box>
              </CardMedia>
            </Grid>
            <Grid xs={12} md={6}>
              <CardContent className='blogTilesCardContent extraGap'>
                <Typography variant='h2medium' className='threeline title'>
                  {content.Title}
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <Typography variant='p4bold' component='div' className='publishedBy marginZero'>
                    {content?.Author?.trim() || content?.PublishedBy || content?.LastModifiedBy}
                  </Typography>
                  <Typography variant='p4regular' className='publishedBy gap marginZero'>
                    {PlatFormXDateTimeFormat(content?.PublishedDate || content?.lastModifiedDate)}
                  </Typography>
                </Box>
                <Typography variant='p3regular' className='fiveline'>
                  {" "}
                  {handleHtmlTags(content.Description)}
                </Typography>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </Box>
      {modalStatus && !secondaryArgs?.editState && (
        <ImageVideoGalleryModalSliderNew
          openModal={modalStatus}
          handleClose={toggleModalStatus}
          content={content}
          secondaryArgs={secondaryArgs}
        />
      )}
    </>
  );
};

export default BlogTilesCard2;
