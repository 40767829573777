import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = (selectedType) => {
  const theme = useTheme();
  return makeStyles(() => {
    return {
      service1PrelemWrapper: {
        "&.service1PrelemBg": {
          background: theme.palette?.[selectedType]?.BACKGROUND,
          "& .labelText": {
            color: theme.palette?.[selectedType]?.LABEL,
          },
          "& .headingText": {
            color: theme.palette?.[selectedType]?.TITLE,
          },
          "& .descriptionText": {
            color: theme.palette?.[selectedType]?.PARAGRAPH,
          },
          "& .TypoRight": {
            margin: "30px 0",
          },
          "& #Title": {
            color: theme.palette?.[selectedType]?.LABEL,
          },
          "& .LeftServices1": {
            paddingRight: "25px",
            [theme.breakpoints.down("sm")]: {
              paddingRight: "0px",
            },
          },
          "& .ServiceWrapperBoxOverflowX": {
            overflowX: "hidden",
          },
          "& .ReplaceWrapper": {
            background: `rgba(${theme.palette.overlay["editOverlay"]})`,
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "0",
            left: "",
            display: "none",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "1",
          },
          "& .replaceIconWrapper": {
            width: "70px",
            height: "70px",
            color: theme.palette.autoRenewIcon,
            margin: "auto",
            [theme.breakpoints.down("sm")]: {
              width: "50px",
              height: "50px",
            },
          },
          "& .WrapperBoxIcons": {
            cursor: "pointer",
            textAlign: "center",
          },
        },
      },
    };
  })();
};
