import { useEffect, useState } from "react";
import XVideoWithText from "./XVideoWithText";

const VideoOptions = ({ setState, state, field }) => {
  const [items, setItems] = useState<any>(
    state[field?.name] ? state[field?.name] : [{ id: "1", option: "", video: "" }],
  );

  useEffect(() => {
    setState({ ...state, [field?.name]: items });
  }, [items]);

  const updateVideo = (videoObj, currentItem) => {
    const updatedItem = items.map((cItem) =>
      cItem.id === currentItem ? { ...cItem, video: videoObj } : cItem,
    );
    setItems(updatedItem);
  };
  return <XVideoWithText callBack={updateVideo} items={items} setItems={setItems} />;
};
export default VideoOptions;
