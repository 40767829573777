import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = (selectedType) => {
  const theme = useTheme();
  return makeStyles(() => {
    return {
      paragraphWithHeadlineAndCTAWrapper: {
        "&.paragraphWithHeadlineAndCTABg": {
          background: theme.palette?.[selectedType]?.BACKGROUND,
          "& .labelText": {
            color: theme.palette?.[selectedType]?.LABEL,
          },
          "& .headingText": {
            color: theme.palette?.[selectedType]?.TITLE,
          },
          "& .descriptionText": {
            color: theme.palette?.[selectedType]?.PARAGRAPH,
          },
          "& .firstColumnContentWrapper": {
            paddingRight: 0,
            width: "100%",
            [theme.breakpoints.up("md")]: {
              paddingRight: "40px",
            },
            [theme.breakpoints.up("em")]: {
              paddingRight: "116px",
            },
            [theme.breakpoints.up("xl")]: {
              paddingRight: "116px",
            },
            [theme.breakpoints.down("md")]: {
              textAlign: "left",
            },
          },
          "& .borderBottom": {
            borderBottom: `1px solid ${theme.palette?.[selectedType]?.LINE}`,
            maxWidth: "80%",
            [theme.breakpoints.up("md")]: {
              maxWidth: "350px",
            },
            [theme.breakpoints.up("lg")]: {
              maxWidth: "420px",
            },
          },
          "& .secondColumnContentWrapper": {
            width: "100%",
            [theme.breakpoints.down("md")]: {
              textAlign: "left",
            },
          },
        },
      },
    };
  })();
};
