import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = (selectedType) => {
  const theme = useTheme();
  return makeStyles(() => {
    return {
      aboutUsfourWithsubHeadingWrapper: {
        "&.aboutUsfourWithsubHeadingBg": {
          background: theme.palette?.[selectedType]?.BACKGROUND,
          "& .labelText": {
            color: theme.palette?.[selectedType]?.LABEL,
          },
          "& .headingText": {
            color: theme.palette?.[selectedType]?.TITLE,
          },
          "& .descriptionText": {
            color: theme.palette?.[selectedType]?.PARAGRAPH,
          },
          "& .firstColumnWrapper": {
            textAlign: "left",
            [theme.breakpoints.up("xs")]: {
              marginBottom: "25px",
              paddingRight: 0,
            },
            [theme.breakpoints.up("em")]: {
              marginBottom: "35px",
              paddingRight: "35px",
            },
            [theme.breakpoints.up("lg")]: {
              marginBottom: 0,
              paddingRight: "60px",
            },
            display: "initial",
          },
          "& .secondColumnWrapper": {
            display: "initial",
            textAlign: "left",
          },
          "& .quotesColumn": {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            marginTop: "15px",
          },
          "& .doubleQuote": {
            fill: theme.palette?.[selectedType]?.TITLE,
          },
          "& .imgWrapper": {
            minWidth: "57px",
            overflow: "hidden",
            minHeight: "57px",
            maxWidth: "57px",
            maxHeight: "57px",
            background: "#f1f1f1",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "15px",
          },
          "& .quoteGap": {
            marginTop: "12px",
            marginBottom: "12px",
          },
          "& .headingSubheading": {
            display: "flex",
            flexDirection: "column",
            "& p": {
              margin: 0,
            },
          },
        },
      },
    };
  })();
};
