import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = (selectedType) => {
  const theme = useTheme();
  return makeStyles(() => {
    return {
      formCreatorWrapper: {
        "&.formCreator": {
          minHeight: "520px",
          background: theme.palette?.[selectedType]?.BACKGROUND,
          "& .headingText": {
            color: theme.palette?.[selectedType]?.TITLE,
          },
          "& .descriptionText": {
            color: theme.palette?.[selectedType]?.PARAGRAPH,
          },
          "&.noscroll": {
            overflowY: "hidden !important",
            "& .editForm": {
              minHeight: "480px",
              overflow: "hidden",
            },
          },
          "& .formCreatorPrelem": {
            height: "100%",
            position: "relative",
            maxWidth: "740px",
            margin: "0px auto",
            paddingTop: "0px",
            padding: theme.spacing(2.5),
            border: `1px solid ${theme.palette?.[selectedType]?.LINE}`,
            borderRadius: theme.borderRadius.value1,
            "& .formSection": {
              height: "100%",
              "& .mandatory": {
                color: "red",
              },
              "& .form-element": {
                "& .formDropdown.elementWrapper": {
                  maxHeight: "90px",
                },
                "& .form-label": {
                  color: theme.palette?.[selectedType]?.LABEL,
                },
                "& .form-outline.selectfield": {
                  "&.Platform-x-InputBase-root.Platform-x-FilledInput-root": {
                    border: `1px solid ${theme.palette?.[selectedType]?.LINE}`,
                    borderRadius: "8px",
                    backgroundColor: theme.palette?.[selectedType]?.BACKGROUND,
                    "&.Mui-focused": {
                      border: "1px solid #1a1a1a",
                      backgroundColor: "#fff",
                    },
                  },
                  "& .Platform-x-Select-select.Platform-x-Select-filled": {
                    borderRadius: theme.borderRadius.value1,
                    backgroundColor: "#fff",
                    fontSize: theme.fontSize?.fontSize_14,
                    "&:focus": {
                      backgroundColor: "#fff",
                    },
                  },
                },
                "& .form-outline.textarea": {
                  border: `1px solid ${theme.palette?.[selectedType]?.LINE}`,
                  "&:focus": {
                    outline: "none",
                  },
                  "&.textbox-default": {
                    "&:focus": {
                      border: `1px solid #1a1a1a !important`,
                    },
                  },
                  "&::placeholder": {
                    color: "#9C9CA6", //theme.palette?.[selectedType]?.INPUT.PLACEHOLDER,
                    opacity: 0.9,
                  },
                },
              },
            },
            "& .add-content-overlay": {
              left: 0,
              borderRadius: theme.borderRadius.value1,
            },
          },
        },
      },
    };
  })();
};
